// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Input = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Input"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_7200_4495)" fill="currentColor">
            <path d="M3.2 11.2H1.6V4.8h1.6a.801.801 0 000-1.6H.8c-.441 0-.8.359-.8.8v8c0 .44.36.8.8.8h2.4a.801.801 0 000-1.6zM15.2 3.2H8a.801.801 0 000 1.6h6.4v6.4H8a.801.801 0 000 1.6h7.2c.441 0 .8-.36.8-.8V4c0-.44-.36-.8-.8-.8z" />
            <path d="M8 .8c0-.441-.359-.8-.8-.8H4a.801.801 0 000 1.6h.8v12.8H4A.801.801 0 004 16h3.2a.801.801 0 000-1.6h-.8V1.6h.8c.441 0 .8-.36.8-.8z" />
          </g>
          <defs>
            <clipPath id="prefix__clip0_7200_4495">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Input;
