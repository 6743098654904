// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const ChevronUpLight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronUpLight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.526 11.186a.896.896 0 01-1.265 0 .896.896 0 010-1.265l5.107-5.107a.896.896 0 011.264 0l5.107 5.107a.896.896 0 010 1.265.896.896 0 01-1.265 0L7.998 6.708l-4.472 4.478z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default ChevronUpLight;
