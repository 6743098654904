// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Spinner2 = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Spinner2"
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          {...props}
        >
          <g>
            <path
              d="M6.145 15.782A8 8 0 10.642 4.861l2.193.936a5.615 5.615 0 113.863 7.665l-.553 2.32z"
              fill="var(--brand)"
            />
            <path
              d="M16 8a8 8 0 10-9.348 7.886l.417-2.438A5.526 5.526 0 1113.527 8H16z"
              fill="var(--brand-35)"
            />
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 8 8"
              to="360 8 8"
              dur="0.8s"
              repeatCount="indefinite"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Spinner2;
