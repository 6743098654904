// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Gauge = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Gauge"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 9.8c0-4.407-3.593-8-8-8s-8 3.593-8 8c0 1.666.517 3.217 1.394 4.5l1.515-1.197A6.054 6.054 0 011.94 9.8 6.046 6.046 0 018 3.74a6.046 6.046 0 016.06 6.06c0 1.22-.354 2.354-.97 3.303l1.516 1.197A7.955 7.955 0 0016 9.8zM5.433 6.166a.674.674 0 00-.277.77l1.823 5.727a1.47 1.47 0 102.636-1.24l-3.25-5.056a.674.674 0 00-.932-.201z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Gauge;
