import React, { type ReactElement } from "react";

import { NavbarItem } from "./NavbarItem";
import { NavbarFooter } from "./NavbarFooter";

import { classNames } from "../../utils";

import type { NavbarItemAltProps } from "./NavbarItem";

import styles from "./Navbar.module.css";
import { NavbarUserProfile } from "./NavbarUserProfile";

type NavbarProps = {
  /**
   * The value of the active nav item
   */
  activeNavItem?: string;
  /**
   * The children of the Navbar - NavbarItem
   */
  children: React.ReactElement | React.ReactElement[] | React.ReactFragment;
  /**
   * Whether the navbar is expanded or collapsed
   * @default true
   */
  expanded?: boolean;
};

export const Navbar = (props: NavbarProps) => {
  const { activeNavItem, children, expanded: isExpanded = true } = props;

  const navbarTopSectionChildren: ReactElement[] = [];
  let navbarFooterChildren = null;
  const navbarBottomSectionChildren: ReactElement[] = [];

  // Filter out null or undefined children
  const validChildren = React.Children.toArray(children).filter(child =>
    React.isValidElement(child)
  );

  /* Reason for typecasting
    1. Using React.ReactFargment as children type in Navbar component. This is done
        to avoid the type error when mapping over array for NavbarItem component
        as children (refer storybook for Navbar)
    2. React.Children.forEach expects ReactElement[] as children type
  */
  React.Children.forEach(validChildren as ReactElement[], child => {
    if (child.type === NavbarFooter) {
      navbarFooterChildren = child.props.children;
    } else {
      navbarTopSectionChildren.push(
        React.cloneElement(child, {
          expanded: isExpanded,
          isActive: child.props.id === activeNavItem
        })
      );
    }
  });

  if (!!navbarFooterChildren) {
    React.Children.forEach(
      navbarFooterChildren,
      (child: ReactElement<NavbarItemAltProps>) => {
        if (React.isValidElement(child) && child.type === NavbarItem) {
          navbarBottomSectionChildren.push(
            React.cloneElement(child, {
              expanded: isExpanded,
              isActive: child.props.id === activeNavItem
            })
          );
        } else if (
          React.isValidElement(child) &&
          child.type === NavbarUserProfile
        ) {
          navbarBottomSectionChildren.push(
            React.cloneElement(child, {
              expanded: isExpanded
            })
          );
        }
      }
    );
  }

  return (
    <nav
      className={classNames({
        [styles.catalystNavbar]: true,
        [styles.catalystNavbarCollapsed]: !isExpanded
      })}
      style={{
        minWidth: isExpanded ? "230px" : "fit-content"
      }}
      aria-label="navigation sidebar"
    >
      <ul>{navbarTopSectionChildren}</ul>
      <ul>{navbarBottomSectionChildren}</ul>
    </nav>
  );
};
