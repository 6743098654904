import type { Gap } from "./types";

export type Padding =
  | `${Gap} ${Gap} ${Gap} ${Gap}`
  | `${Gap} ${Gap} ${Gap}`
  | `${Gap} ${Gap}`
  | `${Gap}`;

// Regular expression pattern to match the padding values
const PADDING_PATTERN = /(\d+)/g;

/**
 * Replaces the padding values with equivalent CSS variables
 * @param padding The padding values
 * @returns The transformed padding values
 * @example
 * getTransformedPaddingWithCSSVariables("4 8 12 16") => "var(--space-4) var(--space-8) var(--space-12) var(--space-16)"
 */
export const getTransformedPaddingWithCSSVariables = (padding?: Padding) => {
  // Replace the padding values with equivalent CSS variables
  const transformedPadding = padding?.replace(
    PADDING_PATTERN,
    function (match) {
      return "var(--space-" + match + ")";
    }
  );

  return transformedPadding;
};
