// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Dots = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Dots"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 8a2 2 0 11-4 0 2 2 0 014 0zm5.965 0a2 2 0 11-4 0 2 2 0 014 0zm3.966 2a2 2 0 100-3.999 2 2 0 000 3.999z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Dots;
