import { useSelector } from "react-redux";
import { get as lodashGet } from "lodash-es";

const useConfigBrandColor = () => {
  return useSelector(state =>
    lodashGet(state, "config.custom_ui_labels.brandColor")
  );
};

export { useConfigBrandColor };
