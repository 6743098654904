import type { MarkObj } from "./Slider.types";

/**
 * Converts a given value to a percentage relative to a specified range.
 *
 * @param value - The current value to convert.
 * @param min - The minimum value of the range.
 * @param max - The maximum value of the range.
 * @returns The percentage representation of the value within the range, clamped between 0 and 100.
 */
export function convertValueToPercentage(
  value: number,
  min: number,
  max: number
) {
  const maxSteps = max - min;
  const percentPerStep = 100 / maxSteps;
  const percentage = percentPerStep * (value - min);
  return clamp(percentage, [0, 100]);
}

/**
 * Clamps a number within the provided range.
 *
 * @param value - The number to clamp.
 * @param range - An array with two elements representing the minimum and maximum range.
 * @returns The clamped value.
 */
export function clamp(value: number, [min, max]: [number, number]): number {
  return Math.min(max, Math.max(min, value));
}

/**
 * Offsets the thumb center point while sliding to ensure it remains
 * within the bounds of the slider when reaching the edges.
 *
 * @param width - The width of the thumb.
 * @param left - The current left position of the thumb.
 * @param direction - The direction of the movement (1 for right, -1 for left).
 * @returns The adjusted position offset to keep the thumb within bounds.
 */
export function getPositionOffset(
  width: number,
  left: number,
  direction: number
) {
  const halfWidth = width / 2;
  const halfPercent = 50;
  const offset = linearScale([0, halfPercent], [0, halfWidth]);
  return (halfWidth - offset(left) * direction) * direction;
}

/**
 * Creates a linear scaling function that maps an input range to an output range.
 *
 * @param input - An array with two elements representing the input range.
 * @param output - An array with two elements representing the output range.
 * @returns A function that scales a value from the input range to the output range.
 */
function linearScale(
  input: readonly [number, number],
  output: readonly [number, number]
) {
  return (value: number) => {
    if (input[0] === input[1] || output[0] === output[1]) return output[0];
    const ratio = (output[1] - output[0]) / (input[1] - input[0]);
    return output[0] + ratio * (value - input[0]);
  };
}

/**
 * Generates a green-to-red gradient based on a value and a maximum value.
 *
 * @param value - The current value.
 * @param max - The maximum value.
 * @returns A CSS linear-gradient string representing the green-to-red gradient.
 */
export const generateGreenToRedGradient = (value: number, max: number) => {
  const percentageValue = (value / max) * 100;
  return `linear-gradient(90deg, #00CA69 0%, #7EDA4E ${
    (25 / percentageValue) * 100
  }%, #FFEA31 ${(50 / percentageValue) * 100}%, #FCA311 ${
    (75 / percentageValue) * 100
  }%, #F72C25 100%)`;
};

/**
 * Generates a red-to-green gradient based on a value and a maximum value.
 *
 * @param value - The current value.
 * @param max - The maximum value.
 * @returns A CSS linear-gradient string representing the red-to-green gradient.
 */
export const generateRedToGreenGradient = (value: number, max: number) => {
  const percentageValue = (value / max) * 100;
  return `linear-gradient(90deg, #F72C25 0%, #FCA311 ${
    (25 / percentageValue) * 100
  }%, #FFEA31 ${(50 / percentageValue) * 100}%, #7EDA4E ${
    (75 / percentageValue) * 100
  }%, #00CA69 100%)`;
};

/**
 * Checks if a given mark is a MarkObj.
 *
 * @param mark - The mark to check.
 * @returns True if the mark is a MarkObj, otherwise false.
 */
const isMarkObj = (mark: React.ReactNode | MarkObj): mark is MarkObj => {
  return typeof mark === "object" && mark !== null;
};

/**
 * Gets the label and style from a mark object or node.
 *
 * @param mark - The mark to process, which can be a React node or a MarkObj.
 * @returns An object containing the label and style of the mark.
 */
export const getMarkLabelAndStyle = (
  mark: React.ReactNode | MarkObj | undefined
): {
  label?: React.ReactNode | string;
  style?: React.CSSProperties;
} => {
  if (!mark) {
    return {};
  }
  if (isMarkObj(mark)) {
    return {
      label: mark.label,
      style: mark.style
    };
  }
  return {
    label: mark
  };
};
