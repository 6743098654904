type AnyObject = { [key: string]: any };

/**
 * Omits specified keys from the given object and returns a new object.
 * @param obj - The original object.
 * @param keysToOmit - An array of keys to be omitted from the object.
 * @returns A new object with the specified keys omitted.
 */
export function omit<T extends AnyObject>(
  obj: T,
  keysToOmit: (keyof T)[]
): Partial<T> {
  const newObj: Partial<T> = {};

  for (const key in obj) {
    if (!keysToOmit.includes(key)) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}
