// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const OpenInWindow = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="OpenInWindow"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_168_20)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.352 1.063C9.352.476 9.828 0 10.416 0h4.52C15.525 0 16 .476 16 1.063v4.49a1.063 1.063 0 01-2.127 0V3.767l-5.81 5.88A1.063 1.063 0 016.552 8.15l5.953-6.024h-2.088a1.063 1.063 0 01-1.064-1.064zm-8.165.94c-.588 0-1.064.477-1.064 1.064v11.957c0 .587.476 1.063 1.064 1.063h11.956c.587 0 1.063-.476 1.063-1.063V8.812a1.063 1.063 0 00-2.127 0v5.149H2.25V4.13h5.148a1.063 1.063 0 000-2.127H1.187z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_168_20">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default OpenInWindow;
