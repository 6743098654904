import { useRef, useCallback } from "react";

type UseIntersectionObserverOptions = {
  root?: Element | Document | null;
  rootMargin?: string;
  threshold?: number | number[];
  loadMore?: () => void;
};

type IntersectionReturn = {
  ref: (node?: Element | null) => void;
};

export function useIntersectionObserver({
  threshold = 0,
  root = null,
  rootMargin = "0px",
  loadMore
}: UseIntersectionObserverOptions = {}): IntersectionReturn {
  const loadMoreRef = useRef<() => void>();
  const observerRef = useRef<IntersectionObserver | null>(null);

  loadMoreRef.current = loadMore;

  const ref = useCallback(
    (node?: Element | null) => {
      if (observerRef.current) observerRef.current.disconnect();
      if (!node) return;
      if (!("IntersectionObserver" in window)) return;

      observerRef.current = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]): void => {
          const [firstEntry] = entries;

          if (firstEntry && firstEntry.isIntersecting && loadMoreRef.current) {
            loadMoreRef.current();
          }
        },
        { rootMargin, threshold, root }
      );

      observerRef.current.observe(node);
    },
    [rootMargin, threshold, root]
  );

  return { ref };
}
