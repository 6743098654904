// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Lock = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Lock"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_9588_10435)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.005 8a1 1 0 00-1 1v6a1 1 0 001 1h9.998a1 1 0 001-1V9a1 1 0 00-1-1h-.333V4.667A4.674 4.674 0 008.003 0a4.673 4.673 0 00-4.665 4.667V8h1.999V4.667c0-1.469 1.2-2.666 2.666-2.666 1.469 0 2.668 1.2 2.668 2.666V8H3.005z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_9588_10435">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Lock;
