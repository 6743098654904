// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Info = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Info"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 8A6 6 0 112 8a6 6 0 0112 0zM7 8a1 1 0 012 0v3a1 1 0 11-2 0V8zm1-2a1 1 0 100-2 1 1 0 000 2z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Info;
