import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

export const Sparks = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="sparks-icon"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M5.3335 10.0007C8.5835 10.0007 10.0002 8.63332 10.0002 5.33398C10.0002 8.63332 11.4068 10.0007 14.6668 10.0007C11.4068 10.0007 10.0002 11.4073 10.0002 14.6673C10.0002 11.4073 8.5835 10.0007 5.3335 10.0007ZM1.3335 4.33398C3.42283 4.33398 4.3335 3.45532 4.3335 1.33398C4.3335 3.45532 5.23816 4.33398 7.3335 4.33398C5.23816 4.33398 4.3335 5.23865 4.3335 7.33398C4.3335 5.23865 3.42283 4.33398 1.3335 4.33398Z"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
