import { nanoid } from "nanoid";

/**
 * Generates a string of class names from an object of class names and their boolean
 * values also accepts a string of class names as arguments
 * @param classes An object of class names and their boolean values
 * @returns A string of class names
 * @example
 * const classes = classNames({
 *  'error': true,
 * 'success': false,
 * 'warning': true
 * });
 * // classes = 'error warning'
 * @example
 * const classes = classNames('error', 'warning');
 * // classes = 'error warning'
 * @example
 * const classes = classNames('error', undefined, 'warning');
 * // classes = 'error warning'
 */

export function classNames(
  classes: Record<string, boolean | undefined | null>
): string;
export function classNames(...args: Array<string | undefined | null>): string;
export function classNames(args: unknown) {
  const currentClasses: Array<string> = [];

  if (arguments.length > 1) {
    return Array.from(arguments).filter(Boolean).join(" ");
  } else if (arguments.length === 1 && Object.keys(arguments[0]).length) {
    Object.entries(arguments[0]).forEach(([key, value]) => {
      if (value) {
        currentClasses.push(key);
      }
    });

    if (currentClasses.length) {
      return currentClasses.join(" ");
    }
  } else {
    return args;
  }

  return "";
}

/**
 * Generates a unique id for a field with optional arguments
 * @param args Optional arguments to append to the id
 * @returns A unique id
 * @example
 * const id = generateFieldId('name');
 * // id = 'catalyst-field-id-1a2b3c-name'
 * @example
 * const id = generateFieldId('name', 'email');
 * // id = 'catalyst-field-id-1a2b3c-name-email'
 * @example
 * const id = generateFieldId();
 * // id = 'catalyst-field-id-1a2b3c'
 */

export const generateFieldId = (...args: Array<string>) => {
  const id = nanoid();

  if (args.length) {
    return `catalyst-field-${id}-${args.join("-")}`;
  }

  return `catalyst-field-id-${id}`;
};

/**
 * Merges multiple refs into a single ref
 * @param refs An array of refs
 * @returns A callback ref
 * @example
 * const ref = useRef(null);
 * const mergedRef = mergeRefs([ref, otherRef]);
 * // mergedRef = (inst) => {
 * //   ref.current = inst;
 * //   otherRef.current = inst;
 * // }
 * @example
 * const mergedRef = mergeRefs([ref, otherRef, null]);
 * // mergedRef = (inst) => {
 * //   ref.current = inst;
 * //   otherRef.current = inst;
 * // }
 */

export const mergeRefs = <T extends any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> | null => {
  const filteredRefs = refs.filter(Boolean);

  if (!filteredRefs.length) return null;

  return (inst: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        (ref as React.MutableRefObject<T | null>).current = inst;
      }
    }
  };
};

// Filter object properties based on allowed properties
export const filterProperties = <T extends object, K extends keyof T>(
  obj: T,
  allowedProps: K[]
): Pick<T, K> => {
  // Create an empty object to store the filtered properties
  const filteredObj = {} as Pick<T, K>;

  // Iterate over the entries of the object
  Object.entries(obj).forEach(([key, value]) => {
    // Check if the current property is allowed
    if (allowedProps.includes(key as K)) {
      // Add the property to the filtered object
      filteredObj[key as K] = value;
    }
  });

  // Return the filtered object
  return filteredObj;
};

/**
 * Options for controlling the behavior of the throttle function.
 */
type ThrottleOptions = {
  leading?: boolean; // Should the function be called immediately on the leading edge?
  trailing?: boolean; // Should the function be called on the trailing edge after the last call?
};

/**
 * Throttle a function, ensuring it's called at most once every `wait` milliseconds.
 * @param func The function to throttle.
 * @param wait The number of milliseconds to wait between function calls.
 * @param options Options to customize leading and trailing edge behavior.
 * @returns The throttled version of the original function.
 */
export function throttle<T extends (...args: any[]) => void>(
  func: T,
  wait: number,
  options: ThrottleOptions = { leading: true, trailing: false }
): T {
  let timeout: ReturnType<typeof setTimeout> | null;
  let previous = 0;

  // Create the throttled function
  const throttled = function (
    this: ThisParameterType<T>,
    ...args: Parameters<T>
  ): void {
    const now = Date.now();
    if (!previous && !options.leading) {
      previous = now;
    }
    const remaining = wait - (now - previous);

    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      func.apply(this, args);
    } else if (!timeout && options.trailing) {
      timeout = setTimeout(() => {
        previous = options.leading ? Date.now() : 0;
        timeout = null;
        func.apply(this, args);
      }, remaining);
    }
  } as T;

  return throttled;
}
