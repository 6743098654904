// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Search = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Search"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_2_157)">
            <path
              d="M11.784 10.146a6.578 6.578 0 00.609-6.114A6.458 6.458 0 007.702.126a6.393 6.393 0 00-5.816 1.782A6.562 6.562 0 00.125 7.79a6.498 6.498 0 003.861 4.745c1.99.829 4.259.598 6.046-.617l3.704 3.746c.489.46 1.25.446 1.72-.03a1.263 1.263 0 00.03-1.74l-3.702-3.748zm-5.345.374a3.938 3.938 0 01-2.802-1.174 4.029 4.029 0 01-1.16-2.834c0-1.062.417-2.082 1.16-2.833A3.938 3.938 0 016.44 2.505c1.05 0 2.059.422 2.802 1.174a4.029 4.029 0 011.16 2.833 4.029 4.029 0 01-1.16 2.834 3.937 3.937 0 01-2.802 1.174z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_2_157">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Search;
