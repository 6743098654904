/**
 * The ID of the theme container that lives near the top the component hierarchy.
 * It can be used to mount overlays as it has all the necessary CSS variables from
 * the Design System.
 */
export const LAYOUT_ID: string = "new-min-layout-body";

export const BRAND_COLORS = {
  /** Branding */
  "brand-d10": "#0042eb",
  "brand-d15": "#003bd1",
  brand: "#1f5eff",
  "brand-15": "#6b95ff",
  "brand-35": "#d1deff",
  "brand-l15": "#6B95FF",
  "brand-100": "#ECF2FF",
  "brand-300": "#5c8bfe"
};

export const APP_COLORS = {
  /** Neutrals */
  "neutral-0": "#ffffff",
  "neutral-5": "#f9f9f9",
  "neutral-10": "#f6f6f6",
  "neutral-20": "#eaeaea",
  "neutral-50": "#dbdbdb",
  "neutral-60": "#727272",
  "neutral-70": "#8f8f91",
  "neutral-100": "#00164e",
  "neutral-100-d10": "#00081b",
  "neutral-500": "#d0d4df",
  "neutral-600": "#8892AC",
  "neutral-700": "#6b7693",

  /** Teal */
  "teal-d5": "#40bbff",
  "teal-d10": "#27b2ff",
  teal: "#5ac4ff",
  "teal-40": "#f1faff",
  "teal-60": "#f8fcff",

  /** Red */
  "red-d5": "#f6140c",
  "red-d15": "#c80e07",
  "red-d10": "#e10f08",
  red: "#f72c25",
  "red-40": "#feeaea",
  "red-60": "#fffafa",

  /** Green */
  "green-d5": "#00B05C",
  "green-d10": "#00974e",
  green: "#00ca69",
  "green-40": "#deffef",
  "green-60": "#f5fffa",

  /** Purple */
  "purple-d5": "#bd45cf",
  "purple-d10": "#b333c7",
  purple: "#c459d4",
  "purple-40": "#fbdfff",
  "purple-60": "#fef8ff",

  /** Orange */
  "orange-d5": "#f09703",
  "orange-d10": "#d78703",
  orange: "#fca311",
  "orange-40": "#fff1da",
  "orange-faded": "#FFFBF4",

  /** Blueberry */
  "blueberry-d5": "#2d1b82",
  "blueberry-d10": "#4b1fa2",
  blueberry: "#5f27cd",

  /** Black */
  black: "#000000",

  yellow: "#ffea00",
  pink: "#ff6ec7",
  cyan: "#00f7ff",
  lime: "#aaff00",
  indigo: "#3f51b5",
  amber: "#ffc107",
  deepPurple: "#673ab7",
  deepOrange: "#ff5722",
  lightBlue: "#03a9f4",
  lightGreen: "#8bc34a",
  brown: "#795548",
  grey: "#9e9e9e",
  blueGrey: "#607d8b",
  limeGreen: "#32cd32",
  salmon: "#fa8072",
  skyBlue: "#87ceeb",
  lavender: "#e6e6fa",
  maroon: "#800000",
  olive: "#808000"
};

export const COLORS = { ...BRAND_COLORS, ...APP_COLORS };

/**
 * TODO: Remove "non-design-system" variants
 * Avoid using variants marked as "non-design-system" below.
 */
export const TYPOGRAPHY_VARIANTS = {
  //TITLE
  title: {
    fontSize: "22px",
    lineHeight: "27px",
    fontWeight: "700",
    fontStyle: null,
    textTransform: null
  },

  // H1
  "h1-bold": {
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "700",
    fontStyle: null,
    textTransform: null
  },

  // H2
  "h2-bold": {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "700",
    fontStyle: null,
    textTransform: null
  },
  "h2-medium": {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "500",
    fontStyle: null,
    textTransform: null
  },
  // non-design-system
  "h2-regular": {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: "400",
    fontStyle: null,
    textTransform: null
  },

  // H3
  "h3-bold": {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "700",
    fontStyle: null,
    textTransform: null
  },
  "h3-medium": {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "500",
    fontStyle: null,
    textTransform: null
  },
  "h3-regular": {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "400",
    fontStyle: null,
    textTransform: null
  },

  // P1
  "p1-bold": {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "700",
    fontStyle: null,
    textTransform: null
  },
  // non-design-system
  "p1-semibold": {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "600",
    fontStyle: null,
    textTransform: null
  },
  "p1-medium": {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "500",
    fontStyle: null,
    textTransform: null
  },
  "p1-regular": {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "400",
    fontStyle: null,
    textTransform: null
  },
  "p1-regular-italic": {
    fontSize: "12px",
    lineHeight: "15px",
    fontWeight: "400",
    fontStyle: "italic",
    textTransform: null
  },

  // P2
  "p2-bold": {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: "700",
    fontStyle: null,
    textTransform: null
  },
  // non-design-system
  "p2-medium": {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: "500",
    fontStyle: null,
    textTransform: null
  },
  "p2-bold-upper": {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: "700",
    fontStyle: null,
    textTransform: "uppercase"
  },
  // non-design-system
  "p2-semibold-upper": {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: "600",
    fontStyle: null,
    textTransform: "uppercase"
  },
  // non-design-system
  "p2-medium-upper": {
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: "500",
    fontStyle: null,
    textTransform: "uppercase"
  }
};

export const Z_INDEX = {
  "zindex-dropdown": 1000,
  "zindex-sticky": 1020,
  "zindex-fixed": 1030,
  "zindex-modal-backdrop": 1040,
  "zindex-offcanvas": 1050,
  "zindex-modal": 1060,
  "zindex-popover": 1070,
  "zindex-tooltip": 1080
};
