export enum FieldStatus {
  SUCCESS = "Success",
  ERROR = "Error",
  LOADING = "Loading"
}

export type AllowedInputStyleProperties = {
  boxShadow?: string;
  border?: string;
  borderRadius?: string;
  backgroundColor?: string;
  focusedBorder?: string;
};

export type TextInputContainerStyle = AllowedInputStyleProperties;

export type NumberInputContainerStyle = AllowedInputStyleProperties;

export enum PopperPlacements {
  AUTO = "auto",
  AUTO_START = "auto-start",
  AUTO_END = "auto-end",
  TOP = "top",
  TOP_START = "top-start",
  TOP_END = "top-end",
  BOTTOM = "bottom",
  BOTTOM_START = "bottom-start",
  BOTTOM_END = "bottom-end",
  RIGHT = "right",
  RIGHT_START = "right-start",
  RIGHT_END = "right-end",
  LEFT = "left",
  LEFT_START = "left-start",
  LEFT_END = "left-end"
}
