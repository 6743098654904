/**
 * Checks if the current environment allows the use of the Document Object Model (DOM).
 *
 * The function checks whether the 'window' object is defined, along with the presence of
 * 'document' and 'createElement' properties, to determine if the DOM can be accessed.
 *
 * @function
 * @returns {boolean} Returns 'true' if the DOM can be used; otherwise, returns 'false'.
 * @example
 * // Example
 * const isDomAvailable = canUseDom();
 * console.log(isDomAvailable); // Output: true (in a browser environment)
 */
export function canUseDom() {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
}
