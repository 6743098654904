import moment from "moment";

/**
 * Format a date using the specified format.
 * Optionally, format the date according to the user's locale.
 *
 * @param {string | moment.Moment | null} value - The date or moment object to format.
 * @param {string} format - The desired date format.
 * @param {boolean} formatWithUserLocale - If true, format the date according to the user's locale.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (
  value: string | moment.Moment,
  format: string = "YYYY-MM-DD",
  formatWithUserLocale: boolean = true
): string => {
  if (typeof value === "string") {
    value = moment(value);
  }
  return value.format(formatWithUserLocale ? "L" : format);
};

type ParsedDateResult = {
  isValid: boolean;
  parsedMoment: moment.Moment | null;
  formattedString: string;
};

export const validateAndParseDate = (
  inputValue: string,
  format: string = "YYYY-MM-DD",
  formatWithUserLocale: boolean = true
): ParsedDateResult => {
  if (!inputValue) {
    return { isValid: false, parsedMoment: null, formattedString: "" };
  }

  if (formatWithUserLocale) {
    try {
      const parsedMoment = moment(inputValue, "L", true).locale(
        navigator.language
      );
      const isValid = parsedMoment.isValid();
      return {
        isValid,
        parsedMoment: isValid ? parsedMoment : null,
        formattedString: isValid ? parsedMoment.format("L") : ""
      };
    } catch (error) {
      return { isValid: false, parsedMoment: null, formattedString: "" };
    }
  } else {
    const parsedMoment = moment(inputValue, format, true);
    const isValid = parsedMoment.isValid();
    return {
      isValid,
      parsedMoment: isValid ? parsedMoment : null,
      formattedString: isValid ? parsedMoment.format(format) : ""
    };
  }
};

type DateFilterOptions = {
  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;
};

/**
 * Checks if a given date is out of bounds based on a minimum and/or maximum date.
 *
 * @param {moment.Moment} day - The date to check.
 * @param {Object} options - The options object containing minDate and maxDate.
 * @param {moment.Moment | null} [options.minDate] - The minimum date boundary.
 * @param {moment.Moment | null} [options.maxDate] - The maximum date boundary.
 * @returns {boolean} - Returns `true` if the date is out of bounds, `false` otherwise.
 */
export function isOutOfBounds(
  date: moment.Moment,
  { minDate, maxDate }: DateFilterOptions = {}
): boolean {
  return (
    ((minDate && date.isBefore(minDate, "day")) ||
      (maxDate && date.isAfter(maxDate, "day"))) ??
    false
  );
}
