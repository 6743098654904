import React, { useEffect, useRef, forwardRef, useMemo } from "react";

import styles from "./Radio.module.css";

import { classNames, generateFieldId, mergeRefs } from "../../utils/common";

type RadioProps = {
  id?: string | number;
  name?: string;
  label: string;
  "aria-describedby"?: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
};

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    id,
    name,
    label: radioLabel,
    value,
    checked: isChecked = false,
    disabled: isDisabled = false,
    required: isRequired = false,
    autoFocus: shouldAutoFocus = false,
    onChange,
    error,
    "aria-describedby": ariaDescribedBy
  } = props;

  const radioRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (radioRef.current) {
      radioRef.current.checked = isChecked;
    }
  }, [isChecked]);

  const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(evt);
  };

  const radioId = useMemo(() => {
    const currentRadioId = id?.toString();

    if (!currentRadioId) {
      return generateFieldId("checkbox");
    }

    return currentRadioId;
  }, [id]);

  const ariaDescribedById = ariaDescribedBy
    ? ariaDescribedBy
    : !!error
      ? id + "-error"
      : undefined;

  const handleWrapperClick = () => {
    if (radioRef.current) {
      radioRef.current.click();
      radioRef.current.focus();
    }
  };

  return (
    <div
      className={classNames({
        [styles.catalystRadioContainer]: true,
        [styles.catalystRadioContainerDisabled]: isDisabled,
        [styles.catalystRadioContainerError]: !!error
      })}
      onClick={handleWrapperClick}
    >
      <div
        className={classNames({
          [styles.catalystRadioInputContainer]: true
        })}
        onChange={handleOnChange}
      >
        <input
          autoFocus={shouldAutoFocus}
          id={radioId}
          name={name}
          className={classNames({
            [styles.catalystRadio]: true
          })}
          type="radio"
          disabled={isDisabled}
          value={value}
          ref={mergeRefs([radioRef, ref])}
          aria-describedby={ariaDescribedById}
          required={isRequired}
        />
      </div>
      {/*
        Do not add the input inside the label, it will break the accessibility
        by not reading the group label when used in group role in Firefox.
      */}
      <label htmlFor={radioId}>{radioLabel}</label>
    </div>
  );
});

const getRadioGroupDirection = (direction: string) => {
  switch (direction) {
    case "vertical":
      return "Vertical";
    default:
      return "Horizontal";
  }
};

type RadioGroupProps = {
  direction?: "horizontal" | "vertical";
  children: React.ReactElement<RadioProps> | React.ReactElement<RadioProps>[];
  name: string;
  "aria-label"?: string;
  value?: string;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: string;
};

export const RadioGroup = (props: RadioGroupProps) => {
  const {
    direction = "horizontal",
    children,
    name,
    "aria-label": ariaLabel,
    value,
    onChange,
    disabled: isDisabled = false,
    error
  } = props;

  const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(evt);
  };

  const radioUniqueName = generateFieldId(name);

  return (
    <div
      className={classNames({
        [styles.catalystRadioGroup]: true,
        [styles[`catalystRadioGroup${getRadioGroupDirection(direction)}`]]:
          !!direction
      })}
      role="radiogroup"
      aria-label={ariaLabel || name}
    >
      {React.Children.map(children, (radio: React.ReactElement<RadioProps>) => {
        if (radio?.type !== Radio) {
          throw new Error("Use only Radio component as children");
        }
        return React.cloneElement(radio, {
          name: radioUniqueName,
          checked: radio.props.value === value,
          onChange: handleOnChange,
          disabled: isDisabled || radio.props.disabled,
          error
        });
      })}
    </div>
  );
};
