import type { COLORS } from "./constants";

// NOTE: This type has been updated
// TODO: Update it accordingly in the Task page props
export type IconProps = Omit<
  React.SVGProps<SVGSVGElement>,
  "fill" | "width" | "height"
> & {
  size?: string;
  color?: string;
  disabled?: boolean;
};

export type LiteralUnion<T extends U, U = string> =
  | T
  | (U & Record<never, never>);

export type Colors = keyof typeof COLORS;

export type RAGIndicator = "R" | "A" | "G";

export enum RAGStatusColor {
  R = "var(--red)",
  A = "var(--orange)",
  G = "var(--green)"
}

export enum RAGStatusText {
  R = "HIGH",
  A = "MED",
  G = "LOW"
}
