// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Question = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Question"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_11316_11466)">
            <path
              d="M8 0a7.99 7.99 0 00-8 8 8 8 0 0016 0c0-4.426-3.574-8-8-8zm-.213 13.724c-.604 0-1.085-.48-1.085-1.085 0-.604.48-1.084 1.085-1.084a1.09 1.09 0 011.085 1.085c-.001.604-.481 1.084-1.085 1.084zM9.51 8.16c-.48.498-.818.87-.818 1.689a.91.91 0 01-.907.906.91.91 0 01-.907-.906c0-1.547.747-2.347 1.298-2.934.48-.515.711-.782.711-1.369 0-.604-.498-1.102-1.102-1.102-.604 0-1.102.498-1.102 1.102a.91.91 0 01-.907.907.91.91 0 01-.907-.907 2.899 2.899 0 015.796 0c.036 1.334-.622 2.045-1.155 2.614z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_11316_11466">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Question;
