// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Clock = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Clock"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5 8a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM14 8A6 6 0 112 8a6 6 0 0112 0zm-5.807.255l1.727.758a.568.568 0 01.285.782.617.617 0 01-.546.348.537.537 0 01-.236-.05l-2.075-.906A.603.603 0 017 8.64V5.596C7 5.261 7.261 5 7.596 5c.336 0 .597.273.597.596v2.659z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Clock;
