// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Warning = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Warning"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15.623 10.956l-5.188-8.61C9.932 1.512 9.005 1 8.001 1c-1.004 0-1.93.513-2.434 1.345L.38 10.956a2.597 2.597 0 00-.003 2.697C.879 14.486 1.807 15 2.813 15H13.19c1.004 0 1.932-.513 2.434-1.347a2.597 2.597 0 000-2.693v-.004zm-7.662 1.786a.937.937 0 01-.866-.55.868.868 0 01.198-.978.963.963 0 011.018-.199c.35.138.58.463.582.826a.878.878 0 01-.271.636.956.956 0 01-.66.265zm.932-3.894c0 .493-.417.892-.932.892-.514 0-.932-.399-.932-.892V6.2c0-.493.418-.892.932-.892.515 0 .932.4.932.892v2.648z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Warning;
