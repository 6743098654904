// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Hidden = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Hidden"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_479_32)">
            <path
              d="M12.975 4.689l1.967-2.029c.48-.494.48-1.295 0-1.79a1.2 1.2 0 00-1.735 0L10.7 3.456a7.834 7.834 0 00-2.7-.488C4.812 2.967 1.952 4.927 0 8.03c.852 1.355 1.877 2.49 3.027 3.341L1.06 13.4a1.293 1.293 0 000 1.789 1.2 1.2 0 001.735 0L5.3 12.603a7.824 7.824 0 002.7.488c3.188 0 6.048-1.96 7.999-5.062-.851-1.354-1.876-2.49-3.025-3.34zm-8.041 3.34c0-1.745 1.375-3.164 3.067-3.164.394 0 .769.084 1.115.224L8.097 6.14c-.032-.001-.063-.01-.096-.01-1.014 0-1.84.852-1.84 1.898 0 .033.01.065.01.099l-1.02 1.05a3.198 3.198 0 01-.217-1.149zM8 11.193a2.95 2.95 0 01-1.115-.224l1.02-1.051c.031.002.062.01.095.01 1.013 0 1.84-.852 1.84-1.899 0-.033-.009-.064-.01-.097L10.85 6.88c.135.357.217.743.217 1.15 0 1.743-1.376 3.163-3.067 3.163z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_479_32">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Hidden;
