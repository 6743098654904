// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const UserGroup = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="UserGroup"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_697_21)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.028 16h-.833C2.307 16 .056 15.862 0 12.861c0-2.672 1.805-4.93 4.25-5.673A3.79 3.79 0 012.167 3.8C2.167 1.68 3.889 0 6 0a3.807 3.807 0 013.833 3.8c0 1.46-.833 2.782-2.083 3.388C10.223 7.933 12 10.19 12 12.86 12 15.862 9.75 16 6.862 16h-.834zM16 11.384c0 1.887-1.067 2.415-2.624 2.561.081-.311.124-.67.124-1.084 0-2.67-1.777-4.928-4.25-5.673 1.25-.606 2.083-1.928 2.083-3.388A3.782 3.782 0 009.923.85c.337-.119.7-.183 1.077-.183a3.172 3.172 0 013.194 3.167c0 1.216-.694 2.318-1.736 2.823C14.52 7.277 16 9.159 16 11.384z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_697_21">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default UserGroup;
