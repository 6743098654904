// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const ChevronRight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronRight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.416 9.467L7.27 13.613a1.333 1.333 0 01-2.012-.153 1.365 1.365 0 01.167-1.764L9.122 8 5.388 4.265a1.332 1.332 0 01.155-2.014 1.364 1.364 0 011.763.167l4.11 4.114a2.079 2.079 0 010 2.935z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default ChevronRight;
