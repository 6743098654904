// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const AccordionExpand = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="AccordionExpand"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_139_26)" fill="currentColor">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 10.487V5.52c0-.454-.484-.694-.76-.373L.12 7.604c-.16.187-.16.507 0 .72l2.12 2.51c.276.348.76.107.76-.347zm10 0V5.52c0-.454.484-.694.76-.373l2.12 2.457c.16.187.16.507 0 .72l-2.12 2.51c-.276.348-.76.107-.76-.347z"
            />
            <rect
              opacity={0.5}
              x={5}
              y={16}
              width={16}
              height={1.5}
              rx={0.75}
              transform="rotate(-90 5 16)"
            />
            <rect
              opacity={0.5}
              x={9}
              y={16}
              width={16}
              height={1.5}
              rx={0.75}
              transform="rotate(-90 9 16)"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_139_26">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default AccordionExpand;
