// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Thunder = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Thunder"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_2460_3373)">
            <path
              d="M12.654 6.963l-4.502 8.87a.35.35 0 01-.3.167h-.1c-.167-.067-.267-.2-.234-.367l1.1-6.636L4.35 8.93a.35.35 0 01-.3-.167.303.303 0 010-.333L9.052.16c.1-.134.267-.2.4-.134.167.067.234.234.2.4l-1.4 6.07h4.102c.1 0 .233.067.3.167a.46.46 0 010 .3z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_2460_3373">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Thunder;
