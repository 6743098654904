// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const RelatedProcesses = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="RelatedProcesses"
      icon={
        <svg
          width={restProps?.size || "100%"}
          height={restProps?.size || "100%"}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M8 1c-1.099 0-2 .901-2 2 0 .926.642 1.703 1.5 1.928v2.064h-5v3.078A2.006 2.006 0 001 12c0 1.099.901 2 2 2s2-.901 2-2c0-.925-.642-1.705-1.5-1.93V7.992H8V8h4.5v2.07A2.006 2.006 0 0011 12c0 1.099.901 2 2 2s2-.901 2-2c0-.925-.642-1.705-1.5-1.93V7h-5V4.928A2.002 2.002 0 0010 3c0-1.099-.901-2-2-2z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default RelatedProcesses;
