// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Eye = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Eye"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 8s3.295-5 8-5c4.753 0 8 5 8 5s-2.353 5-8 5c-5.6 0-8-5-8-5zm4.706 0c0 1.773 1.459 3.181 3.294 3.181S11.294 9.773 11.294 8c0-1.773-1.459-3.181-3.294-3.181S4.706 6.227 4.706 8zM8 9.364c.78 0 1.412-.61 1.412-1.364 0-.753-.632-1.364-1.412-1.364-.78 0-1.412.61-1.412 1.364 0 .753.632 1.364 1.412 1.364z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Eye;
