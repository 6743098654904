// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Backslash = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Backslash"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M9.286 3.843H4.928l1.714-1.665L5.429 1 2.252 4.087a.817.817 0 000 1.177L5.429 8.35l1.213-1.178-1.714-1.665h4.358c1.656 0 3 1.305 3 2.913 0 1.61-1.344 2.914-3 2.914H5.429V13h3.857c1.684 0 3.24-.872 4.082-2.29a4.462 4.462 0 000-4.578c-.842-1.417-2.398-2.29-4.082-2.29z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Backslash;
