export enum PermissionTypes {
  CAN_ADD_EMAIL_ADDRESS = "Can add email address",
  CAN_CHANGE_EMAIL_ADDRESS = "Can change email address",
  CAN_DELETE_EMAIL_ADDRESS = "Can delete email address",
  CAN_ADD_EMAIL_CONFIRMATION = "Can add email confirmation",
  CAN_CHANGE_EMAIL_CONFIRMATION = "Can change email confirmation",
  CAN_DELETE_EMAIL_CONFIRMATION = "Can delete email confirmation",
  CAN_ADD_LOG_ENTRY = "Can add log entry",
  CAN_CHANGE_LOG_ENTRY = "Can change log entry",
  CAN_DELETE_LOG_ENTRY = "Can delete log entry",
  CAN_ADD_GROUP = "Can add group",
  CAN_CHANGE_GROUP = "Can change group",
  CAN_DELETE_GROUP = "Can delete group",
  CAN_ADD_PERMISSION = "Can add permission",
  CAN_CHANGE_PERMISSION = "Can change permission",
  CAN_DELETE_PERMISSION = "Can delete permission",
  CAN_ADD_TOKEN = "Can add Token",
  CAN_CHANGE_TOKEN = "Can change Token",
  CAN_DELETE_TOKEN = "Can delete Token",
  CAN_ADD_ACCESS_ATTEMPT = "Can add access attempt",
  CAN_CHANGE_ACCESS_ATTEMPT = "Can change access attempt",
  CAN_DELETE_ACCESS_ATTEMPT = "Can delete access attempt",
  CAN_ADD_ACCESS_LOG = "Can add access log",
  CAN_CHANGE_ACCESS_LOG = "Can change access log",
  CAN_DELETE_ACCESS_LOG = "Can delete access log",
  CAN_ADD_CONFIGURATION = "Can add configuration",
  CAN_CHANGE_CONFIGURATION = "Can change configuration",
  CAN_DELETE_CONFIGURATION = "Can delete configuration",
  CAN_ADD_COMPANY = "Can add company",
  CAN_ADD_CUSTOMER = "Can add customer",
  CAN_CHANGE_COMPANY = "Can change company",
  CAN_CHANGE_CUSTOMER = "Can change customer",
  CAN_DELETE_COMPANY = "Can delete company",
  CAN_DELETE_CUSTOMER = "Can delete customer",
  CAN_ADD_CONTENT_TYPE = "Can add content type",
  CAN_CHANGE_CONTENT_TYPE = "Can change content type",
  CAN_DELETE_CONTENT_TYPE = "Can delete content type",
  CAN_ADD_CONSTANCE = "Can add constance",
  CAN_CHANGE_CONSTANCE = "Can change constance",
  CAN_DELETE_CONSTANCE = "Can delete constance",
  CAN_ADD_FLAT_PAGE = "Can add flat page",
  CAN_CHANGE_FLAT_PAGE = "Can change flat page",
  CAN_DELETE_FLAT_PAGE = "Can delete flat page",
  CAN_ADD_ACTIVITY_LOG = "Can add activity log",
  CAN_VIEW_ACTIVITY_LOG = "Can View Activity Log",
  CAN_CHANGE_ACTIVITY_LOG = "Can change activity log",
  CAN_DELETE_ACTIVITY_LOG = "Can delete activity log",
  CAN_ADD_ALERT = "Can add alert",
  CAN_VIEW_ALERTS = "Can View Alerts",
  CAN_CHANGE_ALERT = "Can add alert subscriber",
  CAN_DELETE_ALERT = "Can delete alert",
  CAN_ADD_ALERT_ACTION = "Can add alert action",
  CAN_CHANGE_ALERT_ACTION = "Can change alert action",
  CAN_DELETE_ALERT_ACTION = "Can delete alert action",
  CAN_ADD_ALERT_CATEGORY = "Can add alert category",
  CAN_CHANGE_ALERT_CATEGORY = "Can change alert category",
  CAN_DELETE_ALERT_CATEGORY = "Can delete alert category",
  CAN_ADD_ALERT_MAPPING = "Can add alert mapping",
  CAN_CHANGE_ALERT_MAPPING = "Can change alert mapping",
  CAN_DELETE_ALERT_MAPPING = "Can delete alert mapping",
  CAN_ADD_ALERT_STATUS = "Can add alert status",
  CAN_CHANGE_ALERT_STATUS = "Can change alert status",
  CAN_DELETE_ALERT_STATUS = "Can delete alert status",
  CAN_ADD_ALERT_TRIGGER = "Can add alert trigger",
  CAN_CHANGE_ALERT_TRIGGER = "Can change alert trigger",
  CAN_DELETE_ALERT_TRIGGER = "Can delete alert trigger",
  CAN_ADD_ALTERNATIVE_NAME = "Can add alternative name",
  CAN_CHANGE_ALTERNATIVE_NAME = "Can change alternative name",
  CAN_DELETE_ALTERNATIVE_NAME = "Can delete alternative name",
  CAN_ADD_API_CALL_LOG = "Can add api call log",
  CAN_CHANGE_API_CALL_LOG = "Can change api call log",
  CAN_DELETE_API_CALL_LOG = "Can delete api call log",
  CAN_ADD_BUSINESS_UNIT = "Can add business unit",
  CAN_CHANGE_BUSINESS_UNIT = "Can change business unit",
  CAN_DELETE_BUSINESS_UNIT = "Can delete business unit",
  CAN_ADD_CHANNEL = "Can add channel",
  CAN_CHANGE_CHANNEL = "Can change channel",
  CAN_DELETE_CHANNEL = "Can delete channel",
  CAN_ADD_CHILD_WORKFLOW_CREATION_CONSTRAINT = "Can add child workflow creation constraint",
  CAN_CHANGE_CHILD_WORKFLOW_CREATION_CONSTRAINT = "Can change child workflow creation constraint",
  CAN_DELETE_CHILD_WORKFLOW_CREATION_CONSTRAINT = "Can delete child workflow creation constraint",
  CAN_ADD_CITY = "Can add city",
  CAN_CHANGE_CITY = "Can change city",
  CAN_DELETE_CITY = "Can delete city",
  CAN_ADD_COMMENT_FLAG = "Can add comment flag",
  CAN_CHANGE_COMMENT_FLAG = "Can change comment flag",
  CAN_DELETE_COMMENT_FLAG = "Can delete comment flag",
  CAN_ADD_COMMENT_FLAG_OPTION = "Can add comment flag option",
  CAN_CHANGE_COMMENT_FLAG_OPTION = "Can change comment flag option",
  CAN_DELETE_COMMENT_FLAG_OPTION = "Can delete comment flag option",
  CAN_ADD_COUNTRY = "Can add country",
  CAN_CHANGE_COUNTRY = "Can change country",
  CAN_DELETE_COUNTRY = "Can delete country",
  CAN_ADD_DASHBOARD_MAP = "Can add dashboard map",
  CAN_CHANGE_DASHBOARD_MAP = "Can change dashboard map",
  CAN_DELETE_DASHBOARD_MAP = "Can delete dashboard map",
  CAN_ADD_DATA_IMPORT = "Can add data import",
  CAN_CHANGE_DATA_IMPORT = "Can change data import",
  CAN_DELETE_DATA_IMPORT = "Can delete data import",
  CAN_ADD_DATA_MIGRATION = "Can add data migration",
  CAN_CHANGE_DATA_MIGRATION = "Can change data migration",
  CAN_DELETE_DATA_MIGRATION = "Can delete data migration",
  CAN_ADD_DATA_ROW = "Can add data row",
  CAN_CHANGE_DATA_ROW = "Can change data row",
  CAN_DELETE_DATA_ROW = "Can delete data row",
  CAN_ADD_EMAILATTACHMENT = "Can add EmailAttachment",
  CAN_CHANGE_EMAILATTACHMENT = "Can change EmailAttachment",
  CAN_DELETE_EMAILATTACHMENT = "Can delete EmailAttachment",
  CAN_ADD_EMAILTEMPLATE = "Can add EmailTemplate",
  CAN_CHANGE_EMAILTEMPLATE = "Can change EmailTemplate",
  CAN_DELETE_EMAILTEMPLATE = "Can delete EmailTemplate",
  CAN_ADD_ENCRYPTED_RESPONSE = "Can add encrypted response",
  CAN_CHANGE_ENCRYPTED_RESPONSE = "Can change encrypted response",
  CAN_DELETE_ENCRYPTED_RESPONSE = "Can delete encrypted response",
  CAN_ADD_EXTRA_JSON = "Can add extra json",
  CAN_CHANGE_EXTRA_JSON = "Can change extra json",
  CAN_DELETE_EXTRA_JSON = "Can delete extra json",
  CAN_ADD_FEATURE = "Can add feature",
  CAN_CHANGE_FEATURE = "Can change feature",
  CAN_DELETE_FEATURE = "Can delete feature",
  CAN_ADD_FIELD = "Can add field",
  CAN_CHANGE_FIELD = "Can change field",
  CAN_DELETE_FIELD = "Can delete field",
  CAN_ADD_FIELD_CONSTRAINT = "Can add field constraint",
  CAN_CHANGE_FIELD_CONSTRAINT = "Can change field constraint",
  CAN_DELETE_FIELD_CONSTRAINT = "Can delete field constraint",
  CAN_ADD_FIELD_DEF = "Can add field def",
  CAN_CHANGE_FIELD_DEF = "Can change field def",
  CAN_DELETE_FIELD_DEF = "Can delete field def",
  CAN_ADD_FIELD_INTEGRATION_TRIGGER_CONSTRAINT = "Can add field integration trigger constraint",
  CAN_CHANGE_FIELD_INTEGRATION_TRIGGER_CONSTRAINT = "Can change field integration trigger constraint",
  CAN_DELETE_FIELD_INTEGRATION_TRIGGER_CONSTRAINT = "Can delete field integration trigger constraint",
  CAN_ADD_HISTORICAL_INTEGRATION_CONFIG = "Can add historical integration config",
  CAN_CHANGE_HISTORICAL_INTEGRATION_CONFIG = "Can change historical integration config",
  CAN_DELETE_HISTORICAL_INTEGRATION_CONFIG = "Can delete historical integration config",
  CAN_ADD_HISTORICAL_INTEGRATION_TOKEN = "Can add historical integration token",
  CAN_CHANGE_HISTORICAL_INTEGRATION_TOKEN = "Can change historical integration token",
  CAN_DELETE_HISTORICAL_INTEGRATION_TOKEN = "Can delete historical integration token",
  CAN_ADD_INHERITANCE_CONSTRAINT = "Can add inheritance constraint",
  CAN_CHANGE_INHERITANCE_CONSTRAINT = "Can change inheritance constraint",
  CAN_DELETE_INHERITANCE_CONSTRAINT = "Can delete inheritance constraint",
  CAN_ADD_INTEGRATION_CONFIG = "Can add integration config",
  CAN_CHANGE_INTEGRATION_CONFIG = "Can change integration config",
  CAN_DELETE_INTEGRATION_CONFIG = "Can delete integration config",
  CAN_ADD_INTEGRATION_RESULT = "Can add integration result",
  CAN_CHANGE_INTEGRATION_RESULT = "Can change integration result",
  CAN_DELETE_INTEGRATION_RESULT = "Can delete integration result",
  CAN_ADD_INTEGRATION_TOKEN = "Can add integration token",
  CAN_CHANGE_INTEGRATION_TOKEN = "Can change integration token",
  CAN_DELETE_INTEGRATION_TOKEN = "Can delete integration token",
  CAN_ADD_LANGUAGE = "Can add language",
  CAN_CHANGE_LANGUAGE = "Can change language",
  CAN_DELETE_LANGUAGE = "Can delete language",
  CAN_ADD_LOGGED_IN_USER = "Can add logged in user",
  CAN_CHANGE_LOGGED_IN_USER = "Can change logged in user",
  CAN_DELETE_LOGGED_IN_USER = "Can delete logged in user",
  CAN_ADD_MESSAGE = "Can add message",
  CAN_CHANGE_MESSAGE = "Can change message",
  CAN_DELETE_MESSAGE = "Can delete message",
  CAN_ADD_MIGRATION_ROW = "Can add migration row",
  CAN_CHANGE_MIGRATION_ROW = "Can change migration row",
  CAN_DELETE_MIGRATION_ROW = "Can delete migration row",
  CAN_ADD_NOTIFICATION = "Can add notification",
  CAN_CHANGE_NOTIFICATION = "Can change notification",
  CAN_DELETE_NOTIFICATION = "Can delete notification",
  CAN_ADD_NOTIFICATION_CONSTRAINT = "Can add notification constraint",
  CAN_CHANGE_NOTIFICATION_CONSTRAINT = "Can change notification constraint",
  CAN_DELETE_NOTIFICATION_CONSTRAINT = "Can delete notification constraint",
  CAN_ADD_NOTIFICATION_DEF = "Can add notification def",
  CAN_CHANGE_NOTIFICATION_DEF = "Can change notification def",
  CAN_DELETE_NOTIFICATION_DEF = "Can delete notification def",
  CAN_ADD_REDUCTION_CONSTRAINT = "Can add reduction constraint",
  CAN_CHANGE_REDUCTION_CONSTRAINT = "Can change reduction constraint",
  CAN_DELETE_REDUCTION_CONSTRAINT = "Can delete reduction constraint",
  CAN_ADD_REGION = "Can add region",
  CAN_CHANGE_REGION = "Can change region",
  CAN_DELETE_REGION = "Can delete region",
  CAN_ADD_RELATED_WORKFLOW_FIELD_CONSTRAINT = "Can add related workflow field constraint",
  CAN_CHANGE_RELATED_WORKFLOW_FIELD_CONSTRAINT = "Can change related workflow field constraint",
  CAN_DELETE_RELATED_WORKFLOW_FIELD_CONSTRAINT = "Can delete related workflow field constraint",
  CAN_ADD_REQUEST = "Can add request",
  CAN_CHANGE_REQUEST = "Can change request",
  CAN_DELETE_REQUEST = "Can delete request",
  CAN_ADD_RESPONSE = "Can add response",
  CAN_CHANGE_RESPONSE = "Can change response",
  CAN_DELETE_RESPONSE = "Can delete response",
  CAN_ADD_RESPONSE_VERSION = "Can add response version",
  CAN_CHANGE_RESPONSE_VERSION = "Can change response version",
  CAN_DELETE_RESPONSE_VERSION = "Can delete response version",
  CAN_ADD_RESULT = "Can add result",
  CAN_CHANGE_RESULT = "Can change result",
  CAN_DELETE_RESULT = "Can delete result",
  CAN_ADD_SENDGRID_EMAIL_STATUS = "Can add sendgrid email status",
  CAN_CHANGE_SENDGRID_EMAIL_STATUS = "Can change sendgrid email status",
  CAN_DELETE_SENDGRID_EMAIL_STATUS = "Can delete sendgrid email status",
  CAN_ADD_SENTIMENT_CHECK = "Can add sentiment check",
  CAN_CHANGE_SENTIMENT_CHECK = "Can change sentiment check",
  CAN_DELETE_SENTIMENT_CHECK = "Can delete sentiment check",
  CAN_ADD_STEP = "Can add step",
  CAN_CHANGE_STEP = "Can change step",
  CAN_DECRYPT_FIELDS_IN_A_STEP = "Can decrypt fields in a step",
  CAN_DELETE_STEP = "Can delete step",
  CAN_SUBMIT_A_STEP = "Can submit a step",
  CAN_UNDO_A_STEP = "Can undo a step",
  CAN_ADD_STEP_ACTION = "Can add step action",
  CAN_CHANGE_STEP_ACTION = "Can change step action",
  CAN_DELETE_STEP_ACTION = "Can delete step action",
  CAN_ADD_STEP_ACTION_CONSTRAINT = "Can add step action constraint",
  CAN_CHANGE_STEP_ACTION_CONSTRAINT = "Can change step action constraint",
  CAN_DELETE_STEP_ACTION_CONSTRAINT = "Can delete step action constraint",
  CAN_ADD_STEP_CONSTRAINT = "Can add step constraint",
  CAN_CHANGE_STEP_CONSTRAINT = "Can change step constraint",
  CAN_DELETE_STEP_CONSTRAINT = "Can delete step constraint",
  CAN_ADD_STEP_DEF = "Can add step def",
  CAN_CHANGE_STEP_DEF = "Can change step def",
  CAN_DELETE_STEP_DEF = "Can delete step def",
  CAN_ADD_STEP_GROUP = "Can add step group",
  CAN_CHANGE_STEP_GROUP = "Can change step group",
  CAN_DELETE_STEP_GROUP = "Can delete step group",
  CAN_ADD_STEP_GROUP_CONSTRAINT = "Can add step group constraint",
  CAN_CHANGE_STEP_GROUP_CONSTRAINT = "Can change step group constraint",
  CAN_DELETE_STEP_GROUP_CONSTRAINT = "Can delete step group constraint",
  CAN_ADD_STEP_GROUP_DEF = "Can add step group def",
  CAN_CHANGE_STEP_GROUP_DEF = "Can change step group def",
  CAN_DELETE_STEP_GROUP_DEF = "Can delete step group def",
  CAN_ADD_STEP_TAG_FOR_USER = "Can add step tag for user",
  CAN_CHANGE_STEP_TAG_FOR_USER = "Can change step tag for user",
  CAN_DELETE_STEP_TAG_FOR_USER = "Can delete step tag for user",
  CAN_ADD_SUBREGION = "Can add subregion",
  CAN_CHANGE_SUBREGION = "Can change subregion",
  CAN_DELETE_SUBREGION = "Can delete subregion",
  CAN_ADD_USER = "Can add user",
  CAN_CHANGE_USER = "Can change user",
  CAN_DELETE_USER = "Can delete user",
  CAN_ADD_USER_PROFILE = "Can add user profile",
  CAN_CHANGE_USER_PROFILE = "Can change user profile",
  CAN_DELETE_USER_PROFILE = "Can delete user profile",
  CAN_ADD_WORKFLOW = "Can add workflow",
  CAN_ARCHIVE_WORKFLOW = "Can archive workflow",
  CAN_ARCHIVE_WORKFLOWS = "Can Archive Workflows",
  CAN_VIEW_DASHBOARD = "Can view dashboard",
  CAN_VIEW_PROCESS_REPORT = "Can view process report",
  CAN_ADD_PROCESS_REPORT = "Can add process report",
  CAN_EDIT_PROCESS_REPORT = "Can edit process report",
  CAN_DELETE_PROCESS_REPORT = "Can delete process report",
  CAN_VIEW_TASKS = "Can view tasks",
  CAN_VIEW_WORKFLOW_PROFILE = "Can view workflow profile",
  CAN_VIEW_WORKFLOW_STATUS = "Can view Workflow Status",
  CAN_CHANGE_WORKFLOW = "Can change workflow",
  CAN_DELETE_WORKFLOW = "Can delete workflow",
  CAN_ADD_WORKFLOW_DEF = "Can add workflow def",
  CAN_CHANGE_WORKFLOW_DEF = "Can change workflow def",
  CAN_DELETE_WORKFLOW_DEF = "Can delete workflow def",
  CAN_ADD_WORKFLOW_DEF_JSON = "Can add workflow def json",
  CAN_CHANGE_WORKFLOW_DEF_JSON = "Can change workflow def json",
  CAN_DELETE_WORKFLOW_DEF_JSON = "Can delete workflow def json",
  CAN_ADD_WORKFLOW_KIND = "Can add workflow kind",
  CAN_CHANGE_WORKFLOW_KIND = "Can change workflow kind",
  CAN_CHECKMARK_RELATED_WORKFLOWS = "Can checkmark related workflows",
  CAN_DELETE_WORKFLOW_KIND = "Can delete workflow kind",
  CAN_EXPORT_WORKFLOW_DATA = "Can export workflow data",
  CAN_ADD_WORKFLOW_PDF_PRINT = "Can add workflow pdf print",
  CAN_CHANGE_WORKFLOW_PDF_PRINT = "Can change workflow pdf print",
  CAN_DELETE_WORKFLOW_PDF_PRINT = "Can delete workflow pdf print",
  CAN_ADD_WORKFLOW_PDF_PRINT_DEF = "Can add workflow pdf print def",
  CAN_CHANGE_WORKFLOW_PDF_PRINT_DEF = "Can change workflow pdf print def",
  CAN_DELETE_WORKFLOW_PDF_PRINT_DEF = "Can delete workflow pdf print def",
  CAN_ADD_WORKFLOW_PDF_TEMPLATE = "Can add Workflow PDF Template",
  CAN_CHANGE_WORKFLOW_PDF_TEMPLATE = "Can change Workflow PDF Template",
  CAN_DELETE_WORKFLOW_PDF_TEMPLATE = "Can delete Workflow PDF Template",
  CAN_ADD_WORKFLOW_STATUS = "Can add workflow status",
  CAN_VIEW_DETAILED_STATUS = "Can View Detailed Status",
  CAN_CHANGE_WORKFLOW_STATUS = "Can change workflow status",
  CAN_DELETE_WORKFLOW_STATUS = "Can delete workflow status",
  CAN_ADD_TOTP_DEVICE = "Can add TOTP device",
  CAN_CHANGE_TOTP_DEVICE = "Can change TOTP device",
  CAN_DELETE_TOTP_DEVICE = "Can delete TOTP device",
  CAN_ADD_SESSION = "Can add session",
  CAN_CHANGE_SESSION = "Can change session",
  CAN_DELETE_SESSION = "Can delete session",
  CAN_ADD_SITE = "Can add site",
  CAN_CHANGE_SITE = "Can change site",
  CAN_DELETE_SITE = "Can delete site",
  CAN_CHANGE_ALERT_SUBSCRIBER = "Can change alert subscriber",
  CAN_DELETE_ALERT_SUBSCRIBER = "Can delete alert subscriber",
  CAN_ADD_INTEGRATION_POLLING_CONSTRAINT = "Can add integration polling constraint",
  CAN_CHANGE_INTEGRATION_POLLING_CONSTRAINT = "Can change integration polling constraint",
  CAN_DELETE_INTEGRATION_POLLING_CONSTRAINT = "Can delete integration polling constraint",
  CAN_BYPASS_CAPTCHA = "Can Bypass Captcha",
  CAN_VIEW_PROGRESS_MAP = "Can view progress map",
  CAN_DEPLOY_TO_DEVELOPMENT = "Can deploy to Development",
  CAN_DEPLOY_TO_QA = "Can deploy to QA",
  CAN_DEPLOY_TO_STAGING = "Can deploy to Staging",
  CAN_DEPLOY_TO_PRODUCTION = "Can deploy to Production",
  CAN_DEPLOY_TO_UAT = "Can deploy to UAT",
  CAN_ACCESS_ADMIN_SETTINGS = "Can access admin settings",
  CAN_IMPERSONATE_OTHER_USERS = "Can impersonate other users",
  CAN_GENERATE_TEMPLATE_JSON = "Can generate template JSON",
  CAN_CHANGE_SCHEDULED_REPORT_EXPORT = "Can change Scheduled Report Export",
  CAN_CHANGE_SFTP_SCHEDULED_REPORT_EXPORT = "Can change SFTP Scheduled Report Export",
  CAN_DELETE_SCHEDULED_REPORT_EXPORT = "Can delete Scheduled Report Export",
  CAN_DELETE_SFTP_SCHEDULED_REPORT_EXPORT = "Can delete SFTP Scheduled Report Export",
  CAN_ACCESS_STUDIO = "can_access_studio",
  CAN_LINK_DELINK_WORKFLOWS = "Can link/delink workflows"
}
