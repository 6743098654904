// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const SingleFile = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="SingleFile"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_13780_11594)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.5 6v8.5h-9v-13H8V6h4.5zM2 1a1 1 0 011-1h5.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a1 1 0 01-1 1H3a1 1 0 01-1-1V1z"
              fill="currentColor"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.653 7.454C7.96 7.164 8.38 7 8.817 7c.437 0 .856.163 1.165.454.309.29.482.684.482 1.095 0 .411-.173.805-.482 1.096l-1.98 1.863a1.034 1.034 0 01-.708.276c-.266 0-.52-.1-.708-.276a.914.914 0 01-.293-.665c0-.25.105-.49.293-.666l1.98-1.863a.37.37 0 01.502 0 .32.32 0 010 .471l-1.98 1.863a.267.267 0 00-.086.194c0 .073.03.143.085.195.055.051.13.08.207.08a.302.302 0 00.206-.08l1.98-1.863a.857.857 0 00.276-.625.857.857 0 00-.275-.624.97.97 0 00-.664-.258.97.97 0 00-.663.258L6.173 9.788c-.297.28-.464.66-.464 1.054 0 .396.167.775.464 1.055.297.28.7.436 1.12.436.421 0 .824-.157 1.121-.436l1.981-1.864a.37.37 0 01.501 0 .32.32 0 010 .472l-1.98 1.863A2.37 2.37 0 017.293 13a2.37 2.37 0 01-1.622-.632A2.095 2.095 0 015 10.842c0-.572.242-1.12.672-1.525l1.98-1.863z"
            fill="currentColor"
          />
          <defs>
            <clipPath id="prefix__clip0_13780_11594">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default SingleFile;
