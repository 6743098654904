// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Template = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Template"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_479_70)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.2 0H.8C.32 0 0 .32 0 .8v3.04c0 .08.08.16.16.16h15.68c.08 0 .16-.08.16-.16V.8c0-.48-.32-.8-.8-.8zm.64 5.6H5.76c-.08 0-.16.08-.16.16v10.08c0 .08.08.16.16.16h9.44c.48 0 .8-.32.8-.8V5.76c0-.08-.08-.16-.16-.16zM.16 5.6h3.68c.08 0 .16.08.16.16v10.08c0 .08-.08.16-.16.16H.8c-.48 0-.8-.32-.8-.8V5.76c0-.08.08-.16.16-.16z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_479_70">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Template;
