// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Progress = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Progress"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_5848_4257)">
            <path
              d="M16 8A8 8 0 110 8a8 8 0 0116 0zM3.2 8a4.8 4.8 0 109.6 0 4.8 4.8 0 00-9.6 0z"
              fill=""
            />
            <path
              d="M8 0A8 8 0 11.58 5.01l2.968 1.196A4.8 4.8 0 108 3.2V0z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_5848_4257">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Progress;
