// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Logout = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Logout"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_547_24)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.648 12.556a1.133 1.133 0 010-1.606l1.809-1.803H1.139a1.137 1.137 0 110-2.272h7.338L6.647 5.05a1.133 1.133 0 010-1.607 1.142 1.142 0 011.612 0l4.032 4.02a.757.757 0 010 1.073l-4.032 4.02a1.141 1.141 0 01-1.611 0zm7.206-11.49v13.867c0 .59.48 1.067 1.073 1.067.48 0 1.073-.477 1.073-1.067V1.067C16 .477 15.52 0 14.927 0c-.48 0-1.073.477-1.073 1.067z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_547_24">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Logout;
