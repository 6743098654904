// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Sum = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Sum"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14 12.646V16H2v-.958l5.156-6.994L2 .64V0h12v3.64l-1.938-2.458-6.53-.064 4.53 6.004-4.53 6.387v.735h6.655L14 12.646z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Sum;
