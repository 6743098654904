// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Clear = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Clear"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_3355_4011)">
            <path
              d="M13.726 2.274A8.098 8.098 0 002.274 13.726 8.098 8.098 0 0013.726 2.274zm-2.276 8.027V10.3a.811.811 0 01-.574 1.385.809.809 0 01-.576-.235L8 9.142 5.7 11.45a.809.809 0 01-.575.235.807.807 0 01-.815-.81c0-.216.086-.422.24-.575L6.858 8 4.55 5.7A.814.814 0 015.7 4.55L8 6.858l2.3-2.308a.814.814 0 011.15 1.15L9.142 8l2.308 2.3z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_3355_4011">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Clear;
