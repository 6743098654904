// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const App = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="App"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.87 11.2V4.8a1.6 1.6 0 00-.776-1.372L8.759.228a1.602 1.602 0 00-1.645 0l-5.335 3.2c-.482.289-.778.81-.779 1.371v6.402a1.6 1.6 0 00.776 1.37l5.335 3.202a1.601 1.601 0 001.645 0l5.335-3.201c.482-.289.778-.81.779-1.371zM7.935 1.6l4.555 2.733-4.555 2.734L3.38 4.332 7.935 1.6zM2.6 11.2V5.733l4.535 2.72v5.468L2.6 11.201zm6.135 2.72V8.453l4.535-2.72V11.2l-4.535 2.72z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default App;
