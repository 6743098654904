import type { TypographyVariants } from "./types";
import { css } from "@emotion/core";
import { COLORS, TYPOGRAPHY_VARIANTS } from "../../constants";

// Using this to avoid passing complete var(--colorName)
export const getTextColor = (color?: string) => {
  // If color is not given return undefined, to avoid any breakages
  if (!color) return color;

  // If passed a design system color then use color as a variable
  if (COLORS.hasOwnProperty(color)) return `var(--${color})`;

  // Else return the hexcode/rgb color passed
  return color;
};

export const getEllipsisWidth = (ellipsis?: string | number) => {
  if (!ellipsis) return undefined;

  const isNumber = !isNaN(Number(ellipsis));

  return isNumber ? `${ellipsis}px` : ellipsis;
};

export const getVariantStyles = (
  variantName: TypographyVariants,
  excludeLineHeight: boolean = false
) => {
  const variantValue = TYPOGRAPHY_VARIANTS[variantName];
  if (variantValue) {
    return css`
      font-size: ${variantValue.fontSize};
      ${!excludeLineHeight &&
      css`
        line-height: ${variantValue.lineHeight};
      `};
      font-weight: ${variantValue.fontWeight};
      ${variantValue.fontStyle ? `font-style: ${variantValue.fontStyle};` : ""}
      ${variantValue.textTransform
        ? `text-transform: ${variantValue.textTransform};`
        : ""}
    `;
  }

  // When incorrent variant passed, fallback to p1-regular
  return css`
    font-size: ${TYPOGRAPHY_VARIANTS["p1-regular"].fontSize};
    line-height: ${TYPOGRAPHY_VARIANTS["p1-regular"].lineHeight};
    font-weight: ${TYPOGRAPHY_VARIANTS["p1-regular"].fontWeight};
  `;
};
