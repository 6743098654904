// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Language = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Language"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_547_8)">
            <path
              d="M0 0v9.143h9.143V0H0zm4 1.714h1.143L7.429 7.43H6.286l-.458-1.143H3.315l-.458 1.143H1.714L4 1.714zm.571 1.429l-.8 2h1.601l-.8-2zm5.715 3.714V8h4.571v6.857H8v-4.571H6.857V16H16V6.857h-5.714zm.571 1.714v1.143H8.571v1.143h1.175c.09.792.371 1.435.763 1.937-.588.251-1.296.349-1.937.349v1.143c.89 0 1.948-.136 2.857-.68.91.544 1.967.68 2.857.68v-1.143c-.641 0-1.349-.098-1.937-.35.392-.5.673-1.144.764-1.936h1.174V9.714H12V8.571h-1.143zm.04 2.286h1.064c-.075.572-.264.98-.532 1.287-.268-.307-.457-.716-.532-1.287z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_547_8">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Language;
