// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Plus = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Plus"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M8 2c-.764 0-1.385.62-1.385 1.385v3.23h-3.23a1.385 1.385 0 000 2.77h3.23v3.23a1.385 1.385 0 002.77 0v-3.23h3.23a1.385 1.385 0 000-2.77h-3.23v-3.23C9.385 2.62 8.765 2 8 2z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Plus;
