import React from "react";
import ReactDOM from "react-dom";

type PortalProps = {
  children: React.ReactNode;
  container?: HTMLElement | (() => HTMLElement) | null;
  containerId?: string;
  disablePortal?: boolean;
};

function getContainer(
  container: PortalProps["container"],
  containerId: PortalProps["containerId"]
) {
  if (container) {
    return typeof container === "function" ? container() : container;
  }
  if (containerId) {
    return document.getElementById(containerId);
  }
  return document.body;
}

export const Portal = (props: PortalProps) => {
  const {
    children,
    container,
    containerId,
    disablePortal: shouldDisablePortal = false
  } = props;

  if (shouldDisablePortal) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const mountNode = getContainer(container, containerId);

  return (
    <React.Fragment>
      {mountNode ? ReactDOM.createPortal(children, mountNode) : null}
    </React.Fragment>
  );
};
