// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Vendor = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Vendor"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.513 2.723H1.554c-.15 0-.3.014-.447.053-.65.179-1.099.796-1.106 1.474v1.955 6.939c0 .229 0 .439.038.668.008.047.02.09.036.136a1.415 1.415 0 00.425.66c.18.152.34.249.565.326.138.046.293.065.436.066H14.314c.203 0 .406 0 .603-.064a1.55 1.55 0 001.076-1.464v-7.1a.613.613 0 000-.19V4.327c0-.14-.014-.273-.038-.412a.832.832 0 00-.033-.125 1.895 1.895 0 00-.13-.3 1.537 1.537 0 00-1.326-.766h-3.181a3.608 3.608 0 00-.168-.526 3.723 3.723 0 00-.343-.687 3.436 3.436 0 00-.941-.953c-.5-.338-1.096-.5-1.69-.548a3.515 3.515 0 00-.46-.003 3.616 3.616 0 00-1.598.453c-.765.435-1.29 1.194-1.52 2.043-.02.073-.037.146-.052.22zm1.66 0H9.61l-.002-.007a2.674 2.674 0 00-.242-.427 2.43 2.43 0 00-.289-.293 2.467 2.467 0 00-.377-.22 2.812 2.812 0 00-.504-.139 3.756 3.756 0 00-.632 0c-.175.032-.347.079-.513.141-.128.062-.25.133-.366.216-.095.081-.183.17-.263.266-.088.126-.164.26-.228.4a3.07 3.07 0 00-.022.063zm8.225 1.624H1.835a6.25 6.25 0 01-.233-.003V5.445h12.795V4.478v-.083l.001-.048zm-.001 2.722H10.903v.73a.797.797 0 01-.014.15c-.037.342-.181.658-.416.911-.266.288-.632.43-1.011.46a2.783 2.783 0 01-.264.004H7.685l-.271.003c-.297.006-.598.012-.888-.044a1.425 1.425 0 01-.749-.437 1.566 1.566 0 01-.409-.992 3.74 3.74 0 01-.002-.295l.001-.136v-.354H1.602V13.326l-.002.049H14.161l.116.001.12.002v-.65V7.07zm-.043 6.435l.008-.01-.008.01zm-12.88-.172l.01.008a.497.497 0 01-.01-.008zm.17-9.115l-.007.01a.744.744 0 01.007-.01zm12.88.172l-.01-.007a.473.473 0 01.01.007zM6.969 7.321V7.07h2.337v.539a3.22 3.22 0 00-.002.095h-.051l-.083-.001H7.163l-.102.001-.097.001c0-.072 0-.144.003-.215l.002-.169z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Vendor;
