import type { AlignType } from "@rc-component/trigger";

// htmlRegin visible means if space at bottom is not available
// than cascader will open above, so menu opens inside the
// visible region of the viewport
// this accompanied by fixed height of cascader gives a standard user
// experience
const htmlRegion: AlignType["htmlRegion"] = "visible";

const sharedConfig = {
  overflow: {
    adjustX: true,
    adjustY: true,
    shiftY: true
  },
  htmlRegion
};

export const CASCADER_PLACEMENTS = {
  bottomLeft: {
    ...sharedConfig,
    points: ["tl", "bl"],
    offset: [0, 4]
  },
  bottomRight: {
    ...sharedConfig,
    points: ["tr", "br"],
    offset: [0, 4]
  },
  topLeft: {
    ...sharedConfig,
    points: ["bl", "tl"],
    offset: [0, -4]
  },
  topRight: {
    ...sharedConfig,
    points: ["br", "tr"],
    offset: [0, -4]
  }
};
