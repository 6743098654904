const ALLOWED_FILE_SIZE_IN_MB = 30;

export const UPLOAD_FILE_MAX_SIZE = ALLOWED_FILE_SIZE_IN_MB * Math.pow(1024, 2);
export const convertMBToBytes = (mb: number) => mb * Math.pow(1024, 2);

export const ALLOWED_EXTENSIONS = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "csv",
  "txt",
  "png",
  "jpg",
  "jpeg",
  "cer",
  "msg",
  "zip",
  "ppt",
  "pptx",
  "mp4",
  "mov"
];

export const IMAGE_FILE_TYPES_FOR_PREVIEW = ["png", "jpg", "jpeg"];
export const VIDEO_FILE_TYPES = ["mp4", "mov"];

export const formatBytes = ({ bytes }: any) => {
  if (bytes === 0) return "0 Bytes";

  const blockSize = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const sizeIndex = Math.floor(Math.log(bytes) / Math.log(blockSize));
  return `${parseFloat((bytes / Math.pow(blockSize, sizeIndex)).toFixed(2))} ${
    sizes[sizeIndex]
  }`;
};

export const getFileExtension = ({ file }: any) => {
  return file.name.split(".").pop().toLowerCase();
};

/**
 * TODO: Add INTL in error message
 */
export const validateUploadFile = ({
  file,
  allowedExtensions,
  allowedFileSizeLimitInMBs
}: {
  file: File;
  allowedExtensions?: string[];
  allowedFileSizeLimitInMBs?: number;
}) => {
  const fileExtension = getFileExtension({ file });
  const allowedExtensionsArray = allowedExtensions || ALLOWED_EXTENSIONS;

  if (allowedExtensionsArray.indexOf(fileExtension) === -1) {
    return {
      valid: false,
      error: `File extension ${fileExtension} is not allowed.
      Allowed extensions are: '${allowedExtensionsArray.join(", ")}'.`
    };
  }
  const fileSizeLimitInMBs =
    allowedFileSizeLimitInMBs ?? ALLOWED_FILE_SIZE_IN_MB;
  if (file.size > convertMBToBytes(fileSizeLimitInMBs)) {
    return {
      valid: false,
      error: `Ensure this file size is not greater than ${fileSizeLimitInMBs} MB.
      Your file size is ${formatBytes({ bytes: file.size })}.`
    };
  }

  return {
    valid: true
  };
};

/**
 * @deprecated
 * @param url download url of file or blob
 * @param fileName name of file to be downloaded
 * Download a file by it URL or blob reference url
 */
export const downloadFile = (
  url: string,
  fileName?: string,
  isNewTab = false
) => {
  const a = document.createElement("a");
  a.style.display = "none"; //optional
  a.href = url;
  a.download = fileName || "Untitled file";
  if (isNewTab === true) {
    a.target = "_blank";
  }
  document.body.appendChild(a);
  a.click();
};

export const getFileType = (fileName: string) => {
  const fileSplitArray = fileName.split(".");
  const fileType = fileSplitArray[fileSplitArray.length - 1];
  return fileType;
};

/**
Format bytes as human-readable text.
@param bytes Number of bytes.
@param siUnit True to use metric (SI) units, aka powers of 1000. False to use
      binary (IEC), aka powers of 1024.

@param depth Number of decimal places to display.
@return Formatted string.
**/
export const getHumanReadableFileSize = (
  bytes: number,
  siUnit = true,
  depth = 1
) => {
  const thresh = siUnit ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = siUnit
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** depth;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(depth) + " " + units[u];
};

export const ERROR_MESSAGE_FILE_SIZE_OR_TYPE =
  "Either file size exceeded limit (30MB) or file type is not supported.";

/**
 * Extracts the filename from a given attachment URL.
 *
 * @param attachment - The attachment URL.
 * @returns The extracted filename.
 */
export const getFileNameFromAttachmentUrl = (attachment: string): string => {
  // Extract the filename from the attachment URL
  const encodedFileName = attachment.substring(
    attachment.lastIndexOf("/") + 1,
    attachment.lastIndexOf("?")
  );

  // Decode the filename if it's URL-encoded
  const decodedFileName = decodeURIComponent(encodedFileName);

  return decodedFileName;
};
