/**
 * Checks if a given node is a descendant of the specified root node within the DOM tree.
 *
 * @function
 * @param {Node | null | undefined} root - The root node to check if it contains the target node.
 * @param {Node} [n] - The node to check if it is a descendant of the root node.
 * @returns {boolean} Returns 'true' if the 'root' contains 'n' as a descendant; otherwise, returns 'false'.
 */

export function contains(root: Node | null | undefined, n?: Node) {
  if (!root || !n) {
    return false;
  }

  // Use native if support
  if (root.contains) {
    return root.contains(n);
  }

  // `document.contains` not support with IE11
  let node: Node | null = n;
  while (node) {
    if (node === root) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
}
