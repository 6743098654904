import React from "react";
import styled from "@emotion/styled";
import { getTextColor, getEllipsisWidth, getVariantStyles } from "./utils";
import type {
  TypographyVariants,
  TypographyHTMLTags,
  TypographyAlign,
  TypographyTransform
} from "./types";

export type TextProps = {
  variant?: TypographyVariants;
  color?: string;
  as?: TypographyHTMLTags;
  ellipsis?: number | string;
  lineClamp?: number;
  transform?: TypographyTransform;
  useMaxWidth?: boolean;
  align?: TypographyAlign;
  noWrap?: boolean;
  // TODO: Check if this creates any issues
  children?: React.ReactNode;
  htmlFor?: string;
} & React.HTMLAttributes<HTMLParagraphElement | HTMLHeadingElement>;

const CustomText = React.forwardRef<
  HTMLParagraphElement | HTMLHeadingElement,
  TextProps
>(({ useMaxWidth, ...rest }, ref) => {
  return React.createElement(rest.as || "span", { ...rest, ref });
});

/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Typography } from '@certa/catalyst';
 */
export const Text = styled(CustomText)`
  ${({ variant }) => getVariantStyles(variant || "p1-regular")}
  text-transform: ${({ transform }) => transform};
  color: ${({ color }) => color && getTextColor(color)};
  white-space: ${({ ellipsis }) => (ellipsis ? "nowrap" : "inherit")};
  overflow: ${({ ellipsis, lineClamp }) =>
    ellipsis || lineClamp ? "hidden" : "inherit"};
  text-overflow: ellipsis;
  width: ${({ ellipsis, useMaxWidth }) =>
    ellipsis
      ? `${useMaxWidth ? "inherit" : getEllipsisWidth(ellipsis)}`
      : "inherit"};
  max-width: ${({ ellipsis, useMaxWidth }) =>
    ellipsis && useMaxWidth ? getEllipsisWidth(ellipsis) : "inherit"};
  font-family: "Inter", "system-ui";
  white-space: ${props => props.noWrap && "nowrap"};
  text-align: ${({ align }) => align};
  ${props =>
    !props.lineClamp
      ? ""
      : `display: -webkit-box;
        -webkit-line-clamp: ${props.lineClamp};
        -webkit-box-orient: vertical;`};
  --label: CustomText;
`;
