// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Badge = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Badge"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_12336_11456)">
            <path
              d="M4-.001a2 2 0 00-2 2v8.667c0 .191.083.375.228.5l5.333 4.668c.252.22.627.22.878 0l5.334-4.667a.667.667 0 00.227-.501V1.999a2 2 0 00-2-2H4z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_12336_11456">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Badge;
