export type LCData = {
  label: string;
  // display_type: string; // make it specific "normal" | "alert" | "hidden"
  value?: string | number | boolean | null | string[];
  // hide_from_workflow?: boolean;
  format?: LCDataFormat | string;
  display_type?: string;
};

export enum LCDataFormat {
  DATE = "date",
  ICON = "icon",
  TAGS = "tags",
  RATING = "rating",
  DUNS = "duns",
  PID = "pid",
  DEFAULT = ""
}
