import { useState, useRef, useLayoutEffect } from "react";
import { convertValueToPercentage, getPositionOffset } from "../Slider.utils";

type NodePositionInRangeProps = {
  value?: number;
  min: number;
  max: number;
};

export const useNodePositionInRange = ({
  value,
  min,
  max
}: NodePositionInRangeProps) => {
  const [left, setLeft] = useState("0");
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const boundingRect = nodeRef.current?.getBoundingClientRect();
  const nodeWidth = boundingRect ? boundingRect.right - boundingRect.left : 0;

  useLayoutEffect(() => {
    const updateNodePosition = () => {
      const node = nodeRef.current;
      if (!node) return;

      const percent =
        value === undefined ? 0 : convertValueToPercentage(value, min, max);
      const positionOffset = nodeWidth
        ? getPositionOffset(nodeWidth, percent, 1)
        : 0;
      const leftValue = `calc(${percent}% + ${positionOffset}px)`;
      setLeft(leftValue);
    };

    updateNodePosition();
  }, [value, min, max, nodeWidth]);

  return { left, nodeRef };
};
