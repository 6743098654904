// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Download = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Download"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.444 6.648a1.133 1.133 0 011.606 0l1.803 1.809V1.139a1.137 1.137 0 112.272 0v7.338l1.824-1.83a1.133 1.133 0 011.607 0 1.142 1.142 0 010 1.612l-4.02 4.032a.757.757 0 01-1.073 0l-4.02-4.032a1.141 1.141 0 010-1.611zm11.49 7.206H1.066c-.59 0-1.067.48-1.067 1.073C0 15.407.477 16 1.067 16h13.866c.59 0 1.067-.48 1.067-1.073 0-.48-.477-1.073-1.067-1.073z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Download;
