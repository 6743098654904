import {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useRef
} from "react";
import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";
import { nanoid } from "nanoid";
import { Close } from "@certa/icons";

import type { ButtonProps } from "../Button";
import { Button, ButtonSizes, ButtonTypes, ButtonVariants } from "../Button";
import { Dimmer } from "../Dimmer";

import { CATALYST_Z_INDEXES } from "../../constants/styles";

import { classNames } from "../../utils";
import styles from "./Dialog.module.css";

type DialogCloseProps =
  | {
      preventCloseOnClickOutside?: boolean;
      onClose: () => void;
      showCloseIcon: boolean;
    }
  | {
      preventCloseOnClickOutside: boolean;
      onClose: () => void;
      showCloseIcon?: boolean;
    }
  | {
      preventCloseOnClickOutside: boolean;
      onClose: () => void;
      showCloseIcon: boolean;
    }
  | {
      preventCloseOnClickOutside?: undefined;
      onClose?: undefined;
      showCloseIcon?: false;
    };

type DialogProps = {
  role?: "dialog" | "alertdialog";
  show: boolean;
  title: string;
  description?: string;
  "aria-label"?: string;
  width?: string | number;
  height?: string | number;
  maxHeight?: string | number;
  children: React.ReactNode;
  primaryActionText?: string;
  secondaryActionText?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  primaryButtonProps?: Omit<ButtonProps, "onClick" | "children">;
  secondaryButtonProps?: Omit<ButtonProps, "onClick" | "children">;
  // TODO: Remove this prop once we have a better solution
  noContentStyling?: boolean;
  canWhiteList?: (element: HTMLElement) => boolean;
  minWidth?: string;
  maxWidth?: string;
} & DialogCloseProps;

export const Dialog = (props: DialogProps) => {
  const {
    role = "dialog",
    show: shouldShow = false,
    "aria-label": ariaLabel,
    title,
    description,
    children,
    width = "400px",
    minWidth,
    maxWidth,
    height = "fit-content",
    maxHeight,
    preventCloseOnClickOutside: shouldPreventCloseOnClickOutside = false,
    primaryActionText = "Ok",
    onPrimaryAction,
    primaryButtonProps = {
      loading: false,
      disabled: false
    },
    secondaryActionText = "Cancel",
    onSecondaryAction,
    secondaryButtonProps = {
      loading: false,
      disabled: false
    },
    onClose,
    showCloseIcon: shouldShowCloseIcon = true,
    noContentStyling: hasNoContentStyling = false,
    canWhiteList = () => true
  } = props;

  const [shouldShowDialog, setShouldShowDialog] = useState(false);

  const descriptionId = description
    ? "catalyst-dialog-description-" + nanoid(6)
    : undefined;

  const rootElement = document.getElementById("catalyst-dialogs-wrapper");
  const dialogTriggerElementRef = useRef<HTMLElement | null>();

  useLayoutEffect(() => {
    if (rootElement) {
      rootElement.setAttribute("role", "presentation");
      rootElement.className = styles.catalystDialogWrapper;
      rootElement.style.zIndex = CATALYST_Z_INDEXES.DIALOG;
    }
  }, [rootElement, shouldShow]);

  const returnFocusToTriggeredElement = useCallback(() => {
    requestAnimationFrame(() => {
      dialogTriggerElementRef.current?.focus();
      dialogTriggerElementRef.current = null;
    });
  }, []);

  const handleClose = useCallback(() => {
    if (!shouldPreventCloseOnClickOutside) {
      onClose?.();
      returnFocusToTriggeredElement();
    }
  }, [
    onClose,
    shouldPreventCloseOnClickOutside,
    returnFocusToTriggeredElement
  ]);

  // This is to focus the trigger element on drawer close.
  // Also to avoid losing the trigger element reference.
  useEffect(() => {
    if (shouldShow) {
      dialogTriggerElementRef.current = document.activeElement as HTMLElement;
    } else {
      returnFocusToTriggeredElement();
    }
    setShouldShowDialog(shouldShow);
  }, [returnFocusToTriggeredElement, shouldShow]);

  useEffect(() => {
    const onEscapeKeyHandler = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        handleClose();
      }
    };

    if (shouldShow) {
      window.addEventListener("keydown", onEscapeKeyHandler);
    } else {
      window.removeEventListener("keydown", onEscapeKeyHandler);
    }

    return () => {
      window.removeEventListener("keydown", onEscapeKeyHandler);
    };
  }, [handleClose, shouldShow]);

  const handleOnCloseIcon = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return rootElement && shouldShowDialog
    ? createPortal(
        <>
          <Dimmer onClick={handleClose} scrollLock />
          <FocusLock disabled={!shouldShow} whiteList={canWhiteList}>
            <div
              role={role}
              aria-modal={true}
              aria-label={ariaLabel || title}
              aria-describedby={descriptionId}
              className={styles.catalystDialog}
              data-testid="catalyst-dialog"
              style={{ height, width, maxHeight, minWidth, maxWidth }}
            >
              <div className={styles.catalystDialogHeader}>
                <div className={styles.catalystDialogTitle}>{title}</div>
                {description && (
                  <div
                    className={styles.catalystDialogDescription}
                    id={descriptionId}
                  >
                    {description}
                  </div>
                )}
              </div>
              <div
                className={classNames({
                  [styles.catalystDialogContent]: true,
                  [styles.catalystDialogContentOnly]: !(
                    onPrimaryAction || onSecondaryAction
                  ),
                  [styles.catalystDialogContentNoStyling]: hasNoContentStyling
                })}
              >
                {children}
              </div>
              {!!(onPrimaryAction || onSecondaryAction) && (
                <div className={styles.catalystDialogFooter}>
                  {onSecondaryAction && (
                    <Button
                      variant={ButtonVariants.TEXT}
                      {...secondaryButtonProps}
                      onClick={onSecondaryAction}
                    >
                      {secondaryActionText}
                    </Button>
                  )}
                  <Button
                    variant={ButtonVariants.FILLED}
                    {...primaryButtonProps}
                    onClick={onPrimaryAction}
                  >
                    {primaryActionText}
                  </Button>
                </div>
              )}
              {shouldShowCloseIcon && (
                <div className={styles.catalystDialogCloseIcon}>
                  <Button
                    leftIcon={<Close />}
                    type={ButtonTypes.ICON}
                    variant={ButtonVariants.TEXT}
                    size={ButtonSizes.SMALL}
                    onClick={handleOnCloseIcon}
                    aria-label="Close"
                  />
                </div>
              )}
            </div>
          </FocusLock>
        </>,
        rootElement
      )
    : null;
};
