import { useState, useEffect, useRef } from "react";
import { createPortal, render } from "react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Close } from "@certa/icons";

// Move these icons to @certa/icons once the new icons are finalised
import { Info } from "../../icons/Info";
import { Error } from "../../icons/Error";
import { Warning } from "../../icons/Warning";
import { Success } from "../../icons/Success";
import loadingIcon from "../../icons/loading.svg";

import styles from "./Toast.module.css";

import { classNames } from "../../utils/common";
import { CATALYST_Z_INDEXES } from "../../constants/styles";

export enum ToastTypes {
  LOADING = "Loading",
  SUCCESS = "Success",
  INFO = "Info",
  ERROR = "Error",
  WARNING = "Warning"
}

export enum ToastPlacements {
  BOTTOM_RIGHT = "BottomRight",
  BOTTOM_LEFT = "BottomLeft"
}

export type ToastProps = {
  show: boolean;
  placement?: ToastPlacements;
  type?: ToastTypes;
  duration?: number;
  title: string;
  description?: string;
  onClose?: () => void;
};

export function Toast(props: ToastProps) {
  const {
    show: shouldShow,
    placement = ToastPlacements.BOTTOM_RIGHT,
    type = ToastTypes.INFO,
    title,
    description,
    duration = 5000,
    onClose
  } = props;

  const rootElement = document.getElementById("catalyst-toasts-wrapper");

  const [shouldShowToast, setShouldShowToast] = useState<boolean>(false);

  useEffect(() => {
    if (rootElement) {
      rootElement.setAttribute("role", "status");
      rootElement.className = styles["catalystToast" + placement];
      rootElement.style.zIndex = CATALYST_Z_INDEXES.TOAST;
    }
    setShouldShowToast(shouldShow);
  }, [placement, rootElement, shouldShow]);

  const timerRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setShouldShowToast(false);
      onClose && onClose();
    }, duration);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [duration, onClose]);

  const getToastTypeIcon = () => {
    switch (true) {
      case type === ToastTypes.SUCCESS:
        return <Success />;
      case type === ToastTypes.WARNING:
        return <Warning />;
      case type === ToastTypes.ERROR:
        return <Error />;
      case type === ToastTypes.INFO:
        return <Info />;
    }
  };

  const handleClose = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setShouldShowToast(false);
    onClose && onClose();
  };

  return rootElement && shouldShowToast
    ? createPortal(
        <AnimatePresence>
          <motion.div
            className={classNames({
              [styles.catalystToast]: true,
              [styles["catalystToast" + type]]: true
            })}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
          >
            {type === ToastTypes.LOADING ? (
              <>
                <div className={styles.catalystToastLoadingIcon}>
                  <img src={loadingIcon} alt={`toast ${type} icon`} />
                </div>
                <div className={styles.catalystToastTitle}>{title}</div>
              </>
            ) : (
              <>
                <div className={styles.catalystToastTypeIcon}>
                  {getToastTypeIcon()}
                </div>
                <div className={styles.catalystToastMessage}>
                  <div className={styles.catalystToastTitle}>{title}</div>
                  {description && (
                    <div className={styles.catalystToastDescription}>
                      {description}
                    </div>
                  )}
                </div>
                <div
                  className={styles.catalystToastClose}
                  onClick={handleClose}
                >
                  <Close size={12} />
                </div>
              </>
            )}
          </motion.div>
        </AnimatePresence>,
        rootElement
      )
    : null;
}

export function showToast(params: Omit<ToastProps, "show">): void {
  const {
    type = ToastTypes.INFO,
    title,
    description,
    placement = ToastPlacements.BOTTOM_RIGHT,
    onClose
  } = params;

  const toastContainer = document.createDocumentFragment();

  render(
    <Toast
      show={true}
      type={type}
      title={title}
      description={description}
      placement={placement}
      onClose={onClose}
    ></Toast>,
    toastContainer
  );
}
