// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Tag = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Tag"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.594 8.18L7.819 1.405C7.593 1.135 7.232 1 6.871 1H2.355A1.33 1.33 0 001 2.354V6.87c0 .361.135.722.406.948l6.775 6.774c.27.271.632.407.948.407.316 0 .678-.136.949-.407l4.516-4.516a1.31 1.31 0 000-1.896zM4.16 5.063a.906.906 0 01-.903-.903c0-.497.406-.904.903-.904s.904.407.904.904a.906.906 0 01-.904.903z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Tag;
