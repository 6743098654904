import * as React from "react";
import type { IconProps } from "../types/icon";
import { getColor } from "@certa/blocks/src/utils";
import { css, cx } from "emotion";

type IconWrapperProps = {
  componentName: string;
  icon: React.ReactNode;
};

export const IconWrapper: React.VFC<IconWrapperProps & IconProps> = ({
  componentName,
  icon,
  color: colorProp,
  size: sizeProp,
  autoSize,
  className,
  ...restProps
}) => {
  const color = colorProp ? getColor(colorProp) : "currentColor";
  const size = sizeProp ? `${sizeProp}px` : autoSize ? "1em" : "16px";
  return (
    <span
      role="img"
      aria-hidden="true"
      aria-label={componentName}
      className={cx(
        css`
          color: ${color};
          width: ${size};
          height: ${size};
          display: inline-flex;
          font-size: inherit;
        `,
        className
      )}
      {...restProps}
    >
      {icon}
    </span>
  );
};
