import { useState, forwardRef, useEffect } from "react";
import styles from "./Slider.module.css";
import { classNames } from "../../utils";
import * as RadixSlider from "@radix-ui/react-slider";
import { SliderColorVariants, type SliderProps } from "./Slider.types";
import {
  clamp,
  generateGreenToRedGradient,
  generateRedToGreenGradient,
  getMarkLabelAndStyle
} from "./Slider.utils";
import { SliderInput } from "./components/SliderInput/SliderInput";
import { CatalystColors } from "../../constants";
import { SliderMark } from "./components/SliderMark/SliderMark";
import { omit } from "../../utils/object";
import { AriaLive } from "../AriaLive";

export const Slider = forwardRef<HTMLInputElement, SliderProps>(
  (props, ref) => {
    const {
      min,
      max,
      step = 1,
      value,
      onChange,
      "aria-label": ariaLabel,
      disabled: isDisabled,
      width = "100%",
      status,
      color = "default",
      trackBackgroundGradient = false,
      marks = {}
    } = props;

    const [sliderValue, setSliderValue] = useState<number>(value ?? min);
    const [inputValue, setInputValue] = useState<number | undefined>(
      value ?? min
    );
    const [isAutoCorrected, setIsAutoCorrected] = useState<boolean>(false);

    useEffect(() => {
      setSliderValue(value ?? min);
      setInputValue(value ?? min);
    }, [value, min]);

    const handleChange = (val: number) => {
      setSliderValue(val);
      if (val !== value) {
        onChange?.(val);
      }
    };

    const handleSliderChange = (val: number) => {
      setInputValue(val);
      handleChange(val);
      setIsAutoCorrected(false);
    };

    const handleNumberInputValueChange = (value?: number) => {
      setInputValue(value);
    };

    const handleNumberInputBlur = (value?: number) => {
      const convertedValue = clamp(value ?? min, [min, max]);
      const isAutoCorrectionNeeded = convertedValue !== value;
      setIsAutoCorrected(isAutoCorrectionNeeded);
      setInputValue(convertedValue);
      handleChange(convertedValue);
    };

    const gradientGenerator =
      trackBackgroundGradient === "greenToRed"
        ? generateGreenToRedGradient
        : generateRedToGreenGradient;
    const sliderBackgroundDefault = isDisabled
      ? CatalystColors.NEUTRAL_600
      : CatalystColors.BRAND_400;
    const sliderBackground = trackBackgroundGradient
      ? gradientGenerator(sliderValue, max)
      : sliderBackgroundDefault;
    const trackOpacity = isDisabled && trackBackgroundGradient ? 0.8 : 1;

    // Note: normally it should not be undefined
    // But currenlty studio preview has a bug that causes it to be undefined
    // To avoid showing undefined to user, added following check
    // Other way could have been to make prop optional, but to make slider type safe, didn't do that
    const stringifiedMin = min === undefined ? "" : String(min);
    const stringifiedMax = max === undefined ? "" : String(max);
    const minMark = marks[min];
    const { label: minLabel, style: minLabelStyle } =
      getMarkLabelAndStyle(minMark);
    const maxMark = marks[max];
    const { label: maxLabel, style: maxLabelStyle } =
      getMarkLabelAndStyle(maxMark);
    const marksExcludingMinAndMax = omit(marks, [
      stringifiedMin,
      stringifiedMax
    ]);

    return (
      <div className={styles.catalystSliderBox}>
        <div className={styles.catalystSliderOuterWrapper} style={{ width }}>
          <div className={styles.catalystSliderContainer}>
            <div
              className={styles.catalystSliderLabelMin}
              style={minLabelStyle}
            >
              {minLabel || stringifiedMin}
            </div>
            <div className={styles.catalystSliderInnerWrap}>
              <RadixSlider.Root
                className={classNames({
                  [styles.catalystSlider]: true,
                  [styles.catalystSliderDisabled]: isDisabled
                })}
                defaultValue={[50]}
                max={max}
                step={step}
                value={[sliderValue]}
                onValueChange={val => handleSliderChange(val[0])}
                min={min}
                disabled={isDisabled}
              >
                <RadixSlider.Track
                  className={styles.catalystSliderTrack}
                  style={{ opacity: trackOpacity }}
                >
                  <div>
                    {Object.keys(marksExcludingMinAndMax).length > 0
                      ? Object.keys(marksExcludingMinAndMax).map(key => (
                          <SliderMark
                            key={key}
                            value={Number(key)}
                            min={min}
                            max={max}
                            mark={marksExcludingMinAndMax[key]}
                          />
                        ))
                      : null}
                    <RadixSlider.Range
                      style={{
                        background: sliderBackground,
                        opacity: trackOpacity
                      }}
                      className={classNames({
                        [styles.catalystSliderFilledTrack]: true
                      })}
                      data-testid="catalyst-slider-range"
                    />
                  </div>
                </RadixSlider.Track>

                {!isDisabled && (
                  <RadixSlider.Thumb
                    className={classNames({
                      [styles.catalystSliderThumb]: true,
                      [styles[
                        "catalystSliderThumb" + SliderColorVariants[color]
                      ]]: true
                    })}
                    aria-label={ariaLabel}
                  />
                )}
              </RadixSlider.Root>
            </div>
            <div
              className={styles.catalystSliderLabelMax}
              style={maxLabelStyle}
            >
              {maxLabel || stringifiedMax}
            </div>
          </div>
          <div className={styles.catalystSliderInputWrapper}>
            <SliderInput
              label={ariaLabel}
              value={inputValue}
              onChange={handleNumberInputValueChange}
              onBlur={handleNumberInputBlur}
              min={min}
              max={max}
              step={step}
              disabled={isDisabled}
              status={status}
              ref={ref}
            />
          </div>
        </div>

        {isAutoCorrected && (
          <div className={styles.catalystSliderAutoCorrected}>
            The value was corrected to the nearest allowed digit.
            <AriaLive message="The value was corrected to the nearest allowed digit." />
          </div>
        )}
      </div>
    );
  }
);
