import React, { forwardRef } from "react";
import { ClassNames } from "@emotion/core";
import { getCSSVarToken } from "../utils";

type direction = "vertical" | "horizontal";
type gap = string;

export type StackProps = React.HTMLAttributes<HTMLDivElement> & {
  inline?: boolean;
  reverse?: boolean;
  justify?: React.CSSProperties["justifyContent"];
  align?: React.CSSProperties["alignItems"];
  direction?: direction;
  gap?: gap;
  gutter?: string;
  itemWidth?: string;
  wrap?: React.CSSProperties["flexWrap"];
} & React.RefAttributes<HTMLDivElement>;

const createDirection = (direction: any, reverse: boolean) => {
  const prefix = direction === "vertical" ? "column" : "row";
  const suffix = reverse ? "-reverse" : "";
  return `${prefix}${suffix}`;
};

const Stack = forwardRef<HTMLDivElement, StackProps>(function Stack(
  {
    inline = false,
    reverse = false,
    justify,
    align,
    direction = "horizontal",
    gap = "s00",
    className,
    gutter,
    itemWidth,
    wrap,
    ...props
  },
  ref
) {
  return (
    <ClassNames>
      {({ css, cx }) => (
        <div
          ref={ref}
          // TODO: Check what if I just use string concatenation?
          className={cx(
            css`
              display: ${inline ? "inline-flex" : "flex"};
              flex-direction: ${createDirection(direction, reverse)};
              justify-content: ${justify};
              align-items: ${align};
              flex-wrap: ${wrap};
              width: ${itemWidth};
              > * + * {
                margin-top: ${direction === "vertical"
                  ? `var(--${gap})`
                  : "0px"};
                margin-inline-start: ${direction === "vertical"
                  ? 0
                  : reverse
                    ? 0
                    : `var(--${gap})`};
                margin-inline-end: ${reverse ? `var(--${gap})` : 0};
              }
              padding: ${gutter && getCSSVarToken(gutter)};
              --label: Stack;
            `,
            className
          )}
          {...props}
        />
      )}
    </ClassNames>
  );
});

export { Stack };
