// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const ChevronDown = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronDown"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6.533 11.416L2.387 7.27a1.333 1.333 0 01.153-2.012 1.365 1.365 0 011.764.167L8 9.122l3.734-3.734a1.332 1.332 0 012.014.155 1.364 1.364 0 01-.167 1.763l-4.114 4.11a2.079 2.079 0 01-2.935 0z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default ChevronDown;
