import React, { useRef, useMemo, useState, useEffect, forwardRef } from "react";
import type { NumberFormatValues, SourceInfo } from "react-number-format";
import { countries, type CountryItem } from "./countries";
import {
  formatNumber,
  guessCountry,
  phoneNumberDefaultFormat
} from "./PhoneNumber.utils";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  TypographyVariants,
  Typography
} from "..";
import { PhoneNumberTextInput } from "./components/PhoneNumberTextInput/PhoneNumberTextInput";
import { TriggerButton } from "./components/TriggerButton/TriggerButton";
import { Flag } from "./components/Flag/Flag";
import { Stack } from "../../layouts";
import styles from "./PhoneNumber.module.css";
import type { FieldStatus } from "../types";
import NumberFormat from "react-number-format";

type PhoneNumberProps = {
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  status?: FieldStatus;
  id?: string;
  "aria-label": string;
};

type Option = {
  label: React.ReactNode;
  value: string;
};

const countrySelectOptions: Option[] = Object.values(countries).map(
  country => ({
    label: (
      <Stack direction="horizontal" align="center" gap="8">
        <Flag iso2={country.iso2} />
        <Typography variant={TypographyVariants.BODY}>
          {country.name} {formatNumber(country.dialCode, country.format)}
        </Typography>
      </Stack>
    ),
    value: country.id
  })
);

export const PhoneNumber = forwardRef<HTMLInputElement, PhoneNumberProps>(
  (props, ref) => {
    const {
      value,
      onChange,
      disabled: isDisabled,
      status,
      onBlur,
      id,
      "aria-label": ariaLabel
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const selectedOptionRef = useRef<HTMLDivElement | null>(null);
    const phoneNumberContainerRef = useRef<HTMLDivElement | null>(null);
    const timeOutRef = useRef<NodeJS.Timeout | null>(null);

    const countryDetails: null | CountryItem = useMemo(() => {
      return guessCountry(value);
    }, [value]);

    const handleValueChange = (
      { formattedValue }: NumberFormatValues,
      sourceInfo: SourceInfo
    ) => {
      // This is make sure that onChange is called only when the value
      // is changed by the user
      if (!isDisabled && sourceInfo.source === "event") {
        onChange(formattedValue);
      }
    };

    const onDropdownChange = (countryId: string) => {
      if (!countryId || !countries[countryId]) onChange("");
      const country = countries[countryId];
      const formattedDialCode = formatNumber(country.dialCode, country.format);
      onChange(formattedDialCode);
    };

    useEffect(() => {
      if (selectedOptionRef.current && isOpen) {
        selectedOptionRef.current.focus();
      }
    }, [isOpen]);

    const customInputProps = {
      disabled: isDisabled,
      status,
      id
    };

    const onContainerBlur = () => {
      if (isOpen) return;
      timeOutRef.current = setTimeout(() => onBlur?.(), 100);
    };

    const onContainerFocus = () => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
        timeOutRef.current = null;
      }
    };

    const handlePhoneNumberFormat = (val: string) => {
      return formatNumber(
        val,
        countryDetails?.format || phoneNumberDefaultFormat
      );
    };

    return (
      <div
        className={styles.catlaystPhoneNumberWrapper}
        ref={phoneNumberContainerRef}
        onBlur={onContainerBlur}
        onFocus={onContainerFocus}
      >
        <DropdownMenu onOpenChange={setIsOpen}>
          <TriggerButton
            flagIso2={countryDetails?.iso2}
            disabled={isDisabled}
            aria-label={ariaLabel}
          />

          <DropdownMenuContent width="202px" height="308px">
            <>
              {countrySelectOptions.map(option => (
                <DropdownMenuCheckboxItem
                  value={option.value}
                  onChange={() => onDropdownChange(option.value)}
                  checked={countryDetails?.id === option.value}
                  key={option.value}
                  // Todo: check if focusing the selected option can be done in dropdown componenet
                  // since its using useEffect, it might cause some edge cases
                  checkboxRef={el => {
                    if (countryDetails?.id === option.value) {
                      selectedOptionRef.current = el;
                    }
                  }}
                >
                  {option.label}
                </DropdownMenuCheckboxItem>
              ))}
            </>
          </DropdownMenuContent>
        </DropdownMenu>

        <NumberFormat
          format={handlePhoneNumberFormat}
          customInput={PhoneNumberTextInput}
          value={value}
          getInputRef={ref}
          onValueChange={handleValueChange}
          {...customInputProps}
        />
      </div>
    );
  }
);
