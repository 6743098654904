// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Wrench = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Wrench"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_4623_4297)">
            <path
              d="M15.713 3.758l-2.074 1.197-1.638-.956-.009-1.897L14.067.905a.19.19 0 00.002-.326A3.995 3.995 0 009.971.553c-1.516.892-2.237 2.686-1.862 4.344L.62 12.385a2.117 2.117 0 102.995 2.995L11.1 7.895a3.994 3.994 0 004.898-3.977.19.19 0 00-.285-.16z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_4623_4297">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Wrench;
