export type CountryItem = {
  name: string;
  regions: string[];
  iso2: string;
  countryCode: string;
  dialCode: string;
  format: string;
  priority: number;
  mainCode?: boolean;
  hasAreaCodes?: boolean;
  isAreaCode?: boolean;
  areaCodeLength?: number;
  id: string;
};

/**
 * List of countries used for the phone number component.
 *
 * This record contains detailed information about each country, including:
 * - `name`: The name of the country.
 * - `regions`: The regions the country belongs to.
 * - `iso2`: The ISO 3166-1 alpha-2 code of the country.
 * - `countryCode`: The country calling code.
 * - `dialCode`: The dialing code used for phone numbers in the country.
 * - `format`: The format used for displaying phone numbers in the country.
 * - `priority`: The priority for selecting the country when multiple countries have the same dial code.
 * - `id`: A unique identifier for the country.
 *
 * This data is essential for the phone number component to:
 * - Guess the country based on the input phone number.
 * - Format the phone number according to the country's specific format.
 */

export const countries: Record<string, CountryItem> = {
  "1": {
    name: "Afghanistan",
    regions: ["asia"],
    iso2: "af",
    countryCode: "93",
    dialCode: "93",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "1"
  },
  "2": {
    name: "Albania",
    regions: ["europe"],
    iso2: "al",
    countryCode: "355",
    dialCode: "355",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "2"
  },
  "3": {
    name: "Algeria",
    regions: ["africa", "north-africa"],
    iso2: "dz",
    countryCode: "213",
    dialCode: "213",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "3"
  },
  "4": {
    name: "Andorra",
    regions: ["europe"],
    iso2: "ad",
    countryCode: "376",
    dialCode: "376",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "4"
  },
  "5": {
    name: "Angola",
    regions: ["africa"],
    iso2: "ao",
    countryCode: "244",
    dialCode: "244",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "5"
  },
  "6": {
    name: "Antigua and Barbuda",
    regions: ["america", "carribean"],
    iso2: "ag",
    countryCode: "1268",
    dialCode: "1268",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "6"
  },
  "7": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54",
    format: "+## (##) ########",
    priority: 0,
    mainCode: true,
    hasAreaCodes: true,
    id: "7"
  },
  "8": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "5411",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 2,
    id: "8"
  },
  "9": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54221",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "9"
  },
  "10": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54223",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "10"
  },
  "11": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54261",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "11"
  },
  "12": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54264",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "12"
  },
  "13": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "542652",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 4,
    id: "13"
  },
  "14": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54280",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "14"
  },
  "15": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "542905",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 4,
    id: "15"
  },
  "16": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54291",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "16"
  },
  "17": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "542920",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 4,
    id: "17"
  },
  "18": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "542966",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 4,
    id: "18"
  },
  "19": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54299",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "19"
  },
  "20": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54341",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "20"
  },
  "21": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54342",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "21"
  },
  "22": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54343",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "22"
  },
  "23": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54351",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "23"
  },
  "24": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54376",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "24"
  },
  "25": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54379",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "25"
  },
  "26": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54381",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "26"
  },
  "27": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "543833",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 4,
    id: "27"
  },
  "28": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54385",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "28"
  },
  "29": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54387",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "29"
  },
  "30": {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    countryCode: "54",
    dialCode: "54388",
    format: "+## (##) ########",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "30"
  },
  "31": {
    name: "Armenia",
    regions: ["asia", "ex-ussr"],
    iso2: "am",
    countryCode: "374",
    dialCode: "374",
    format: "+### ## ######",
    priority: 0,
    id: "31"
  },
  "32": {
    name: "Aruba",
    regions: ["america", "carribean"],
    iso2: "aw",
    countryCode: "297",
    dialCode: "297",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "32"
  },
  "33": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "61",
    format: "+## (##) #### ####",
    priority: 0,
    mainCode: true,
    hasAreaCodes: true,
    id: "33"
  },
  "34": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "612",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 1,
    id: "34"
  },
  "35": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "613",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 1,
    id: "35"
  },
  "36": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "614",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 1,
    id: "36"
  },
  "37": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "617",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 1,
    id: "37"
  },
  "38": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "618",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 1,
    id: "38"
  },
  "39": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "6102",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 2,
    id: "39"
  },
  "40": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "6103",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 2,
    id: "40"
  },
  "41": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "6104",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 2,
    id: "41"
  },
  "42": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "6107",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 2,
    id: "42"
  },
  "43": {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    countryCode: "61",
    dialCode: "6108",
    format: "+## (##) #### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 2,
    id: "43"
  },
  "44": {
    name: "Austria",
    regions: ["europe", "eu-union"],
    iso2: "at",
    countryCode: "43",
    dialCode: "43",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "44"
  },
  "45": {
    name: "Azerbaijan",
    regions: ["asia", "ex-ussr"],
    iso2: "az",
    countryCode: "994",
    dialCode: "994",
    format: "+### (##) ### ## ##",
    priority: 0,
    id: "45"
  },
  "46": {
    name: "Bahamas",
    regions: ["america", "carribean"],
    iso2: "bs",
    countryCode: "1242",
    dialCode: "1242",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "46"
  },
  "47": {
    name: "Bahrain",
    regions: ["middle-east"],
    iso2: "bh",
    countryCode: "973",
    dialCode: "973",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "47"
  },
  "48": {
    name: "Bangladesh",
    regions: ["asia"],
    iso2: "bd",
    countryCode: "880",
    dialCode: "880",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "48"
  },
  "49": {
    name: "Barbados",
    regions: ["america", "carribean"],
    iso2: "bb",
    countryCode: "1246",
    dialCode: "1246",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "49"
  },
  "50": {
    name: "Belarus",
    regions: ["europe", "ex-ussr"],
    iso2: "by",
    countryCode: "375",
    dialCode: "375",
    format: "+### (##) ### ## ##",
    priority: 0,
    id: "50"
  },
  "51": {
    name: "Belgium",
    regions: ["europe", "eu-union"],
    iso2: "be",
    countryCode: "32",
    dialCode: "32",
    format: "+## ### ## ## ##",
    priority: 0,
    id: "51"
  },
  "52": {
    name: "Belize",
    regions: ["america", "central-america"],
    iso2: "bz",
    countryCode: "501",
    dialCode: "501",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "52"
  },
  "53": {
    name: "Benin",
    regions: ["africa"],
    iso2: "bj",
    countryCode: "229",
    dialCode: "229",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "53"
  },
  "54": {
    name: "Bhutan",
    regions: ["asia"],
    iso2: "bt",
    countryCode: "975",
    dialCode: "975",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "54"
  },
  "55": {
    name: "Bolivia",
    regions: ["america", "south-america"],
    iso2: "bo",
    countryCode: "591",
    dialCode: "591",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "55"
  },
  "56": {
    name: "Bosnia and Herzegovina",
    regions: ["europe", "ex-yugos"],
    iso2: "ba",
    countryCode: "387",
    dialCode: "387",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "56"
  },
  "57": {
    name: "Botswana",
    regions: ["africa"],
    iso2: "bw",
    countryCode: "267",
    dialCode: "267",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "57"
  },
  "58": {
    name: "Brazil",
    regions: ["america", "south-america"],
    iso2: "br",
    countryCode: "55",
    dialCode: "55",
    format: "+## (##) #########",
    priority: 0,
    id: "58"
  },
  "59": {
    name: "British Indian Ocean Territory",
    regions: ["asia"],
    iso2: "io",
    countryCode: "246",
    dialCode: "246",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "59"
  },
  "60": {
    name: "Brunei",
    regions: ["asia"],
    iso2: "bn",
    countryCode: "673",
    dialCode: "673",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "60"
  },
  "61": {
    name: "Bulgaria",
    regions: ["europe", "eu-union"],
    iso2: "bg",
    countryCode: "359",
    dialCode: "359",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "61"
  },
  "62": {
    name: "Burkina Faso",
    regions: ["africa"],
    iso2: "bf",
    countryCode: "226",
    dialCode: "226",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "62"
  },
  "63": {
    name: "Burundi",
    regions: ["africa"],
    iso2: "bi",
    countryCode: "257",
    dialCode: "257",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "63"
  },
  "64": {
    name: "Cambodia",
    regions: ["asia"],
    iso2: "kh",
    countryCode: "855",
    dialCode: "855",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "64"
  },
  "65": {
    name: "Cameroon",
    regions: ["africa"],
    iso2: "cm",
    countryCode: "237",
    dialCode: "237",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "65"
  },
  "66": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1",
    format: "+# (###) ###-####",
    priority: 1,
    mainCode: true,
    hasAreaCodes: true,
    id: "66"
  },
  "67": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1204",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "67"
  },
  "68": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1226",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "68"
  },
  "69": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1236",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "69"
  },
  "70": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1249",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "70"
  },
  "71": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1250",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "71"
  },
  "72": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1289",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "72"
  },
  "73": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1306",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "73"
  },
  "74": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1343",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "74"
  },
  "75": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1365",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "75"
  },
  "76": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1387",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "76"
  },
  "77": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1403",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "77"
  },
  "78": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1416",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "78"
  },
  "79": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1418",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "79"
  },
  "80": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1431",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "80"
  },
  "81": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1437",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "81"
  },
  "82": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1438",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "82"
  },
  "83": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1450",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "83"
  },
  "84": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1506",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "84"
  },
  "85": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1514",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "85"
  },
  "86": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1519",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "86"
  },
  "87": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1548",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "87"
  },
  "88": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1579",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "88"
  },
  "89": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1581",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "89"
  },
  "90": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1587",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "90"
  },
  "91": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1604",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "91"
  },
  "92": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1613",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "92"
  },
  "93": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1639",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "93"
  },
  "94": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1647",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "94"
  },
  "95": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1672",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "95"
  },
  "96": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1705",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "96"
  },
  "97": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1709",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "97"
  },
  "98": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1742",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "98"
  },
  "99": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1778",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "99"
  },
  "100": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1780",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "100"
  },
  "101": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1782",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "101"
  },
  "102": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1807",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "102"
  },
  "103": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1819",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "103"
  },
  "104": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1825",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "104"
  },
  "105": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1867",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "105"
  },
  "106": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1873",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "106"
  },
  "107": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1902",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "107"
  },
  "108": {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    countryCode: "1",
    dialCode: "1905",
    format: "+# (###) ###-####",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "108"
  },
  "109": {
    name: "Cape Verde",
    regions: ["africa"],
    iso2: "cv",
    countryCode: "238",
    dialCode: "238",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "109"
  },
  "110": {
    name: "Caribbean Netherlands",
    regions: ["america", "carribean"],
    iso2: "bq",
    countryCode: "599",
    dialCode: "599",
    format: "+### ### ### ### ### ##",
    priority: 1,
    id: "110"
  },
  "111": {
    name: "Central African Republic",
    regions: ["africa"],
    iso2: "cf",
    countryCode: "236",
    dialCode: "236",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "111"
  },
  "112": {
    name: "Chad",
    regions: ["africa"],
    iso2: "td",
    countryCode: "235",
    dialCode: "235",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "112"
  },
  "113": {
    name: "Chile",
    regions: ["america", "south-america"],
    iso2: "cl",
    countryCode: "56",
    dialCode: "56",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "113"
  },
  "114": {
    name: "China",
    regions: ["asia"],
    iso2: "cn",
    countryCode: "86",
    dialCode: "86",
    format: "+## ##-#########",
    priority: 0,
    id: "114"
  },
  "115": {
    name: "Colombia",
    regions: ["america", "south-america"],
    iso2: "co",
    countryCode: "57",
    dialCode: "57",
    format: "+## ### ### ####",
    priority: 0,
    id: "115"
  },
  "116": {
    name: "Comoros",
    regions: ["africa"],
    iso2: "km",
    countryCode: "269",
    dialCode: "269",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "116"
  },
  "117": {
    name: "Congo",
    regions: ["africa"],
    iso2: "cd",
    countryCode: "243",
    dialCode: "243",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "117"
  },
  "118": {
    name: "Congo",
    regions: ["africa"],
    iso2: "cg",
    countryCode: "242",
    dialCode: "242",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "118"
  },
  "119": {
    name: "Costa Rica",
    regions: ["america", "central-america"],
    iso2: "cr",
    countryCode: "506",
    dialCode: "506",
    format: "+### ####-####",
    priority: 0,
    id: "119"
  },
  "120": {
    name: "Côte d’Ivoire",
    regions: ["africa"],
    iso2: "ci",
    countryCode: "225",
    dialCode: "225",
    format: "+### ## ## ## ##",
    priority: 0,
    id: "120"
  },
  "121": {
    name: "Croatia",
    regions: ["europe", "eu-union", "ex-yugos"],
    iso2: "hr",
    countryCode: "385",
    dialCode: "385",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "121"
  },
  "122": {
    name: "Cuba",
    regions: ["america", "carribean"],
    iso2: "cu",
    countryCode: "53",
    dialCode: "53",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "122"
  },
  "123": {
    name: "Curaçao",
    regions: ["america", "carribean"],
    iso2: "cw",
    countryCode: "599",
    dialCode: "599",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "123"
  },
  "124": {
    name: "Cyprus",
    regions: ["europe", "eu-union"],
    iso2: "cy",
    countryCode: "357",
    dialCode: "357",
    format: "+### ## ######",
    priority: 0,
    id: "124"
  },
  "125": {
    name: "Czech Republic",
    regions: ["europe", "eu-union"],
    iso2: "cz",
    countryCode: "420",
    dialCode: "420",
    format: "+### ### ### ###",
    priority: 0,
    id: "125"
  },
  "126": {
    name: "Denmark",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "dk",
    countryCode: "45",
    dialCode: "45",
    format: "+## ## ## ## ##",
    priority: 0,
    id: "126"
  },
  "127": {
    name: "Djibouti",
    regions: ["africa"],
    iso2: "dj",
    countryCode: "253",
    dialCode: "253",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "127"
  },
  "128": {
    name: "Dominica",
    regions: ["america", "carribean"],
    iso2: "dm",
    countryCode: "1767",
    dialCode: "1767",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "128"
  },
  "129": {
    name: "Dominican Republic",
    regions: ["america", "carribean"],
    iso2: "do",
    countryCode: "1",
    dialCode: "1",
    format: "+# ### ### ### ### ##",
    priority: 2,
    mainCode: true,
    hasAreaCodes: true,
    id: "129"
  },
  "130": {
    name: "Dominican Republic",
    regions: ["america", "carribean"],
    iso2: "do",
    countryCode: "1",
    dialCode: "1809",
    format: "+# ### ### ### ### ##",
    priority: 2,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "130"
  },
  "131": {
    name: "Dominican Republic",
    regions: ["america", "carribean"],
    iso2: "do",
    countryCode: "1",
    dialCode: "1829",
    format: "+# ### ### ### ### ##",
    priority: 2,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "131"
  },
  "132": {
    name: "Dominican Republic",
    regions: ["america", "carribean"],
    iso2: "do",
    countryCode: "1",
    dialCode: "1849",
    format: "+# ### ### ### ### ##",
    priority: 2,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "132"
  },
  "133": {
    name: "Ecuador",
    regions: ["america", "south-america"],
    iso2: "ec",
    countryCode: "593",
    dialCode: "593",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "133"
  },
  "134": {
    name: "Egypt",
    regions: ["africa", "north-africa"],
    iso2: "eg",
    countryCode: "20",
    dialCode: "20",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "134"
  },
  "135": {
    name: "El Salvador",
    regions: ["america", "central-america"],
    iso2: "sv",
    countryCode: "503",
    dialCode: "503",
    format: "+### ####-####",
    priority: 0,
    id: "135"
  },
  "136": {
    name: "Equatorial Guinea",
    regions: ["africa"],
    iso2: "gq",
    countryCode: "240",
    dialCode: "240",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "136"
  },
  "137": {
    name: "Eritrea",
    regions: ["africa"],
    iso2: "er",
    countryCode: "291",
    dialCode: "291",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "137"
  },
  "138": {
    name: "Estonia",
    regions: ["europe", "eu-union", "ex-ussr", "baltic"],
    iso2: "ee",
    countryCode: "372",
    dialCode: "372",
    format: "+### #### ######",
    priority: 0,
    id: "138"
  },
  "139": {
    name: "Ethiopia",
    regions: ["africa"],
    iso2: "et",
    countryCode: "251",
    dialCode: "251",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "139"
  },
  "140": {
    name: "Fiji",
    regions: ["oceania"],
    iso2: "fj",
    countryCode: "679",
    dialCode: "679",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "140"
  },
  "141": {
    name: "Finland",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "fi",
    countryCode: "358",
    dialCode: "358",
    format: "+### ## ### ## ##",
    priority: 0,
    id: "141"
  },
  "142": {
    name: "France",
    regions: ["europe", "eu-union"],
    iso2: "fr",
    countryCode: "33",
    dialCode: "33",
    format: "+## # ## ## ## ##",
    priority: 0,
    id: "142"
  },
  "143": {
    name: "French Guiana",
    regions: ["america", "south-america"],
    iso2: "gf",
    countryCode: "594",
    dialCode: "594",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "143"
  },
  "144": {
    name: "French Polynesia",
    regions: ["oceania"],
    iso2: "pf",
    countryCode: "689",
    dialCode: "689",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "144"
  },
  "145": {
    name: "Gabon",
    regions: ["africa"],
    iso2: "ga",
    countryCode: "241",
    dialCode: "241",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "145"
  },
  "146": {
    name: "Gambia",
    regions: ["africa"],
    iso2: "gm",
    countryCode: "220",
    dialCode: "220",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "146"
  },
  "147": {
    name: "Georgia",
    regions: ["asia", "ex-ussr"],
    iso2: "ge",
    countryCode: "995",
    dialCode: "995",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "147"
  },
  "148": {
    name: "Germany",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "de",
    countryCode: "49",
    dialCode: "49",
    format: "+## #### ########",
    priority: 0,
    id: "148"
  },
  "149": {
    name: "Ghana",
    regions: ["africa"],
    iso2: "gh",
    countryCode: "233",
    dialCode: "233",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "149"
  },
  "150": {
    name: "Greece",
    regions: ["europe", "eu-union"],
    iso2: "gr",
    countryCode: "30",
    dialCode: "30",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "150"
  },
  "151": {
    name: "Grenada",
    regions: ["america", "carribean"],
    iso2: "gd",
    countryCode: "1473",
    dialCode: "1473",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "151"
  },
  "152": {
    name: "Guadeloupe",
    regions: ["america", "carribean"],
    iso2: "gp",
    countryCode: "590",
    dialCode: "590",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "152"
  },
  "153": {
    name: "Guam",
    regions: ["oceania"],
    iso2: "gu",
    countryCode: "1671",
    dialCode: "1671",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "153"
  },
  "154": {
    name: "Guatemala",
    regions: ["america", "central-america"],
    iso2: "gt",
    countryCode: "502",
    dialCode: "502",
    format: "+### ####-####",
    priority: 0,
    id: "154"
  },
  "155": {
    name: "Guinea",
    regions: ["africa"],
    iso2: "gn",
    countryCode: "224",
    dialCode: "224",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "155"
  },
  "156": {
    name: "Guinea-Bissau",
    regions: ["africa"],
    iso2: "gw",
    countryCode: "245",
    dialCode: "245",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "156"
  },
  "157": {
    name: "Guyana",
    regions: ["america", "south-america"],
    iso2: "gy",
    countryCode: "592",
    dialCode: "592",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "157"
  },
  "158": {
    name: "Haiti",
    regions: ["america", "carribean"],
    iso2: "ht",
    countryCode: "509",
    dialCode: "509",
    format: "+### ####-####",
    priority: 0,
    id: "158"
  },
  "159": {
    name: "Honduras",
    regions: ["america", "central-america"],
    iso2: "hn",
    countryCode: "504",
    dialCode: "504",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "159"
  },
  "160": {
    name: "Hong Kong",
    regions: ["asia"],
    iso2: "hk",
    countryCode: "852",
    dialCode: "852",
    format: "+### #### ####",
    priority: 0,
    id: "160"
  },
  "161": {
    name: "Hungary",
    regions: ["europe", "eu-union"],
    iso2: "hu",
    countryCode: "36",
    dialCode: "36",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "161"
  },
  "162": {
    name: "Iceland",
    regions: ["europe"],
    iso2: "is",
    countryCode: "354",
    dialCode: "354",
    format: "+### ### ####",
    priority: 0,
    id: "162"
  },
  "163": {
    name: "India",
    regions: ["asia"],
    iso2: "in",
    countryCode: "91",
    dialCode: "91",
    format: "+## #####-#####",
    priority: 0,
    id: "163"
  },
  "164": {
    name: "Indonesia",
    regions: ["asia"],
    iso2: "id",
    countryCode: "62",
    dialCode: "62",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "164"
  },
  "165": {
    name: "Iran",
    regions: ["middle-east"],
    iso2: "ir",
    countryCode: "98",
    dialCode: "98",
    format: "+## ### ### ####",
    priority: 0,
    id: "165"
  },
  "166": {
    name: "Iraq",
    regions: ["middle-east"],
    iso2: "iq",
    countryCode: "964",
    dialCode: "964",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "166"
  },
  "167": {
    name: "Ireland",
    regions: ["europe", "eu-union"],
    iso2: "ie",
    countryCode: "353",
    dialCode: "353",
    format: "+### ## #######",
    priority: 0,
    id: "167"
  },
  "168": {
    name: "Israel",
    regions: ["middle-east"],
    iso2: "il",
    countryCode: "972",
    dialCode: "972",
    format: "+### ### ### ####",
    priority: 0,
    id: "168"
  },
  "169": {
    name: "Italy",
    regions: ["europe", "eu-union"],
    iso2: "it",
    countryCode: "39",
    dialCode: "39",
    format: "+## ### #######",
    priority: 0,
    id: "169"
  },
  "170": {
    name: "Jamaica",
    regions: ["america", "carribean"],
    iso2: "jm",
    countryCode: "1876",
    dialCode: "1876",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "170"
  },
  "171": {
    name: "Japan",
    regions: ["asia"],
    iso2: "jp",
    countryCode: "81",
    dialCode: "81",
    format: "+## ## #### ####",
    priority: 0,
    id: "171"
  },
  "172": {
    name: "Jordan",
    regions: ["middle-east"],
    iso2: "jo",
    countryCode: "962",
    dialCode: "962",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "172"
  },
  "173": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7",
    format: "+# ### ###-##-##",
    priority: 1,
    mainCode: true,
    hasAreaCodes: true,
    id: "173"
  },
  "174": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7310",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "174"
  },
  "175": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7311",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "175"
  },
  "176": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7312",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "176"
  },
  "177": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7313",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "177"
  },
  "178": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7315",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "178"
  },
  "179": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7318",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "179"
  },
  "180": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7321",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "180"
  },
  "181": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7324",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "181"
  },
  "182": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7325",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "182"
  },
  "183": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7326",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "183"
  },
  "184": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7327",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "184"
  },
  "185": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "7336",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "185"
  },
  "186": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "77172",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 4,
    id: "186"
  },
  "187": {
    name: "Kazakhstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    countryCode: "7",
    dialCode: "773622",
    format: "+# ### ###-##-##",
    priority: 1,
    isAreaCode: true,
    areaCodeLength: 5,
    id: "187"
  },
  "188": {
    name: "Kenya",
    regions: ["africa"],
    iso2: "ke",
    countryCode: "254",
    dialCode: "254",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "188"
  },
  "189": {
    name: "Kiribati",
    regions: ["oceania"],
    iso2: "ki",
    countryCode: "686",
    dialCode: "686",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "189"
  },
  "190": {
    name: "Kosovo",
    regions: ["europe", "ex-yugos"],
    iso2: "xk",
    countryCode: "383",
    dialCode: "383",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "190"
  },
  "191": {
    name: "Kuwait",
    regions: ["middle-east"],
    iso2: "kw",
    countryCode: "965",
    dialCode: "965",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "191"
  },
  "192": {
    name: "Kyrgyzstan",
    regions: ["asia", "ex-ussr"],
    iso2: "kg",
    countryCode: "996",
    dialCode: "996",
    format: "+### ### ### ###",
    priority: 0,
    id: "192"
  },
  "193": {
    name: "Laos",
    regions: ["asia"],
    iso2: "la",
    countryCode: "856",
    dialCode: "856",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "193"
  },
  "194": {
    name: "Latvia",
    regions: ["europe", "eu-union", "ex-ussr", "baltic"],
    iso2: "lv",
    countryCode: "371",
    dialCode: "371",
    format: "+### ## ### ###",
    priority: 0,
    id: "194"
  },
  "195": {
    name: "Lebanon",
    regions: ["middle-east"],
    iso2: "lb",
    countryCode: "961",
    dialCode: "961",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "195"
  },
  "196": {
    name: "Lesotho",
    regions: ["africa"],
    iso2: "ls",
    countryCode: "266",
    dialCode: "266",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "196"
  },
  "197": {
    name: "Liberia",
    regions: ["africa"],
    iso2: "lr",
    countryCode: "231",
    dialCode: "231",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "197"
  },
  "198": {
    name: "Libya",
    regions: ["africa", "north-africa"],
    iso2: "ly",
    countryCode: "218",
    dialCode: "218",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "198"
  },
  "199": {
    name: "Liechtenstein",
    regions: ["europe"],
    iso2: "li",
    countryCode: "423",
    dialCode: "423",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "199"
  },
  "200": {
    name: "Lithuania",
    regions: ["europe", "eu-union", "ex-ussr", "baltic"],
    iso2: "lt",
    countryCode: "370",
    dialCode: "370",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "200"
  },
  "201": {
    name: "Luxembourg",
    regions: ["europe", "eu-union"],
    iso2: "lu",
    countryCode: "352",
    dialCode: "352",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "201"
  },
  "202": {
    name: "Macau",
    regions: ["asia"],
    iso2: "mo",
    countryCode: "853",
    dialCode: "853",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "202"
  },
  "203": {
    name: "Macedonia",
    regions: ["europe", "ex-yugos"],
    iso2: "mk",
    countryCode: "389",
    dialCode: "389",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "203"
  },
  "204": {
    name: "Madagascar",
    regions: ["africa"],
    iso2: "mg",
    countryCode: "261",
    dialCode: "261",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "204"
  },
  "205": {
    name: "Malawi",
    regions: ["africa"],
    iso2: "mw",
    countryCode: "265",
    dialCode: "265",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "205"
  },
  "206": {
    name: "Malaysia",
    regions: ["asia"],
    iso2: "my",
    countryCode: "60",
    dialCode: "60",
    format: "+## ##-####-####",
    priority: 0,
    id: "206"
  },
  "207": {
    name: "Maldives",
    regions: ["asia"],
    iso2: "mv",
    countryCode: "960",
    dialCode: "960",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "207"
  },
  "208": {
    name: "Mali",
    regions: ["africa"],
    iso2: "ml",
    countryCode: "223",
    dialCode: "223",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "208"
  },
  "209": {
    name: "Malta",
    regions: ["europe", "eu-union"],
    iso2: "mt",
    countryCode: "356",
    dialCode: "356",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "209"
  },
  "210": {
    name: "Marshall Islands",
    regions: ["oceania"],
    iso2: "mh",
    countryCode: "692",
    dialCode: "692",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "210"
  },
  "211": {
    name: "Martinique",
    regions: ["america", "carribean"],
    iso2: "mq",
    countryCode: "596",
    dialCode: "596",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "211"
  },
  "212": {
    name: "Mauritania",
    regions: ["africa"],
    iso2: "mr",
    countryCode: "222",
    dialCode: "222",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "212"
  },
  "213": {
    name: "Mauritius",
    regions: ["africa"],
    iso2: "mu",
    countryCode: "230",
    dialCode: "230",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "213"
  },
  "214": {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "52",
    format: "+## ### ### ####",
    priority: 0,
    mainCode: true,
    hasAreaCodes: true,
    id: "214"
  },
  "215": {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "5255",
    format: "+## ### ### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 2,
    id: "215"
  },
  "216": {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "5281",
    format: "+## ### ### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 2,
    id: "216"
  },
  "217": {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "5233",
    format: "+## ### ### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 2,
    id: "217"
  },
  "218": {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "52656",
    format: "+## ### ### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "218"
  },
  "219": {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "52664",
    format: "+## ### ### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "219"
  },
  "220": {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "52998",
    format: "+## ### ### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "220"
  },
  "221": {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "52774",
    format: "+## ### ### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "221"
  },
  "222": {
    name: "Mexico",
    regions: ["america", "central-america"],
    iso2: "mx",
    countryCode: "52",
    dialCode: "52229",
    format: "+## ### ### ####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "222"
  },
  "223": {
    name: "Micronesia",
    regions: ["oceania"],
    iso2: "fm",
    countryCode: "691",
    dialCode: "691",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "223"
  },
  "224": {
    name: "Moldova",
    regions: ["europe"],
    iso2: "md",
    countryCode: "373",
    dialCode: "373",
    format: "+### (##) ##-##-##",
    priority: 0,
    id: "224"
  },
  "225": {
    name: "Monaco",
    regions: ["europe"],
    iso2: "mc",
    countryCode: "377",
    dialCode: "377",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "225"
  },
  "226": {
    name: "Mongolia",
    regions: ["asia"],
    iso2: "mn",
    countryCode: "976",
    dialCode: "976",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "226"
  },
  "227": {
    name: "Montenegro",
    regions: ["europe", "ex-yugos"],
    iso2: "me",
    countryCode: "382",
    dialCode: "382",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "227"
  },
  "228": {
    name: "Morocco",
    regions: ["africa", "north-africa"],
    iso2: "ma",
    countryCode: "212",
    dialCode: "212",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "228"
  },
  "229": {
    name: "Mozambique",
    regions: ["africa"],
    iso2: "mz",
    countryCode: "258",
    dialCode: "258",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "229"
  },
  "230": {
    name: "Myanmar",
    regions: ["asia"],
    iso2: "mm",
    countryCode: "95",
    dialCode: "95",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "230"
  },
  "231": {
    name: "Namibia",
    regions: ["africa"],
    iso2: "na",
    countryCode: "264",
    dialCode: "264",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "231"
  },
  "232": {
    name: "Nauru",
    regions: ["africa"],
    iso2: "nr",
    countryCode: "674",
    dialCode: "674",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "232"
  },
  "233": {
    name: "Nepal",
    regions: ["asia"],
    iso2: "np",
    countryCode: "977",
    dialCode: "977",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "233"
  },
  "234": {
    name: "Netherlands",
    regions: ["europe", "eu-union"],
    iso2: "nl",
    countryCode: "31",
    dialCode: "31",
    format: "+## ## ########",
    priority: 0,
    id: "234"
  },
  "235": {
    name: "New Caledonia",
    regions: ["oceania"],
    iso2: "nc",
    countryCode: "687",
    dialCode: "687",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "235"
  },
  "236": {
    name: "New Zealand",
    regions: ["oceania"],
    iso2: "nz",
    countryCode: "64",
    dialCode: "64",
    format: "+## ###-###-####",
    priority: 0,
    id: "236"
  },
  "237": {
    name: "Nicaragua",
    regions: ["america", "central-america"],
    iso2: "ni",
    countryCode: "505",
    dialCode: "505",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "237"
  },
  "238": {
    name: "Niger",
    regions: ["africa"],
    iso2: "ne",
    countryCode: "227",
    dialCode: "227",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "238"
  },
  "239": {
    name: "Nigeria",
    regions: ["africa"],
    iso2: "ng",
    countryCode: "234",
    dialCode: "234",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "239"
  },
  "240": {
    name: "North Korea",
    regions: ["asia"],
    iso2: "kp",
    countryCode: "850",
    dialCode: "850",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "240"
  },
  "241": {
    name: "Norway",
    regions: ["europe", "baltic"],
    iso2: "no",
    countryCode: "47",
    dialCode: "47",
    format: "+## ### ## ###",
    priority: 0,
    id: "241"
  },
  "242": {
    name: "Oman",
    regions: ["middle-east"],
    iso2: "om",
    countryCode: "968",
    dialCode: "968",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "242"
  },
  "243": {
    name: "Pakistan",
    regions: ["asia"],
    iso2: "pk",
    countryCode: "92",
    dialCode: "92",
    format: "+## ###-#######",
    priority: 0,
    id: "243"
  },
  "244": {
    name: "Palau",
    regions: ["oceania"],
    iso2: "pw",
    countryCode: "680",
    dialCode: "680",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "244"
  },
  "245": {
    name: "Palestine",
    regions: ["middle-east"],
    iso2: "ps",
    countryCode: "970",
    dialCode: "970",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "245"
  },
  "246": {
    name: "Panama",
    regions: ["america", "central-america"],
    iso2: "pa",
    countryCode: "507",
    dialCode: "507",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "246"
  },
  "247": {
    name: "Papua New Guinea",
    regions: ["oceania"],
    iso2: "pg",
    countryCode: "675",
    dialCode: "675",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "247"
  },
  "248": {
    name: "Paraguay",
    regions: ["america", "south-america"],
    iso2: "py",
    countryCode: "595",
    dialCode: "595",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "248"
  },
  "249": {
    name: "Peru",
    regions: ["america", "south-america"],
    iso2: "pe",
    countryCode: "51",
    dialCode: "51",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "249"
  },
  "250": {
    name: "Philippines",
    regions: ["asia"],
    iso2: "ph",
    countryCode: "63",
    dialCode: "63",
    format: "+## #### #######",
    priority: 0,
    id: "250"
  },
  "251": {
    name: "Poland",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "pl",
    countryCode: "48",
    dialCode: "48",
    format: "+## ###-###-###",
    priority: 0,
    id: "251"
  },
  "252": {
    name: "Portugal",
    regions: ["europe", "eu-union"],
    iso2: "pt",
    countryCode: "351",
    dialCode: "351",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "252"
  },
  "253": {
    name: "Puerto Rico",
    regions: ["america", "carribean"],
    iso2: "pr",
    countryCode: "1",
    dialCode: "1",
    format: "+# ### ### ### ### ##",
    priority: 3,
    mainCode: true,
    hasAreaCodes: true,
    id: "253"
  },
  "254": {
    name: "Puerto Rico",
    regions: ["america", "carribean"],
    iso2: "pr",
    countryCode: "1",
    dialCode: "1787",
    format: "+# ### ### ### ### ##",
    priority: 3,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "254"
  },
  "255": {
    name: "Puerto Rico",
    regions: ["america", "carribean"],
    iso2: "pr",
    countryCode: "1",
    dialCode: "1939",
    format: "+# ### ### ### ### ##",
    priority: 3,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "255"
  },
  "256": {
    name: "Qatar",
    regions: ["middle-east"],
    iso2: "qa",
    countryCode: "974",
    dialCode: "974",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "256"
  },
  "257": {
    name: "Réunion",
    regions: ["africa"],
    iso2: "re",
    countryCode: "262",
    dialCode: "262",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "257"
  },
  "258": {
    name: "Romania",
    regions: ["europe", "eu-union"],
    iso2: "ro",
    countryCode: "40",
    dialCode: "40",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "258"
  },
  "259": {
    name: "Russia",
    regions: ["europe", "asia", "ex-ussr", "baltic"],
    iso2: "ru",
    countryCode: "7",
    dialCode: "7",
    format: "+# (###) ###-##-##",
    priority: 0,
    id: "259"
  },
  "260": {
    name: "Rwanda",
    regions: ["africa"],
    iso2: "rw",
    countryCode: "250",
    dialCode: "250",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "260"
  },
  "261": {
    name: "Saint Kitts and Nevis",
    regions: ["america", "carribean"],
    iso2: "kn",
    countryCode: "1869",
    dialCode: "1869",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "261"
  },
  "262": {
    name: "Saint Lucia",
    regions: ["america", "carribean"],
    iso2: "lc",
    countryCode: "1758",
    dialCode: "1758",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "262"
  },
  "263": {
    name: "Saint Vincent and the Grenadines",
    regions: ["america", "carribean"],
    iso2: "vc",
    countryCode: "1784",
    dialCode: "1784",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "263"
  },
  "264": {
    name: "Samoa",
    regions: ["oceania"],
    iso2: "ws",
    countryCode: "685",
    dialCode: "685",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "264"
  },
  "265": {
    name: "San Marino",
    regions: ["europe"],
    iso2: "sm",
    countryCode: "378",
    dialCode: "378",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "265"
  },
  "266": {
    name: "São Tomé and Príncipe",
    regions: ["africa"],
    iso2: "st",
    countryCode: "239",
    dialCode: "239",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "266"
  },
  "267": {
    name: "Saudi Arabia",
    regions: ["middle-east"],
    iso2: "sa",
    countryCode: "966",
    dialCode: "966",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "267"
  },
  "268": {
    name: "Senegal",
    regions: ["africa"],
    iso2: "sn",
    countryCode: "221",
    dialCode: "221",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "268"
  },
  "269": {
    name: "Serbia",
    regions: ["europe", "ex-yugos"],
    iso2: "rs",
    countryCode: "381",
    dialCode: "381",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "269"
  },
  "270": {
    name: "Seychelles",
    regions: ["africa"],
    iso2: "sc",
    countryCode: "248",
    dialCode: "248",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "270"
  },
  "271": {
    name: "Sierra Leone",
    regions: ["africa"],
    iso2: "sl",
    countryCode: "232",
    dialCode: "232",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "271"
  },
  "272": {
    name: "Singapore",
    regions: ["asia"],
    iso2: "sg",
    countryCode: "65",
    dialCode: "65",
    format: "+## ####-####",
    priority: 0,
    id: "272"
  },
  "273": {
    name: "Slovakia",
    regions: ["europe", "eu-union"],
    iso2: "sk",
    countryCode: "421",
    dialCode: "421",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "273"
  },
  "274": {
    name: "Slovenia",
    regions: ["europe", "eu-union", "ex-yugos"],
    iso2: "si",
    countryCode: "386",
    dialCode: "386",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "274"
  },
  "275": {
    name: "Solomon Islands",
    regions: ["oceania"],
    iso2: "sb",
    countryCode: "677",
    dialCode: "677",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "275"
  },
  "276": {
    name: "Somalia",
    regions: ["africa"],
    iso2: "so",
    countryCode: "252",
    dialCode: "252",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "276"
  },
  "277": {
    name: "South Africa",
    regions: ["africa"],
    iso2: "za",
    countryCode: "27",
    dialCode: "27",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "277"
  },
  "278": {
    name: "South Korea",
    regions: ["asia"],
    iso2: "kr",
    countryCode: "82",
    dialCode: "82",
    format: "+## ### #### ####",
    priority: 0,
    id: "278"
  },
  "279": {
    name: "South Sudan",
    regions: ["africa", "north-africa"],
    iso2: "ss",
    countryCode: "211",
    dialCode: "211",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "279"
  },
  "280": {
    name: "Spain",
    regions: ["europe", "eu-union"],
    iso2: "es",
    countryCode: "34",
    dialCode: "34",
    format: "+## ### ### ###",
    priority: 0,
    id: "280"
  },
  "281": {
    name: "Sri Lanka",
    regions: ["asia"],
    iso2: "lk",
    countryCode: "94",
    dialCode: "94",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "281"
  },
  "282": {
    name: "Sudan",
    regions: ["africa"],
    iso2: "sd",
    countryCode: "249",
    dialCode: "249",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "282"
  },
  "283": {
    name: "Suriname",
    regions: ["america", "south-america"],
    iso2: "sr",
    countryCode: "597",
    dialCode: "597",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "283"
  },
  "284": {
    name: "Swaziland",
    regions: ["africa"],
    iso2: "sz",
    countryCode: "268",
    dialCode: "268",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "284"
  },
  "285": {
    name: "Sweden",
    regions: ["europe", "eu-union", "baltic"],
    iso2: "se",
    countryCode: "46",
    dialCode: "46",
    format: "+## (###) ###-###",
    priority: 0,
    id: "285"
  },
  "286": {
    name: "Switzerland",
    regions: ["europe"],
    iso2: "ch",
    countryCode: "41",
    dialCode: "41",
    format: "+## ## ### ## ##",
    priority: 0,
    id: "286"
  },
  "287": {
    name: "Syria",
    regions: ["middle-east"],
    iso2: "sy",
    countryCode: "963",
    dialCode: "963",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "287"
  },
  "288": {
    name: "Taiwan",
    regions: ["asia"],
    iso2: "tw",
    countryCode: "886",
    dialCode: "886",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "288"
  },
  "289": {
    name: "Tajikistan",
    regions: ["asia", "ex-ussr"],
    iso2: "tj",
    countryCode: "992",
    dialCode: "992",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "289"
  },
  "290": {
    name: "Tanzania",
    regions: ["africa"],
    iso2: "tz",
    countryCode: "255",
    dialCode: "255",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "290"
  },
  "291": {
    name: "Thailand",
    regions: ["asia"],
    iso2: "th",
    countryCode: "66",
    dialCode: "66",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "291"
  },
  "292": {
    name: "Timor-Leste",
    regions: ["asia"],
    iso2: "tl",
    countryCode: "670",
    dialCode: "670",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "292"
  },
  "293": {
    name: "Togo",
    regions: ["africa"],
    iso2: "tg",
    countryCode: "228",
    dialCode: "228",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "293"
  },
  "294": {
    name: "Tonga",
    regions: ["oceania"],
    iso2: "to",
    countryCode: "676",
    dialCode: "676",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "294"
  },
  "295": {
    name: "Trinidad and Tobago",
    regions: ["america", "carribean"],
    iso2: "tt",
    countryCode: "1868",
    dialCode: "1868",
    format: "+#### ### ### ### ### ##",
    priority: 0,
    id: "295"
  },
  "296": {
    name: "Tunisia",
    regions: ["africa", "north-africa"],
    iso2: "tn",
    countryCode: "216",
    dialCode: "216",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "296"
  },
  "297": {
    name: "Turkey",
    regions: ["europe"],
    iso2: "tr",
    countryCode: "90",
    dialCode: "90",
    format: "+## ### ### ## ##",
    priority: 0,
    id: "297"
  },
  "298": {
    name: "Turkmenistan",
    regions: ["asia", "ex-ussr"],
    iso2: "tm",
    countryCode: "993",
    dialCode: "993",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "298"
  },
  "299": {
    name: "Tuvalu",
    regions: ["asia"],
    iso2: "tv",
    countryCode: "688",
    dialCode: "688",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "299"
  },
  "300": {
    name: "Uganda",
    regions: ["africa"],
    iso2: "ug",
    countryCode: "256",
    dialCode: "256",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "300"
  },
  "301": {
    name: "Ukraine",
    regions: ["europe", "ex-ussr"],
    iso2: "ua",
    countryCode: "380",
    dialCode: "380",
    format: "+### (##) ### ## ##",
    priority: 0,
    id: "301"
  },
  "302": {
    name: "United Arab Emirates",
    regions: ["middle-east"],
    iso2: "ae",
    countryCode: "971",
    dialCode: "971",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "302"
  },
  "303": {
    name: "United Kingdom",
    regions: ["europe", "eu-union"],
    iso2: "gb",
    countryCode: "44",
    dialCode: "44",
    format: "+## #### ######",
    priority: 0,
    id: "303"
  },
  "304": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1",
    format: "+# (###) ###-####",
    priority: 0,
    mainCode: true,
    hasAreaCodes: true,
    id: "304"
  },
  "305": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1907",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "305"
  },
  "306": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1205",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "306"
  },
  "307": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1251",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "307"
  },
  "308": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1256",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "308"
  },
  "309": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1334",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "309"
  },
  "310": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1479",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "310"
  },
  "311": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1501",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "311"
  },
  "312": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1870",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "312"
  },
  "313": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1480",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "313"
  },
  "314": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1520",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "314"
  },
  "315": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1602",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "315"
  },
  "316": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1623",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "316"
  },
  "317": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1928",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "317"
  },
  "318": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1209",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "318"
  },
  "319": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1213",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "319"
  },
  "320": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1310",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "320"
  },
  "321": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1323",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "321"
  },
  "322": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1408",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "322"
  },
  "323": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1415",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "323"
  },
  "324": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1510",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "324"
  },
  "325": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1530",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "325"
  },
  "326": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1559",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "326"
  },
  "327": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1562",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "327"
  },
  "328": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1619",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "328"
  },
  "329": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1626",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "329"
  },
  "330": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1650",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "330"
  },
  "331": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1661",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "331"
  },
  "332": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1707",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "332"
  },
  "333": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1714",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "333"
  },
  "334": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1760",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "334"
  },
  "335": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1805",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "335"
  },
  "336": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1818",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "336"
  },
  "337": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1831",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "337"
  },
  "338": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1858",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "338"
  },
  "339": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1909",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "339"
  },
  "340": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1916",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "340"
  },
  "341": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1925",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "341"
  },
  "342": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1949",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "342"
  },
  "343": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1951",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "343"
  },
  "344": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1303",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "344"
  },
  "345": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1719",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "345"
  },
  "346": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1970",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "346"
  },
  "347": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1203",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "347"
  },
  "348": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1860",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "348"
  },
  "349": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1202",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "349"
  },
  "350": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1302",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "350"
  },
  "351": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1239",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "351"
  },
  "352": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1305",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "352"
  },
  "353": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1321",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "353"
  },
  "354": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1352",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "354"
  },
  "355": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1386",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "355"
  },
  "356": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1407",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "356"
  },
  "357": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1561",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "357"
  },
  "358": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1727",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "358"
  },
  "359": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1772",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "359"
  },
  "360": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1813",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "360"
  },
  "361": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1850",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "361"
  },
  "362": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1863",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "362"
  },
  "363": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1904",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "363"
  },
  "364": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1941",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "364"
  },
  "365": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1954",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "365"
  },
  "366": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1229",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "366"
  },
  "367": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1404",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "367"
  },
  "368": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1478",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "368"
  },
  "369": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1706",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "369"
  },
  "370": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1770",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "370"
  },
  "371": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1912",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "371"
  },
  "372": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1808",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "372"
  },
  "373": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1319",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "373"
  },
  "374": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1515",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "374"
  },
  "375": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1563",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "375"
  },
  "376": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1641",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "376"
  },
  "377": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1712",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "377"
  },
  "378": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1208",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "378"
  },
  "379": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1217",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "379"
  },
  "380": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1309",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "380"
  },
  "381": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1312",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "381"
  },
  "382": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1618",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "382"
  },
  "383": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1630",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "383"
  },
  "384": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1708",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "384"
  },
  "385": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1773",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "385"
  },
  "386": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1815",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "386"
  },
  "387": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1847",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "387"
  },
  "388": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1219",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "388"
  },
  "389": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1260",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "389"
  },
  "390": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1317",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "390"
  },
  "391": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1574",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "391"
  },
  "392": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1765",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "392"
  },
  "393": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1812",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "393"
  },
  "394": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1316",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "394"
  },
  "395": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1620",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "395"
  },
  "396": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1785",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "396"
  },
  "397": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1913",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "397"
  },
  "398": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1270",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "398"
  },
  "399": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1502",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "399"
  },
  "400": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1606",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "400"
  },
  "401": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1859",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "401"
  },
  "402": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1225",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "402"
  },
  "403": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1318",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "403"
  },
  "404": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1337",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "404"
  },
  "405": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1504",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "405"
  },
  "406": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1985",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "406"
  },
  "407": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1413",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "407"
  },
  "408": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1508",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "408"
  },
  "409": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1617",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "409"
  },
  "410": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1781",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "410"
  },
  "411": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1978",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "411"
  },
  "412": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1301",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "412"
  },
  "413": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1410",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "413"
  },
  "414": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1207",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "414"
  },
  "415": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1231",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "415"
  },
  "416": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1248",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "416"
  },
  "417": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1269",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "417"
  },
  "418": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1313",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "418"
  },
  "419": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1517",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "419"
  },
  "420": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1586",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "420"
  },
  "421": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1616",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "421"
  },
  "422": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1734",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "422"
  },
  "423": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1810",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "423"
  },
  "424": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1906",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "424"
  },
  "425": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1989",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "425"
  },
  "426": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1218",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "426"
  },
  "427": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1320",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "427"
  },
  "428": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1507",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "428"
  },
  "429": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1612",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "429"
  },
  "430": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1651",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "430"
  },
  "431": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1763",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "431"
  },
  "432": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1952",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "432"
  },
  "433": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1314",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "433"
  },
  "434": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1417",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "434"
  },
  "435": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1573",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "435"
  },
  "436": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1636",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "436"
  },
  "437": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1660",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "437"
  },
  "438": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1816",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "438"
  },
  "439": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1228",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "439"
  },
  "440": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1601",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "440"
  },
  "441": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1662",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "441"
  },
  "442": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1406",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "442"
  },
  "443": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1252",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "443"
  },
  "444": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1336",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "444"
  },
  "445": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1704",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "445"
  },
  "446": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1828",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "446"
  },
  "447": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1910",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "447"
  },
  "448": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1919",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "448"
  },
  "449": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1701",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "449"
  },
  "450": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1308",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "450"
  },
  "451": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1402",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "451"
  },
  "452": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1603",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "452"
  },
  "453": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1201",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "453"
  },
  "454": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1609",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "454"
  },
  "455": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1732",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "455"
  },
  "456": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1856",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "456"
  },
  "457": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1908",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "457"
  },
  "458": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1973",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "458"
  },
  "459": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1505",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "459"
  },
  "460": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1575",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "460"
  },
  "461": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1702",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "461"
  },
  "462": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1775",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "462"
  },
  "463": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1212",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "463"
  },
  "464": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1315",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "464"
  },
  "465": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1516",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "465"
  },
  "466": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1518",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "466"
  },
  "467": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1585",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "467"
  },
  "468": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1607",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "468"
  },
  "469": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1631",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "469"
  },
  "470": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1716",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "470"
  },
  "471": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1718",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "471"
  },
  "472": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1845",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "472"
  },
  "473": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1914",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "473"
  },
  "474": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1216",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "474"
  },
  "475": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1330",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "475"
  },
  "476": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1419",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "476"
  },
  "477": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1440",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "477"
  },
  "478": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1513",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "478"
  },
  "479": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1614",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "479"
  },
  "480": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1740",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "480"
  },
  "481": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1937",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "481"
  },
  "482": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1405",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "482"
  },
  "483": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1580",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "483"
  },
  "484": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1918",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "484"
  },
  "485": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1503",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "485"
  },
  "486": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1541",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "486"
  },
  "487": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1215",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "487"
  },
  "488": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1412",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "488"
  },
  "489": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1570",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "489"
  },
  "490": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1610",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "490"
  },
  "491": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1717",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "491"
  },
  "492": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1724",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "492"
  },
  "493": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1814",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "493"
  },
  "494": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1401",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "494"
  },
  "495": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1803",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "495"
  },
  "496": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1843",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "496"
  },
  "497": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1864",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "497"
  },
  "498": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1605",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "498"
  },
  "499": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1423",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "499"
  },
  "500": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1615",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "500"
  },
  "501": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1731",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "501"
  },
  "502": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1865",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "502"
  },
  "503": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1901",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "503"
  },
  "504": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1931",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "504"
  },
  "505": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1210",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "505"
  },
  "506": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1214",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "506"
  },
  "507": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1254",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "507"
  },
  "508": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1281",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "508"
  },
  "509": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1325",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "509"
  },
  "510": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1361",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "510"
  },
  "511": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1409",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "511"
  },
  "512": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1432",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "512"
  },
  "513": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1512",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "513"
  },
  "514": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1713",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "514"
  },
  "515": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1806",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "515"
  },
  "516": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1817",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "516"
  },
  "517": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1830",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "517"
  },
  "518": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1903",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "518"
  },
  "519": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1915",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "519"
  },
  "520": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1936",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "520"
  },
  "521": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1940",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "521"
  },
  "522": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1956",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "522"
  },
  "523": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1972",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "523"
  },
  "524": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1979",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "524"
  },
  "525": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1435",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "525"
  },
  "526": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1801",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "526"
  },
  "527": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1276",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "527"
  },
  "528": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1434",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "528"
  },
  "529": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1540",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "529"
  },
  "530": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1703",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "530"
  },
  "531": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1757",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "531"
  },
  "532": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1804",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "532"
  },
  "533": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1802",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "533"
  },
  "534": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1206",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "534"
  },
  "535": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1253",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "535"
  },
  "536": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1360",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "536"
  },
  "537": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1425",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "537"
  },
  "538": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1509",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "538"
  },
  "539": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1262",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "539"
  },
  "540": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1414",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "540"
  },
  "541": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1608",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "541"
  },
  "542": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1715",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "542"
  },
  "543": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1920",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "543"
  },
  "544": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1304",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "544"
  },
  "545": {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    countryCode: "1",
    dialCode: "1307",
    format: "+# (###) ###-####",
    priority: 0,
    isAreaCode: true,
    areaCodeLength: 3,
    id: "545"
  },
  "546": {
    name: "Uruguay",
    regions: ["america", "south-america"],
    iso2: "uy",
    countryCode: "598",
    dialCode: "598",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "546"
  },
  "547": {
    name: "Uzbekistan",
    regions: ["asia", "ex-ussr"],
    iso2: "uz",
    countryCode: "998",
    dialCode: "998",
    format: "+### ## ### ## ##",
    priority: 0,
    id: "547"
  },
  "548": {
    name: "Vanuatu",
    regions: ["oceania"],
    iso2: "vu",
    countryCode: "678",
    dialCode: "678",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "548"
  },
  "549": {
    name: "Vatican City",
    regions: ["europe"],
    iso2: "va",
    countryCode: "39",
    dialCode: "39",
    format: "+## ## #### ####",
    priority: 1,
    id: "549"
  },
  "550": {
    name: "Venezuela",
    regions: ["america", "south-america"],
    iso2: "ve",
    countryCode: "58",
    dialCode: "58",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "550"
  },
  "551": {
    name: "Vietnam",
    regions: ["asia"],
    iso2: "vn",
    countryCode: "84",
    dialCode: "84",
    format: "+## ### ### ### ### ##",
    priority: 0,
    id: "551"
  },
  "552": {
    name: "Yemen",
    regions: ["middle-east"],
    iso2: "ye",
    countryCode: "967",
    dialCode: "967",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "552"
  },
  "553": {
    name: "Zambia",
    regions: ["africa"],
    iso2: "zm",
    countryCode: "260",
    dialCode: "260",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "553"
  },
  "554": {
    name: "Zimbabwe",
    regions: ["africa"],
    iso2: "zw",
    countryCode: "263",
    dialCode: "263",
    format: "+### ### ### ### ### ##",
    priority: 0,
    id: "554"
  }
};
