import type { FC } from "react";
import React from "react";
import { css, cx } from "emotion";
import { notification as antNotification } from "antd";
import { Close, Check, Info, Warning, Exclamation } from "@certa/icons";
import { Z_INDEX } from "../../constants";
import { Description } from "./Description";
import type { NotificationProps, NotificationType } from "./types";
import { mapTypeToColor } from "./utils";
import { Message } from "./Message";
import { CSSVariablesClassName } from "../../layout/DesignTheme";
// TODO: Look for better way to check for Certa 2.0 without using external packages
import { canSeeCERTA2 } from "@certa/common/src/utils/routes";
import { getLayoutContainer } from "../../utils";

const NotificationIcon: FC<{
  type: NotificationType;
  customIcon?: NotificationProps["icon"];
}> = ({ type, customIcon: CustomIcon }) => {
  if (CustomIcon) {
    return <CustomIcon />;
  }
  switch (type) {
    case "success":
      return <Check />;
    case "info":
      return <Info />;
    case "warning":
      return <Warning />;
    case "error":
      return <Exclamation />;
    default:
      return <Check />;
  }
};

const getNotification = (methodType?: NotificationType) => {
  return (config: NotificationProps) => {
    const {
      type: configType,
      message,
      description,
      icon,
      placement,
      duration,
      status,
      className,
      isCERTA2Enabled,
      ...restOfConfig
    } = config;

    const type = methodType || configType || "info";
    const isNewUI = isCERTA2Enabled ?? canSeeCERTA2() ?? false;
    // Mount node for Notification
    const container = getLayoutContainer();

    /**
     * TODO: Ideally these styles should be handled by ant design. Since antd doesn't
     * provide a way to override notifications container styles, a custom prefix class needs
     * to be added and passed to antd notification. The side effect of this is that we now need
     * to handle the styles applied by antd ourselves. This was required to modify the z-index
     * of the notifications container.
     * .ant-notification -> .css-xxxxxxx
     * .ant-notification-notice-message -> .css-xxxxxxx-notice-message
     */
    const PREFIX_CLASS = css`
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      list-style: none;
      position: fixed;
      z-index: ${Z_INDEX["zindex-popover"]};
      width: ${isNewUI ? "unset" : "384px"};
      max-width: calc(100vw - 32px);
      margin-right: 24px;

      * {
        font-family: "Inter", sans-serif, "system-ui" !important;
      }

      &-bottomLeft {
        left: 0px;
        top: auto;
        bottom: 24px;
        margin-right: 0;
        margin-left: 24px;
      }

      &-bottomRight {
        right: 0px;
        top: auto;
        bottom: 24px;
        margin-right: 24px;
        margin-left: 0;
      }

      &-topLeft {
        left: 0px;
        top: 24px;
        bottom: auto;
        margin-right: 0;
        margin-left: 24px;
      }

      &-topRight {
        right: 0px;
        top: 24px;
        bottom: auto;
        margin-right: 24px;
        margin-left: 0;
      }

      &-fade-enter,
      &-fade-appear {
        animation-duration: 0.24s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        opacity: 0;
        animation-play-state: paused;
      }

      &-fade-leave {
        animation-timing-function: ease-in-out;
        animation-fill-mode: both;
        animation-duration: 0.2s;
        animation-play-state: paused;
      }

      &-fade-enter&-fade-enter-active,
      &-fade-appear&-fade-appear-active {
        animation-name: ${placement &&
        ["bottomLeft", "topLeft"].includes(placement)
          ? "NotificationLeftFadeIn"
          : "NotificationFadeIn"};
        animation-play-state: running;
      }

      &-fade-leave&-fade-leave-active {
        animation-name: NotificationFadeOut;
        animation-play-state: running;
      }
    `;

    if (container) {
      return antNotification.open({
        ...restOfConfig,
        type: type,
        placement: placement ?? "bottomLeft",
        duration: duration ?? 4.5,
        prefixCls: isNewUI ? PREFIX_CLASS : undefined,
        message: isNewUI ? (
          <Message
            message={message}
            description={description}
            type={type}
            status={status}
          />
        ) : (
          `${message}${status ? `: ${status}` : ""}`
        ),
        description: isNewUI ? (
          <Description description={description} />
        ) : (
          description
        ),
        /**
         * TODO: Find an alternate way to convert CSS to a classname as the "emotion"
         * package might be removed in the near future. Cannot use ClassName from "@emotion/core"
         * here as there is no component to wrap it around.
         */
        className: isNewUI
          ? cx(
              CSSVariablesClassName,
              css`
                && {
                  position: relative;
                  background-color: var(--neutral-0);
                  box-shadow: var(--popover-shadow);
                  border-radius: var(--s2);
                  padding: var(--s4) var(--s12) var(--s4) var(--s2);
                  margin-bottom: 16px;
                  min-width: 384px;
                  max-width: ${status ? "500px" : "384px"};
                  width: max-content;
                  overflow: hidden;

                  ::before {
                    content: "";
                    width: var(--s1);
                    position: absolute;
                    left: var(--s2);
                    top: var(--s2);
                    bottom: var(--s2);
                    background-color: var(--${mapTypeToColor[type]});
                    border-radius: var(--s1);
                  }

                  .${PREFIX_CLASS}-notice-message {
                    margin: 0 0 0 var(--s10);
                    line-height: 17px;
                    padding-right: 0;
                  }
                  .${PREFIX_CLASS}-notice-description {
                    margin: ${config.description ? "var(--s2)" : "0"} 0 0
                      var(--s10);
                    line-height: 17px;
                  }
                  .${PREFIX_CLASS}-notice-with-icon {
                    line-height: 17px;
                  }
                  .${PREFIX_CLASS}-notice-icon {
                    position: absolute;
                    line-height: 17px;
                    margin-left: 14px;

                    svg {
                      height: var(--s4);
                      width: var(--s4);
                      color: var(--${mapTypeToColor[type]});
                    }
                  }
                  .${PREFIX_CLASS}-notice-close {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    padding: var(--s2) var(--s4);
                  }
                  .${PREFIX_CLASS}-close-x {
                    height: var(--s4);
                    display: inline-flex;
                  }
                }
              `,
              className
            )
          : undefined,
        icon: isNewUI ? (
          <NotificationIcon type={type} customIcon={icon} />
        ) : undefined,
        closeIcon: isNewUI ? <Close color="neutral-50" /> : undefined,
        getContainer: () => container
      });
    } else {
      throw Error("Could not find parent container.");
    }
  };
};
/**
 * @deprecated This component is deprecated and will be removed in future versions.
 * Please use the equivalent component from the Catalyst library instead.
 *
 * @example
 * // Preferred usage
 * import { Toast } from '@certa/catalyst';
 */
export const notification = {
  open: getNotification(),
  success: getNotification("success"),
  info: getNotification("info"),
  warning: getNotification("warning"),
  error: getNotification("error"),
  close: antNotification.close,
  destroy: antNotification.destroy
};
