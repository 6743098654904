export const Success = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11711_9028)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C12.4157 0 16 3.58431 16 8C16 12.4157 12.4157 16 8 16C3.58431 16 0 12.4157 0 8C0 3.58431 3.58431 0 8 0ZM6.51477 9.08883L11.6629 3.94071L13.1481 5.4456L6.51469 12.0594L2.87134 8.41606L4.35657 6.91117L6.5146 9.0895L6.51477 9.08883Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_11711_9028">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
