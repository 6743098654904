import { useMemo, useState, useRef, useEffect, forwardRef } from "react";
import type { DefaultOptionType } from "rc-cascader";
import RCCascader from "rc-cascader";

import { ChevronDown, ChevronRight, Clear, Spinner } from "@certa/icons";

import { FieldStatus } from "../types";

import "./Cascader.css";
import { classNames, mergeRefs } from "../../utils/common";
import { CASCADER_PLACEMENTS } from "./Cascader.constants";
import type {
  CascaderProps,
  BaseSelectRef,
  CascaderOptionType
} from "./Cascader.types";
import { DimmerVariants, Dimmer } from "../Dimmer";

export const Cascader = forwardRef((props: CascaderProps, ref) => {
  const {
    label,
    options,
    placeholder = "Select an item...",
    notFoundContent,
    showSearch = false,
    allowClear: shouldAllowClear = true,
    disabled: isDisabled = false,
    width = "100%",
    minWidth,
    maxWidth,
    dropdownWidth = "200px",
    dropdownMaxWidth = "100%",
    status,
    value,
    onChange,
    multiple: isMultiple = false,
    maxTagTextLength = 10,
    expandTrigger = "hover",
    suffixIcon,
    displayRender = isMultiple ? undefined : singleDisplayRenderer,
    defaultValue,
    placement,
    loadData,
    showCheckedStrategy,
    loading: isLoading
  } = props;
  const [searchValue, setSearchValue] = useState<string>("");
  const prefixCls = isMultiple
    ? "catalyst-multi-cascader"
    : "catalyst-cascader";
  const inputRef = useRef<BaseSelectRef>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const checkable = useMemo(
    () =>
      isMultiple ? (
        <span className={`catalyst-cascader-checkbox-inner`} />
      ) : (
        false
      ),
    [isMultiple]
  );

  const handleChange = (
    value: any,
    selectOptions: CascaderOptionType[] | CascaderOptionType[][]
  ) => {
    onChange && onChange(value, selectOptions);
    inputRef.current?.blur();
    setSearchValue("");
  };

  useEffect(() => {
    if (isOpen && !showSearch) {
      // This is needed when showSearch is false, on clicking of cascader, focus is
      // not coming in, we manually need to give focus to cascader, or else keyboard navigation will not work
      // bug got introduced with adding Dimmer in this component
      // TODO: find a better fix for this
      inputRef.current?.focus();
    }
  }, [isOpen, showSearch]);

  return (
    <>
      <RCCascader
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-hidden="true" // cascader menu item adds RC_CASCADER_SPLIT and join them to make key spitting to keyboard, it is not accessible so menu item hiding from screen reader, might need to look into way to make it accessible
        // @ts-expect-error TS5 upgrade
        role="combobox" // role is important to add, but is not added in prop of cascader, but since its passes all the restprops so it is passed to dom node
        value={value}
        ref={mergeRefs([inputRef, ref])}
        onChange={handleChange}
        checkable={checkable}
        defaultValue={defaultValue}
        aria-label={label}
        inputIcon={suffixIcon || <ChevronDown size={12} />}
        disabled={isDisabled}
        options={options}
        prefixCls={prefixCls}
        notFoundContent={notFoundContent}
        className={classNames({
          "catalyst-cascader-success": status === FieldStatus.SUCCESS
        })}
        showSearch={showSearch}
        allowClear={shouldAllowClear}
        displayRender={displayRender}
        showArrow={true}
        expandIcon={<ChevronRight size={12} />}
        clearIcon={<Clear size={12} />}
        builtinPlacements={CASCADER_PLACEMENTS}
        placeholder={placeholder}
        style={{ width, minWidth, maxWidth }}
        dropdownMenuColumnStyle={{
          width: searchValue ? "" : dropdownWidth,
          maxWidth: dropdownMaxWidth
        }}
        aria-invalid={status === FieldStatus.ERROR}
        maxTagCount={isMultiple ? "responsive" : undefined}
        maxTagTextLength={maxTagTextLength}
        expandTrigger={expandTrigger}
        onSearch={setSearchValue}
        placement={placement}
        onDropdownVisibleChange={setOpen}
        loadData={loadData}
        loading={isLoading}
        showCheckedStrategy={showCheckedStrategy}
        loadingIcon={<Spinner />}
      />
      {isOpen && <Dimmer variant={DimmerVariants.TRANSPARENT} />}
    </>
  );
});

export * from "./Cascader.types";

function singleDisplayRenderer(
  labels: string[],
  selectedOptions: DefaultOptionType[] | undefined
) {
  return <div title={labels.join(" \u2192 ")}>{labels.join(" \u2192 ")}</div>;
}
