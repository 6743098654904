import type { AllowedInputStyleProperties } from "../components/types";

export const CATALYST_Z_INDEXES = {
  DIMMER: "9999",
  TOAST: "9999",
  DIALOG: "9999",
  DROPDOWN: "9999",
  DRAWER: "9999"
};

export const CATALYST_PORTAL_CONTAINER_IDS = {
  POPOVER: "catalyst-popovers-wrapper"
};

const ALLOWED_STYLE_PROPERTIES: (keyof AllowedInputStyleProperties)[] = [
  "backgroundColor",
  "border",
  "borderRadius",
  "boxShadow",
  "focusedBorder"
] as const;

export const TEXT_INPUT_CONTAINER_STYLE_ALLOWED_PROPERTIES =
  ALLOWED_STYLE_PROPERTIES;
export const NUMBER_INPUT_CONTAINER_STYLE_ALLOWED_PROPERTIES =
  ALLOWED_STYLE_PROPERTIES;
export const SELECT_CONTROL_STYLE_ALLOWED_PROPERTIES = ALLOWED_STYLE_PROPERTIES;

export enum CatalystColors {
  BRAND_50 = "var(--colors-brand-50)",
  BRAND_100 = "var(--colors-brand-100)",
  BRAND_200 = "var(--colors-brand-200)",
  BRAND_300 = "var(--colors-brand-300)",
  BRAND_400 = "var(--colors-brand-400)",
  BRAND_500 = "var(--colors-brand-500)",
  BRAND_600 = "var(--colors-brand-600)",

  NEUTRAL_100 = "var(--colors-neutral-100)",
  NEUTRAL_200 = "var(--colors-neutral-200)",
  NEUTRAL_300 = "var(--colors-neutral-300)",
  NEUTRAL_400 = "var(--colors-neutral-400)",
  NEUTRAL_500 = "var(--colors-neutral-500)",
  NEUTRAL_600 = "var(--colors-neutral-600)",
  NEUTRAL_700 = "var(--colors-neutral-700)",
  NEUTRAL_800 = "var(--colors-neutral-800)",

  RED_100 = "var(--colors-red-100)",
  RED_200 = "var(--colors-red-200)",
  RED_300 = "var(--colors-red-300)",
  RED_400 = "var(--colors-red-400)",
  RED_500 = "var(--colors-red-500)",
  RED_600 = "var(--colors-red-600)",

  GREEN_100 = "var(--colors-green-100)",
  GREEN_200 = "var(--colors-green-200)",
  GREEN_300 = "var(--colors-green-300)",
  GREEN_400 = "var(--colors-green-400)",
  GREEN_500 = "var(--colors-green-500)",
  GREEN_600 = "var(--colors-green-600)",

  TEAL_100 = "var(--colors-teal-100)",
  TEAL_200 = "var(--colors-teal-200)",
  TEAL_300 = "var(--colors-teal-300)",
  TEAL_400 = "var(--colors-teal-400)",
  TEAL_500 = "var(--colors-teal-500)",
  TEAL_600 = "var(--colors-teal-600)",

  ORANGE_100 = "var(--colors-orange-100)",
  ORANGE_200 = "var(--colors-orange-200)",
  ORANGE_300 = "var(--colors-orange-300)",
  ORANGE_400 = "var(--colors-orange-400)",
  ORANGE_500 = "var(--colors-orange-500)",
  ORANGE_600 = "var(--colors-orange-600)",

  BLUEBERRY_100 = "var(--colors-blueberry-100)",
  BLUEBERRY_200 = "var(--colors-blueberry-200)",
  BLUEBERRY_300 = "var(--colors-blueberry-300)",
  BLUEBERRY_400 = "var(--colors-blueberry-400)",

  PURPLE_100 = "var(--colors-purple-100)",
  PURPLE_200 = "var(--colors-purple-200)",
  PURPLE_300 = "var(--colors-purple-300)",
  PURPLE_400 = "var(--colors-purple-400)",
  PURPLE_500 = "var(--colors-purple-500)",
  PURPLE_600 = "var(--colors-purple-600)"
}

export const CATALYST_COLORS_WITH_HEXCODES = {
  BRAND_50: "#f8faff",
  BRAND_100: "#ecf2ff",
  BRAND_200: "#a5bafe",
  BRAND_300: "#5c8bfe",
  BRAND_400: "#1f5eff",
  BRAND_500: "#194fd7",
  BRAND_600: "#1543b5",

  NEUTRAL_100: "#ffffff",
  NEUTRAL_200: "#f8f9f9",
  NEUTRAL_300: "#f4f5f5",
  NEUTRAL_400: "#eeefef",
  NEUTRAL_500: "#d0d4df",
  NEUTRAL_600: "#8892ac",
  NEUTRAL_700: "#6b7693",
  NEUTRAL_800: "#00164e",

  RED_100: "#fff8f8",
  RED_200: "#fee9e8",
  RED_300: "#fdc7c7",
  RED_400: "#fb2e3d",
  RED_500: "#ea1931",
  RED_600: "#b71327",

  GREEN_100: "#f7fbf7",
  GREEN_200: "#e3f1e6",
  GREEN_300: "#baddc1",
  GREEN_400: "#49a55b",
  GREEN_500: "#158a28",
  GREEN_600: "#0c6b1a",

  TEAL_100: "#f4fbff",
  TEAL_200: "#d9f1ff",
  TEAL_300: "#a0dcff",
  TEAL_400: "#65c6ff",
  TEAL_500: "#407da5",
  TEAL_600: "#32607f",

  ORANGE_100: "#fff9f0",
  ORANGE_200: "#feebcc",
  ORANGE_300: "#fdcd7d",
  ORANGE_400: "#fcac27",
  ORANGE_500: "#a56b0b",
  ORANGE_600: "#7f5209",

  BLUEBERRY_100: "#faf9fc",
  BLUEBERRY_200: "#dbcff4",
  BLUEBERRY_300: "#c6b2ed",
  BLUEBERRY_400: "#461d97",

  PURPLE_100: "#fef8fd",
  PURPLE_200: "#f8e9f9",
  PURPLE_300: "#efc8ee",
  PURPLE_400: "#d34dc0",
  PURPLE_500: "#c144ab",
  PURPLE_600: "#973482"
};
