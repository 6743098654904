import { classNames } from "../../../../utils";
import "./flag.css";

type FlagProps = {
  iso2: string;
  className?: string;
};
export const Flag = ({ iso2, className }: FlagProps) => {
  return (
    <div className={classNames("catalystFlagWrapper", className)}>
      <div className={classNames("catalystFlag", iso2)} />
    </div>
  );
};
