import React, { forwardRef, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  Ellipsis,
  TypographyColors,
  TypographyVariants,
  Typography,
  classNames,
  NavbarDropdownMenuTrigger
} from "../../../..";
import styles from "./UserProfile.module.css";

type NavbarUserProfileProps = {
  onClick?: () => void;
  children: React.ReactElement | React.ReactElement[] | React.ReactFragment;
};

type UserProfileInternalProps = {
  expanded: boolean;
};

export const NavbarUserProfile = (props: NavbarUserProfileProps) => {
  const { children, onClick, ...internalProps } = props;
  const { expanded: isExpanded } = internalProps as UserProfileInternalProps;
  const [isActive, setIsActive] = useState(false);

  // Filter out null or undefined children
  const validChildren = React.Children.toArray(children).filter(child =>
    React.isValidElement(child)
  );

  let dropdownTriggerChildren: React.ReactElement | undefined;

  const dropdownContentChildren: React.ReactElement[] = [];

  React.Children.forEach(validChildren, (child: any) => {
    const clonedChild = React.cloneElement(child, {
      expanded: isExpanded,
      isActive
    });
    if (child.type === NavbarDropdownMenuTrigger) {
      dropdownTriggerChildren = clonedChild;
    } else {
      dropdownContentChildren.push(clonedChild);
    }
  });

  const handleOnClick = (open: boolean) => {
    setIsActive(open);
    onClick?.();
  };

  if (!dropdownTriggerChildren) {
    return null;
  }

  return (
    <li>
      <DropdownMenu onOpenChange={handleOnClick}>
        {dropdownTriggerChildren}
        <DropdownMenuContent
          sideOffset={-24}
          alignOffset={32}
          side="right"
          align="end"
          width="12.5rem"
        >
          <>{dropdownContentChildren}</>
        </DropdownMenuContent>
      </DropdownMenu>
    </li>
  );
};

type NavbarUserProfileTriggerProps = {
  name?: string;
  email: string;
  avatar: React.ReactElement;
  onClick?: () => void;
};

type NavbarUserProfileTriggerInternalProps = {
  expanded: boolean;
  isActive: boolean;
  onPointerDown?: (event: React.PointerEvent<HTMLButtonElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
};

export const NavbarUserProfileTrigger = forwardRef<
  HTMLButtonElement,
  NavbarUserProfileTriggerProps
>((props, ref) => {
  const { name, email, avatar, onClick, ...internalProps } = props;

  const {
    isActive,
    expanded: isExpanded,
    onKeyDown,
    onPointerDown
  } = internalProps as NavbarUserProfileTriggerInternalProps;

  const ariaProps: React.AriaAttributes = Object.entries(internalProps)
    .filter(([restPropKey]) => restPropKey.includes("aria-"))
    .reduce((ariaProps, [ariaKey, ariaValue]) => {
      return { ...ariaProps, [ariaKey]: ariaValue };
    }, {});

  const dataProps = Object.entries(internalProps)
    .filter(([restPropKey]) => restPropKey.includes("data-"))
    .reduce((ariaProps, [ariaKey, ariaValue]) => {
      return { ...ariaProps, [ariaKey]: ariaValue };
    }, {});

  return (
    <div className={styles.userProfileTriggerWrapper}>
      <button
        className={classNames({
          [styles.userProfile]: true,
          [styles.userProfileActive]: isActive
        })}
        onClick={onClick}
        {...ariaProps}
        {...dataProps}
        onPointerDown={onPointerDown}
        onKeyDown={onKeyDown}
        ref={ref}
      >
        <div className={styles.userProfileContentWrapper}>
          {isExpanded && (
            <div className={styles.userDetailsWrapper}>
              {name && (
                <Typography
                  color={TypographyColors.NEUTRAL_800}
                  variant={TypographyVariants.TITLE_MD}
                  className={styles.userDetailsOverflowWrapper}
                >
                  <Ellipsis>{name}</Ellipsis>
                </Typography>
              )}
              <Typography
                color={
                  !name
                    ? TypographyColors.NEUTRAL_800
                    : TypographyColors.NEUTRAL_600
                }
                variant={
                  !name
                    ? TypographyVariants.TITLE_MD
                    : TypographyVariants.BODY_BOLD
                }
                className={styles.userDetailsOverflowWrapper}
              >
                <Ellipsis>{email}</Ellipsis>
              </Typography>
            </div>
          )}
          <div className={styles.avatarWrapper}>{avatar}</div>
        </div>
      </button>
    </div>
  );
});
