// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Boolean = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Boolean"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.752 0L3.246 4.6 1.082 2.892 0 4.323l2.78 2.193.616.484L9 1.278 7.752 0zm3.408 12.496L9 10.327 10.327 9l2.169 2.16L14.664 9 16 10.327l-2.169 2.169L16 14.664 14.664 16l-2.168-2.169L10.327 16 9 14.664l2.16-2.168zm-9.223.256a.75.75 0 001.061 1.06L13.875 2.937a.75.75 0 10-1.06-1.061L1.937 12.752z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Boolean;
