import { useLayoutEffect, useMemo } from "react";
import { updateCSS, removeCSS } from "../utils/dom/dynamicCSS";
import { nanoid } from "nanoid";
import { isBodyOverflowing } from "../utils/dom/isBodyOverflowing";
import { getTargetScrollBarSize } from "../utils/dom/getScrollBarSize";

const UNIQUE_ID = `catalyst-util-locker-${Date.now()}`;

/**
 * Custom React hook to lock or unlock scrolling for the document body.
 *
 * The function takes an optional 'lock' parameter that, when truthy, locks the scrolling of the document body.
 * When 'lock' is falsy, it unlocks the scrolling.
 *
 * @function
 * @param {boolean} [lock] - Whether to lock or unlock scrolling. If truthy, scrolling is locked; if falsy, scrolling is unlocked.
 */
export function useScrollLocker(lock?: boolean) {
  const isMergedLock = !!lock;
  const styleId = useMemo(() => {
    const uuid = nanoid();
    return `${UNIQUE_ID}_${uuid}`;
  }, []);

  useLayoutEffect(() => {
    if (isMergedLock) {
      const scrollbarSize = getTargetScrollBarSize(document.body).width;
      const isOverflow = isBodyOverflowing();

      // react-select also adds a padding-right to the body, when it opens
      // so in case select component is inside a dialog (which uses scroll lock)
      // its padding right causes ui flickering
      // alternate would have to just use padding-right, but its causing flickering issues in report manage subscription
      // width solution is more robust and not causing any weird flicering issues, like
      // in case modal has sub modals, dropdowns, thats why chosen this way

      updateCSS(
        `html body {
          overflow-y: hidden;
          ${isOverflow ? `width: calc(100% - ${scrollbarSize}px);` : ""}
          padding-right: 0 !important;
        }`,
        styleId
      );
    } else {
      removeCSS(styleId);
    }

    return () => {
      removeCSS(styleId);
    };
  }, [isMergedLock, styleId]);
}
