import React, { forwardRef } from "react";

import { classNames } from "../../utils";
import { getTransformedPaddingWithCSSVariables, type Padding } from "../utils";

import type { Gap } from "../types";
import type { CatalystColors } from "../../constants";

import styles from "./Grid.module.css";

type GridProps = {
  /**
   * The tag of the grid
   * @default "div"
   **/
  as?: "div";
  /**
   * A custom class name to apply to the grid.
   * Use this with caution, might override the default properties of the grid.
   **/
  dangerouslySetClassName?: string;
  /**
   * The padding of the grid
   **/
  padding?: Padding;
  /**
   * The children of the grid
   **/
  children: React.ReactNode;
  /**
   * The justify content of the grid based on main axis
   **/
  justify?: React.CSSProperties["justifyItems"];
  /**
   * The align items of the grid based on cross axis
   **/
  align?: React.CSSProperties["alignItems"];
  /**
   * Gap between the rows
   */
  rowGap?: Gap;
  /**
   * Gap between the columns
   */
  columnGap?: Gap;
  /**
   * The number of rows in the grid
   */
  rows?: number;
  /**
   * The background color of the grid
   **/
  backgroundColor?: CatalystColors;
} & GridColumnsProps;

type GridColumnsProps =
  | {
      /**
       * The number of columns in the grid
       */
      columns?: number;
      /**
       * The minimum length of the grid column
       * The length value representing x in minmax(min(x, 100%), 1fr)
       * This is not considered when columns are provided
       */
      minColWidth: `${string}rem` | `${string}%`;
    }
  | {
      columns: number;
      minColWidth?: `${string}rem` | `${string}%`;
    };

export const Grid = forwardRef<HTMLElement, GridProps>((props, ref) => {
  const {
    as = "div",
    children,
    justify,
    align,
    rowGap,
    columnGap,
    rows,
    columns,
    minColWidth,
    padding,
    backgroundColor,
    dangerouslySetClassName = ""
  } = props;

  return (
    <>
      {React.createElement(as, {
        children,
        ref,
        style: {
          rowGap: rowGap ? `var(--space-${rowGap})` : undefined,
          columnGap: columnGap ? `var(--space-${columnGap})` : undefined,
          gridTemplateRows: rows ? `repeat(${rows}, auto)` : undefined,
          gridTemplateColumns: columns
            ? `repeat(${columns},  1fr)`
            : `repeat(auto-fit, minmax(min(${minColWidth}, 100%), 1fr))`,
          justifyItems: justify,
          alignItems: align,
          padding: getTransformedPaddingWithCSSVariables(padding),
          backgroundColor
        },
        className: classNames({
          [styles.catalystGridContainer]: true,
          [dangerouslySetClassName]: !!dangerouslySetClassName
        })
      })}
    </>
  );
});
