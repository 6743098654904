// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Activity = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Activity"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M7.154 12.554c.22.615 1.196.587 1.372-.04l1.587-5.651.927 2.365c.102.257.372.43.674.43h2.572c.394 0 .714-.29.714-.644 0-.354-.32-.643-.714-.643H12.22L10.674 4.43c-.235-.6-1.193-.56-1.366.056L7.75 10.03 6.394 6.246c-.205-.574-1.095-.601-1.343-.042L4.088 8.37H1.714c-.394 0-.714.289-.714.643 0 .355.32.643.714.643h2.857c.293 0 .555-.16.663-.404l.408-.917 1.512 4.218z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Activity;
