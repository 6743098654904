// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Check = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Check"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12.336 3L6.328 9.573 3.443 7.13 2 9.175l3.707 3.134.821.691L14 4.826 12.336 3z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Check;
