import type { RegisterOptions } from "../../";
import type { TextInputProps } from "../../../Inputs/Text";
import { TextInput as PrimitiveTextInput } from "../../../Inputs/Text";

import { FieldStatus } from "../../../types";

import { ControllerFormField } from "../Controller";

type TextInputFormFieldProps = (Omit<TextInputProps, "status" | "label"> & {
  name: string;
  rules?: Omit<
    RegisterOptions,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}) &
  (
    | {
        label?: string;
        ariaLabel: string;
      }
    | { label: string; ariaLabel?: string }
  );

export const TextInputFormField = (props: TextInputFormFieldProps) => {
  const {
    label,
    name,
    ariaLabel,
    rules,
    required: isRequired,
    ...restProps
  } = props;

  return (
    <ControllerFormField
      label={label}
      name={name}
      rules={rules}
      required={isRequired}
      render={field => {
        return (
          <PrimitiveTextInput
            {...restProps}
            required={isRequired}
            status={!!field.error ? FieldStatus.ERROR : undefined}
            label={(ariaLabel || label) as string}
            {...field}
          />
        );
      }}
    />
  );
};
