// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Number = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Number"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M3.164 4v8.904H1.162V5.47H0V4h3.164zM6.119 5.82v1.176H4.117V5.54C4.117 4.513 4.705 4 5.88 4h1.512c1.195 0 1.792.513 1.792 1.54v1.134c0 .43-.065.803-.196 1.12-.121.308-.322.649-.602 1.022l-1.792 2.618h2.618v1.47H4.159v-1.358l2.464-3.304c.233-.29.387-.546.462-.77.084-.224.126-.5.126-.826V5.82c0-.233-.126-.35-.378-.35h-.35c-.243 0-.364.117-.364.35zM11.957 5.82v1.05H9.97V5.54c0-1.027.588-1.54 1.764-1.54h1.554c1.186 0 1.778.513 1.778 1.54v1.526c0 .69-.21 1.125-.63 1.302.42.159.63.56.63 1.204v1.778c0 1.036-.592 1.554-1.778 1.554h-1.554c-1.176 0-1.764-.518-1.764-1.554v-1.302h1.988v1.036c0 .233.126.35.378.35h.378c.252 0 .378-.117.378-.35V9.432c0-.13-.028-.22-.084-.266-.056-.056-.154-.084-.294-.084h-1.092V7.696h1.092c.14 0 .238-.023.294-.07.056-.056.084-.145.084-.266V5.82c0-.233-.126-.35-.378-.35h-.378c-.252 0-.378.117-.378.35z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Number;
