import type { FC } from "react";
import React from "react";
import styled from "@emotion/styled";
import { Stack } from "../Stack";
import { Text } from "../Typography";
import { Arrow } from "@certa/icons";
import { mapTypeToColor } from "./utils";
import type { NotificationType, NotificationProps } from "./types";

type CustomMessageProps = {
  message: NotificationProps["message"];
  description?: NotificationProps["description"];
  type: NotificationType;
  status?: NotificationProps["status"];
};

export const CustomMessage: FC<CustomMessageProps> = props => {
  const { message, description, type, status } = props;

  return (
    <Text
      variant={description ? "h3-bold" : "h3-medium"}
      color={description && !status ? mapTypeToColor[type] : "neutral-100"}
    >
      {/* // TODO: Find an better way to add right padding to message */}
      <Stack
        inline
        align="baseline"
        gutter="s00 s2 s00 s00"
        style={{
          display: "inline"
        }}
      >
        {message}
      </Stack>
      {status && (
        <Stack
          inline
          align="center"
          style={{
            verticalAlign: "middle"
          }}
        >
          <Stack inline align="center" gutter="s00 s2 s00 s00">
            <Arrow color="neutral-50" />
          </Stack>
          <Text variant="h3-regular" color={mapTypeToColor[type]}>
            {status}
          </Text>
        </Stack>
      )}
    </Text>
  );
};

export const Message = styled(CustomMessage)`
  --label: NotificationMessage;
`;
