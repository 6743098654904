// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Date = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Date"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 .75a.75.75 0 011.5 0V2h5V.75a.75.75 0 011.5 0V2h1a3 3 0 013 3v8a3 3 0 01-3 3H3a3 3 0 01-3-3V5a3 3 0 013-3h1V.75zm6.5 2.75v.75a.75.75 0 001.5 0V3.5h1A1.5 1.5 0 0114.5 5v1.5h-13V5A1.5 1.5 0 013 3.5h1v.75a.75.75 0 001.5 0V3.5h5zM1.5 8v5A1.5 1.5 0 003 14.5h10a1.5 1.5 0 001.5-1.5V8h-13z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Date;
