/**
 * Custom error
 * Show compile time error when anything other than `never` is passed
 * Use Case: Check for never type
 *
 */
class UnreachableError extends Error {
  constructor(val: never, message: string) {
    super(`Unreachable code reached: ${message}`);
  }
}

enum HttpStatus {
  NOT_FOUND = 404,
  BAD_REQUEST = 400
}

export { UnreachableError, HttpStatus };
