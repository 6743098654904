import type { ButtonProps } from ".";
import { css } from "@emotion/core";
import { getVariantStyles } from "../Typography/utils";

export const getButtonSizeStyles = (size: ButtonProps["size"]) => {
  switch (size) {
    case "small":
      return css`
        height: var(--s6);
        padding: var(--s00) var(--s2);
      `;
    case "large":
    default:
      return css`
        height: var(--s8);
        padding: var(--s00) var(--s3);
      `;
  }
};

export const getButtonFontStyles = (
  size: ButtonProps["size"],
  type: ButtonProps["type"]
) => {
  switch (type) {
    case "primary":
      switch (size) {
        case "small":
          return getVariantStyles("p1-medium");
        case "large":
        default:
          return getVariantStyles("h3-bold");
      }
    case "ghost":
      switch (size) {
        case "small":
          return getVariantStyles("p1-medium");
        case "large":
        default:
          return getVariantStyles("h3-bold");
      }
    case "link":
      switch (size) {
        case "small":
          return getVariantStyles("p1-medium");
        case "large":
        default:
          return getVariantStyles("h3-medium");
      }
    case "default":
    default:
      switch (size) {
        case "small":
          return getVariantStyles("p1-medium");
        case "large":
        default:
          return getVariantStyles("h3-medium");
      }
  }
};

export const getButtonIconStyles = (size: ButtonProps["size"]) => {
  switch (size) {
    case "small":
      return css`
        font-size: var(--p1-regular-font-size);
      `;
    case "large":
    default:
      return css`
        font-size: var(--h2-regular-font-size);
      `;
  }
};

export const getButtonDisclosureStyles = (size: ButtonProps["size"]) => {
  switch (size) {
    case "small":
      return css`
        margin-left: var(--s1);
      `;
    case "large":
    default:
      return css`
        margin-left: var(--s2);
      `;
  }
};
