// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Chart = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Chart"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_1_57)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 0a2 2 0 00-2 2v12a2 2 0 104 0V2a2 2 0 00-2-2zM6 8a2 2 0 114 0v6a2 2 0 11-4 0V8zm-6 3a2 2 0 114 0v3a2 2 0 11-4 0v-3z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_1_57">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Chart;
