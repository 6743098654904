// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Trashcan = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Trashcan"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_103_16)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.84 5.333l-.457 9.147A1.6 1.6 0 0111.787 16H4.213a1.6 1.6 0 01-1.597-1.52L2.16 5.332h-.026a1.6 1.6 0 010-3.2h2.79l.254-.948A1.6 1.6 0 016.724 0H9.3a1.6 1.6 0 011.553 1.212l.23.921h2.784a1.6 1.6 0 010 3.2h-.027zM6.725 1.067a.534.534 0 00-.515.394l-.182.672h3.957l-.166-.662a.534.534 0 00-.518-.404H6.724zM2.134 3.2a.533.533 0 100 1.067h11.733a.533.533 0 100-1.067H2.133zm1.093 2.133l.453 9.094a.533.533 0 00.533.506h7.574a.533.533 0 00.533-.506l.454-9.094H3.227zm7.08 2.271a.533.533 0 01.893.423l-.213 4.266a.534.534 0 11-1.067-.053l.213-4.267a.533.533 0 01.175-.369zm-5-.137a.533.533 0 00-.507.56l.213 4.266a.533.533 0 101.067-.053l-.213-4.267a.536.536 0 00-.56-.506zM7.467 8a.534.534 0 011.067 0v4.267a.533.533 0 11-1.067 0V8z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_103_16">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Trashcan;
