// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Spinner = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Spinner"
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="currentColor"
          {...props}
        >
          <path d="M9.095 14.914c.086.545-.286 1.064-.838 1.082A8 8 0 1114.62 3.508c.31.457.11 1.063-.383 1.314-.492.25-1.088.049-1.417-.395A6.001 6.001 0 107.942 14c.553.005 1.067.368 1.153.914z">
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 8 8"
              to="360 8 8"
              dur="0.8s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Spinner;
