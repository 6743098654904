import { canUseDom } from "./canUseDom";
import { contains } from "./contains";

const APPEND_ORDER = "data-catalyst-order";
const MARK_KEY = `catalyst-util-key`;

const containerCache = new Map<ContainerType, Node & ParentNode>();

export type ContainerType = Element | ShadowRoot;
export type Prepend = boolean | "queue";
export type AppendType = "prependQueue" | "append" | "prepend";

type Options = {
  attachTo?: ContainerType;
  csp?: { nonce?: string };
  prepend?: Prepend;
  mark?: string;
};

/**
 * Returns the data attribute mark to be used for custom data attributes.
 *
 * @function
 * @param {Options} [options] - Optional configuration options.
 * @param {string} [options.mark] - The data attribute mark to use. If not provided or falsy, the default mark will be used.
 * @returns {string} The data attribute mark to be used for custom data attributes.
 */
function getMark({ mark }: Options = {}) {
  if (mark) {
    return mark.startsWith("data-") ? mark : `data-${mark}`;
  }
  return MARK_KEY;
}

/**
 * Returns the container element where the dynamically injected styles should be appended to.
 *
 * If the `attachTo` option is provided, it returns the element specified by `attachTo`.
 * Otherwise, it looks for the 'head' element in the document, and if not found, returns the 'body' element.
 *
 * @function
 * @param {Options} option - Configuration options.
 * @param {Element} [option.attachTo] - The specific element to which the styles should be appended.
 * @returns {Element} The container element where the styles should be appended.
 */
function getContainer(option: Options) {
  if (option.attachTo) {
    return option.attachTo;
  }

  const head = document.querySelector("head");
  return head || document.body;
}

/**
 * Determines the type of append operation for injecting styles based on the 'prepend' option.
 *
 * If 'prepend' is set to "queue", it returns "prependQueue".
 * If 'prepend' is truthy but not "queue", it returns "prepend".
 * If 'prepend' is falsy, it returns "append".
 *
 * @function
 * @param {Prepend} [prepend] - The 'prepend' option that determines the type of append operation.
 * @returns {AppendType} The type of append operation: "append", "prepend", or "prependQueue".
 */
function getOrder(prepend?: Prepend): AppendType {
  if (prepend === "queue") {
    return "prependQueue";
  }

  return prepend ? "prepend" : "append";
}

/**
 * Find style which inject by rc-util
 */
function findStyles(container: ContainerType) {
  return Array.from(
    (containerCache.get(container) || container).children
  ).filter(node => node.tagName === "STYLE") as HTMLStyleElement[];
}

/**
 * Injects custom CSS styles into the DOM dynamically.
 *
 * @function
 * @param {string} css - The CSS styles to be injected.
 * @param {Options} [option] - Optional configuration options.
 * @param {Object} [option.csp] - Content Security Policy (CSP) configuration.
 * @param {string} [option.csp.nonce] - Nonce value to be applied for the inline styles in CSP-enabled environments.
 * @param {string} [option.prepend] - Determines whether to prepend the styles. If "queue" is provided,
 * it will prepend first style and then append rest style.
 * @returns {HTMLStyleElement | null} The created <style> element if the DOM is accessible and styles were injected; otherwise, returns 'null'.
 */
export function injectCSS(
  css: string,
  option: Options = {}
): HTMLStyleElement | null {
  if (!canUseDom()) {
    return null;
  }

  const { csp, prepend } = option;

  const styleNode = document.createElement("style");
  styleNode.setAttribute(APPEND_ORDER, getOrder(prepend));

  if (csp?.nonce) {
    styleNode.nonce = csp?.nonce;
  }
  styleNode.innerHTML = css || ""; // Provide a default value for css

  const container = getContainer(option);
  const { firstChild } = container;

  if (prepend) {
    // If is queue `prepend`, it will prepend first style and then append rest style
    if (prepend === "queue") {
      const existStyle = findStyles(container).filter(node =>
        ["prepend", "prependQueue"].includes(node.getAttribute(APPEND_ORDER)!)
      );
      if (existStyle.length) {
        container.insertBefore(
          styleNode,
          existStyle[existStyle.length - 1].nextSibling
        );

        return styleNode;
      }
    }

    // Use `insertBefore` as `prepend`
    container.insertBefore(styleNode, firstChild);
  } else {
    container.appendChild(styleNode);
  }

  return styleNode;
}

/**
 * Finds an existing style node in the specified container with a matching data attribute key.
 *
 * It searches for style nodes in the container with a data attribute key that matches the provided 'key'.
 * The data attribute mark used for searching is determined based on the provided 'option'.
 *
 * @function
 * @param {string} key - The data attribute key to match when searching for an existing style node.
 * @param {Options} [option] - Optional configuration options.
 * @param {Element} [option.attachTo] - The specific element to which the styles should be appended.
 * @param {string} [option.mark] - The data attribute mark to use. If not provided or falsy, the default mark will be used.
 * @returns {Element | undefined} The first style node found in the container with a matching data attribute key,
 * or 'undefined' if no such node is found.
 */
function findExistNode(key: string, option: Options = {}) {
  const container = getContainer(option);

  return findStyles(container).find(
    node => node.getAttribute(getMark(option)) === key
  );
}

/**
 * Removes an existing style node from the specified container with a matching data attribute key.
 *
 * It searches for an existing style node in the container with a data attribute key that matches the provided 'key'.
 * If such a node is found, it will be removed from the container.
 *
 * @function
 * @param {string} key - The data attribute key to match when searching for the style node to remove.
 * @param {Options} [option] - Optional configuration options.
 * @param {Element} [option.attachTo] - The specific element to which the styles were appended.
 * @param {string} [option.mark] - The data attribute mark to use. If not provided or falsy, the default mark will be used.
 */
export function removeCSS(key: string, option: Options = {}) {
  const existNode = findExistNode(key, option);
  if (existNode) {
    const container = getContainer(option);
    container.removeChild(existNode);
  }
}

/**
 * Synchronizes the real container element with the provided container and options.
 *
 * The function checks whether the cached real container is present and still attached to the document.
 * If the real container is not cached or has been removed from the document, it finds the real container,
 * assigns it to the container cache, and removes any temporary placeholder style element that was used for the search.
 *
 * @function
 * @param {ContainerType} container - The container to be synchronized with the real container.
 * @param {Options} option - Configuration options.
 * @param {Element} [option.attachTo] - The specific element to which the styles should be appended.
 * @param {string} [option.mark] - The data attribute mark to use. If not provided or falsy, the default mark will be used.
 */
function syncRealContainer(container: ContainerType, option: Options) {
  const cachedRealContainer = containerCache.get(container);

  // Find real container when not cached or cached container removed
  if (!cachedRealContainer || !contains(document, cachedRealContainer)) {
    const placeholderStyle = injectCSS("", option);
    if (!placeholderStyle) return;
    const { parentNode } = placeholderStyle;
    if (parentNode) {
      containerCache.set(container, parentNode);
    }
    container.removeChild(placeholderStyle);
  }
}

/**
 * manually clear container cache to avoid global cache in unit tests
 */
export function clearContainerCache() {
  containerCache.clear();
}
/**
 * Updates the CSS styles associated with the provided key in the specified container.
 *
 * If an existing style node with a matching data attribute key is found in the container,
 * it updates its content and, if applicable, the nonce value based on the provided options.
 * If no existing style node is found, it injects a new style node with the provided CSS styles.
 *
 * @function
 * @param {string | null} css - The CSS styles to update. If null, the styles will be removed.
 * @param {string} key - The data attribute key associated with the CSS styles.
 * @param {Options} [option] - Optional configuration options.
 * @param {Element} [option.attachTo] - The specific element to which the styles should be appended.
 * @param {string} [option.mark] - The data attribute mark to use. If not provided or falsy, the default mark will be used.
 * @param {Object} [option.csp] - Content Security Policy (CSP) configuration.
 * @param {string} [option.csp.nonce] - Nonce value to be applied for the inline styles in CSP-enabled environments.
 * @returns {HTMLStyleElement | null} The updated or newly injected <style> element, or 'null' if the DOM is not accessible.
 */
export function updateCSS(
  css: string | null,
  key: string,
  option: Options = {}
) {
  const container = getContainer(option);

  // Sync real parent
  syncRealContainer(container, option);

  const existNode = findExistNode(key, option);

  if (existNode) {
    if (option.csp?.nonce && existNode.nonce !== option.csp?.nonce) {
      existNode.nonce = option.csp?.nonce;
    }

    if (existNode.innerHTML !== (css || "")) {
      // Provide a default value for css
      existNode.innerHTML = css || "";
    }

    return existNode;
  }

  const newNode = injectCSS(css || "", option); // Provide a default value for css
  newNode?.setAttribute(getMark(option), key);
  return newNode;
}
