// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Text = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Text"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_491_1818)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.931 2.655h-3.31v10.483h2.207v1.655h-6.07v-1.655h2.208V2.655h-3.31V4.31H0V1.828C0 1.37.37 1 .828 1h9.93c.458 0 .828.37.828.828V4.31H9.931V2.655zm3.31 7.724v2.155c0 .334.27.604.605.604H16v1.655h-2.154a2.26 2.26 0 01-2.26-2.26V10.38H9.38V8.724h2.207V6.517h1.655v2.207h2.483v1.655h-2.483z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_491_1818">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Text;
