import { useRef, useLayoutEffect } from "react";

type AriaLiveProps = {
  message?: string;
};

/**
 * React component for managing an ARIA live node.
 *
 * This component sets up an ARIA live region that announces a message to screen readers
 * when provided. It ensures compatibility with Firefox by injecting the message
 * via appending it to the DOM.
 *
 * @param {AriaLiveProps} props - Props for the component.
 * @param {string} props.message - The message to be announced by screen readers.
 *
 * @returns {JSX.Element} A JSX element representing the ARIA live node.
 */
export const AriaLive = ({ message }: AriaLiveProps): JSX.Element => {
  const nodeRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    if (nodeRef.current) {
      // Clear previous content
      nodeRef.current.innerHTML = "";

      // If a message is provided, append it to the element
      if (message) {
        nodeRef.current.append(message);
      }
    }
  }, [message]);

  if (!message) return <></>;

  return (
    <span
      ref={nodeRef}
      aria-live="polite"
      aria-atomic="true"
      className="catalyst-screen-reader-only"
    />
  );
};
