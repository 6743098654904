// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const DelayedClock = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="DelayedClock"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.333 3.011A6 6 0 008 2v1.43A4.57 4.57 0 113.43 8H2a6 6 0 109.333-4.989zm-3.14 5.244l1.727.758a.568.568 0 01.285.782.617.617 0 01-.546.348.537.537 0 01-.236-.05l-2.075-.906A.603.603 0 017 8.64V5.596C7 5.261 7.261 5 7.596 5c.336 0 .597.273.597.596v2.659zM5.371 2.606a6 6 0 00-1.225.796l.918 1.095a4.57 4.57 0 012.024-.975L6.803 2.12a6 6 0 00-1.385.463l-.047.022zM2.108 6.865v-.001a6 6 0 01.45-1.39l.021-.046a6 6 0 01.782-1.234l1.106.907a4.57 4.57 0 00-.955 2.034l-1.404-.27z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default DelayedClock;
