// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Messages = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Messages"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_4_186)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M.846 15.81c.52.279 1.151.249 1.642-.08l2.797-1.866h8.049a2.666 2.666 0 002.667-2.664V2.667A2.667 2.667 0 0013.334 0H2.667A2.667 2.667 0 000 2.667V14.4c0 .591.325 1.133.846 1.41zm.754-3.543v-9.6c0-.59.477-1.067 1.067-1.067h10.667A1.066 1.066 0 0114.4 2.667V11.2a1.066 1.066 0 01-1.067 1.067H4.8L1.6 14.4v-2.133zM4 4h8v1.6H4V4zm8 4.267H4v1.6h8v-1.6z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_4_186">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Messages;
