import React from "react";

import ReactFocusLock from "react-focus-lock";

type FocusLockProps = {
  children: React.ReactNode;
  disabled?: boolean;
  returnFocus?: boolean;
  persistentFocus?: boolean;
};

export const FocusLock = ({
  children,
  disabled,
  returnFocus = true,
  persistentFocus = false
}: FocusLockProps) => {
  if (disabled) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <ReactFocusLock returnFocus={returnFocus} persistentFocus={persistentFocus}>
      {children}
    </ReactFocusLock>
  );
};
