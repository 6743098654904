import moment from "moment";

export const gmtDate = (value: any) => {
  const day = moment(value).format("dddd");
  const date = moment(value).format("MMM Do YYYY");
  const time = moment(value).format("LTS");
  const offset = moment(value).format("Z");
  const gmt = `${day}, ${date}, ${time} (GMT${offset})`;
  return gmt;
};

/**
 * Overridden moment's fromNow, with floor rounding for more precision
 * 8.2 days => 8 days; 8.9 days => 8 days
 * @param {Date|Number (timestamp)|date-string(Date.parse)|Moment} timestamp
 * @returns {string} Relative human readable time string
 */
export const fromNowWithFloorRounding = (timestamp: any) => {
  const time = moment(timestamp);
  const roundingDefault = moment.relativeTimeRounding();

  // Round relative time evaluation down
  moment.relativeTimeRounding(Math.floor);

  // Updating thresholds
  moment.relativeTimeThreshold("s", 60);
  moment.relativeTimeThreshold("m", 60);
  moment.relativeTimeThreshold("h", 24);
  moment.relativeTimeThreshold("d", 31);
  moment.relativeTimeThreshold("M", 12);

  const relatimeTime = time.fromNow();

  // back to default
  moment.relativeTimeRounding(roundingDefault);
  return relatimeTime;
};

export const ABSOLUTE_DATE_TIME_FORMAT = "MMMM D YYYY, hh:mm a";
