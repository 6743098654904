// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const ZoomToSelection = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ZoomToSelection"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_7183_4477)">
            <rect
              x={3.75}
              y={3.75}
              width={8.5}
              height={8.5}
              rx={1.25}
              stroke="currentColor"
              strokeWidth={1.5}
            />
            <path
              opacity={0.5}
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.5 1.5h-4v4H0V0h5.5v1.5zm0 13h-4v-4H0V16h5.5v-1.5zm9 0h-4V16H16v-5.5h-1.5v4zm-4-13h4v4H16V0h-5.5v1.5z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_7183_4477">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default ZoomToSelection;
