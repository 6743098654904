// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Edit = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Edit"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_128_64)" fill="currentColor">
            <path d="M11.65 0c.217 0 .435.084.598.25l.001.002 2.508 2.58.005.006a.892.892 0 01-.006 1.219l-8.752 8.912a.842.842 0 01-.596.25l-2.532.014h-.002a.84.84 0 01-.595-.251.881.881 0 01-.246-.62l.013-2.593c0-.241.106-.443.233-.591l.014-.016 7-7.115 1.75-1.795a.869.869 0 01.607-.25V0zm.003 2.083l-.56.57 1.33 1.354.56-.57-1.33-1.354zM9.89 3.88l-6.148 6.26-.01 1.35 1.34-.01 6.147-6.246-1.33-1.354z" />
            <rect y={14.5} width={16} height={1.5} rx={0.75} />
          </g>
          <defs>
            <clipPath id="prefix__clip0_128_64">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Edit;
