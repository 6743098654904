/**
 * @deprecated Use FIELD_TYPE instead
 */
export enum FieldTypes {
  EMBEDDED_IN_APP_REPORT = "embedded_in_app_report",
  CHAR = "char_field",
  TEXT = "text",
  DECIMAL = "decimal",
  SELECT = "single_select",
  MULTI_SELECT = "multi_select",
  URL = "url",
  CHECKBOX = "checkbox",
  GROUPED_CHECKBOX = "grouped_checkbox",
  RADIO = "radio",
  BOOL = "bool",
  DATE = "date",
  NAIVE_DATE = "naive_date",
  RELATIVE_DATE = "relative_date",
  FILE = "file",
  MULTI_FILE = "multi_file",
  ATTACHMENT = "attachment",
  CASCADER = "cascader",
  EMAIL = "email",
  INTEGER = "integer",
  PHONE = "phone",
  ALERT = "alert",
  PARAGRAPH = "paragraph",
  BREAK = "break",
  SECTION_BREAK = "section_break",
  DIVIDER = "divider",
  S3 = "s3_object",
  TABLE = "table",
  REGION = "region",
  CURRENCY = "currency",
  USER_WORKFLOW = "user_workflow",
  HTML = "html",
  JSON = "json",
  ARRAY = "array",
  DUNS_SEARCH = "dnb_duns_search",
  DUNS_SEARCH_DIRECT_PLUS = "dnb_duns_search_direct_plus",
  DNB_DIRECTORS = "dnb_directors",
  DNB_RDC = "dnb_rdc",
  DNB_RDC_ALERTS = "dnb_rdc_alerts",
  UBO = "dnb_ubo",
  UBO_GRAPH = "dnb_ubo_graph",
  GOOGLE_SEARCH = "google_search",
  DUPLICATE_CHECK = "duplicate_check",
  AB_DUPLICATE_CHECK = "appbuilder_duplicate_check",
  IFRAME = "iframe",
  DOCUSIGN = "docusign",
  RDC_EVENT_DETAILS = "rdc_event_details",
  RDC_ALERTS_META_DATA = "rdc_alert_metadata",
  GOOGLE_ADDRESS_SEARCH = "google_address_search",
  HERMES = "hermes",
  CHILD_WORFKLOW = "child_workflow",
  LEXIS_NEXIS = "ln_search",
  AMBER_ROAD = "amber_road",
  JUXTAPOSE = "juxtapose",
  WORKDAY = "workday",
  HERMES_DNB_DIVERSITY = "hermes_dnb_diversity",
  DNB_LITIGATION = "dnb_litigation",
  BUSINESS_UNIT = "business_unit",
  DNB_FINANCIALS = "dnb_financials",
  CSI = "csi",
  VISUALIZATION = "visualization",
  DYNAMIC_GROUP_JSON = "dynamic_group_json",
  ESIGNATURE = "e_signature",
  PDF_VIEWER = "pdf_viewer",
  WIZARD_SECTION = "wizard_section",
  SCORM = "scorm",
  SLIDER = "slider",
  INTEGRATION = "integration",
  SYSTEM = "system"
}
