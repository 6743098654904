// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Archive = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Archive"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M15.84 4.68L14.08 1.8c-.32-.48-.8-.8-1.36-.8H3.28c-.56 0-1.04.32-1.36.8L.16 4.68c-.08.16-.16.48-.16.72v8.4c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6V5.4c0-.24-.08-.56-.16-.72zM10.4 8.2H5.6c-.48 0-.8-.32-.8-.8 0-.48.32-.8.8-.8h4.8c.48 0 .8.32.8.8 0 .48-.32.8-.8.8zM1.76 5l1.36-2.4h9.76L14.24 5H1.76z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Archive;
