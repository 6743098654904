export const Tick = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.336 3L6.3282 9.57253L3.44273 7.13014L2 9.17512L5.70688 12.3088L6.52829 13L14 4.82599L12.336 3Z"
        fill="currentColor"
      />
    </svg>
  );
};
