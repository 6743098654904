export const getDashboardsGenericRoute = () => `/dashboards`;
export const getAppListGenericRoute = () => `/appbuilder/apps`;
export const getStudioGenericRoute = () => `/studio/apps`;
export const getInstanceSettingsRoute = () => `/settings`;

export const getAppDetailsRoute = (params?: {
  id: number;
  version?: number;
}) =>
  params
    ? `/appbuilder/apps/${params.id}/${
        params.version ? `${params.version}/` : ""
      }`
    : "/appbuilder/apps/:id/:version?/";

export const getStudioAppDetailsRoute = (params?: {
  id: number;
  branch?: number;
  savepoint?: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch ? `${params.branch}/` : ""}${
        params.branch && params.savepoint ? `${params.savepoint}/` : ""
      }`
    : "/studio/apps/:id/:branch?/:savepoint?/";

export const getStudioAppSettingsRoute = (params?: {
  id: number;
  branch: number;
  savepoint: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/settings/`
    : "/studio/apps/:id/:branch/:savepoint/settings/";

export const getStudioProcessEditorRoute = (params?: {
  id: number;
  branch: number;
  savepoint: number;
  process: number;
  processSavepointId?: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/process/${params.process}/${params?.processSavepointId ?? ""}`
    : "/studio/apps/:id/:branch/:savepoint/process/:process/:processSavepointId?";

export const PROCESS_DESIGNER_SUFFIX = "designer";
export const getStudioProcessDesignerRoute = (params?: {
  id: number;
  branch: number;
  savepoint: number;
  process: number;
  processSavepointId?: number;
}) =>
  params
    ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/designer/${params.process}/${params?.processSavepointId ?? ""}`
    : `/studio/apps/:id/:branch/:savepoint/${PROCESS_DESIGNER_SUFFIX}/:process/:processSavepointId?`;

// params
//   ? `/studio/apps/${params.id}/${params.branch}/${params.savepoint}/process/${params.process}`
//   : "/studio/apps/:id/:branch/:savepoint/process/:process";

export const getAppProcessRoute = (params?: {
  id: number;
  version: number;
  pid: number;
}) =>
  params
    ? `/appbuilder/apps/${params.id}/${params.version}/processes/${params.pid}/`
    : "/appbuilder/apps/:id/:version/processes/:pid/";

export const getTemplatesGenericRoute = () => `/appbuilder/templates`;
export const getPlatformSettingsRoute = () => `/appbuilder/settings`;

export const getDashboardsRoute = (
  dashboardId: number | string = ":dashboardId"
) => `/dashboard/${dashboardId}`;

export const getCreateNewReportRoute = (
  dashboardId: number | ":dashboardId" = ":dashboardId"
) => `/dashboards/${dashboardId}/report/new`;

export const getCreateNewReportRouteWithQueryParams = (
  dashboardId: number | ":dashboardId" = ":dashboardId",
  query: string
) => `/dashboards/${dashboardId}/report/new${query}`;

export const getOldEditReportRoute = (
  dashboardId: number | ":dashboardId" = ":dashboardId",
  reportId: number | ":reportId" = ":reportId"
) => `/dashboards/${dashboardId}/report/${reportId}/edit`;

export const getEditReportRoute = (
  reportId: number | ":reportId" = ":reportId"
) => `/report/${reportId}/edit`;

export const getOldFullScreenReportRoute = () =>
  `/dashboards/:dashboardId/report/:reportId`;

export const getFullScreenReportRoute = (
  reportId: number | ":reportId" = ":reportId"
) => `/report/${reportId}`;

export const getTasksRoute = () => `/tasks`;

export const getProcessDetailRoute = (id: number | string = ":id") =>
  `/process/${id}`;

export const WIZARD_ROUTE_SLUG = "wizard";
export const getProcessWizardRoute = (id: number | string = ":id") =>
  `/process/${id}/${WIZARD_ROUTE_SLUG}/`;

export const getHomeRoute = () => `/home`;

export const getTaskRoute = ({
  processId,
  taskLaneId,
  taskId
}: {
  processId: number;
  taskLaneId?: number;
  taskId: number;
}) =>
  `${getProcessDetailRoute(processId)}?${
    taskLaneId ? `group=${taskLaneId}` : ""
  }&step=${taskId}`;

export const getSearchRoute = () => `/search`;

export const getRouteForProcessDetail = (processId: number) =>
  window.location.pathname.includes(`/workflows/instances/`)
    ? `/workflows/instances/${processId}`
    : getProcessDetailRoute(processId);

/**
 * @deprecated This feature is no longer in use
 */
export const canSeeCERTA2 = () => {
  const certa2routes = [
    "/process",
    "/tasks",
    "/dashboard",
    "/dashboards",
    "/report",
    "/search",
    "/appbuilder",
    "/home",
    "/studio",
    "/apps"
  ];

  return certa2routes.some(route => window.location.pathname.includes(route));
};

export const getIsWizardView = () =>
  window.location.pathname.includes("/wizard");

/**
 *  Persists the `nextUrl` as `ssoRedirectUrl`
 *  in sessionStorage before we enter sso flow
 *  TODO: Revert this change once BE supports redirect_uri, Ref: VET-46317
 */
export const persistNextUrlOnSSO = () => {
  const params = new URLSearchParams(window.location.search);
  const ssoRedirectUrl = params.get("next");
  if (ssoRedirectUrl) {
    sessionStorage.setItem("ssoRedirectUrl", `${ssoRedirectUrl}`);
  }
};

export const getWorkflowIdFromPathname = () => {
  return window.location.pathname
    .match(/\/process\/\d+/)?.[0]
    ?.match(/\d+(?!process)/)?.[0];
};

export const getDashboardIdFromPathname = () => {
  // if url is in pattern "/dashboard/:dashboardId",
  // match returns an array of ["/dashboard/:dashboardId", dashboardId]
  // The func returns 1st index of the array as dashboardId
  return window.location.pathname.match(/^\/dashboard\/(\d+)/)?.[1];
};

export const TABLEAU_PATH = "/reports/tableau";
export const SERVICE_UNAVAILABLE_PATH = "/service-unavailable/";
export const USER_INTERACTION_REQUIRED_PATH = "/storage-access-flow";
