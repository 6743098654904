import type { RegisterOptions } from "../../";
import { useFormContext } from "../../";
import type { SelectProps } from "../../../Select";
import { Select as PrimitiveSelect } from "../../../Select";

import { FieldStatus } from "../../../types";
import type { ControllerFormFieldRenderProps } from "../Controller";
import { ControllerFormField } from "../Controller";

type SelectFormFieldProps<T extends boolean> = Omit<
  SelectProps<T>,
  "status"
> & {
  name: string;
  rules?: T extends true
    ? Pick<RegisterOptions, "validate">
    : Omit<
        RegisterOptions,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >;
};

// TODO: Add custom validations (rules) for multi select field.
// Reason: The multi select returns an array of values, so we need to validate
// based on the values in the array.

export const SelectFormField = <T extends boolean = false>(
  props: SelectFormFieldProps<T>
) => {
  const { label, name, rules, required: isRequired, ...restProps } = props;

  const { clearErrors } = useFormContext();

  const handleOnChange = (
    value: string | string[],
    actionMeta: any,
    originalOption: any,
    field: ControllerFormFieldRenderProps
  ) => {
    // Manual clearErrors is added because react-hook-form doesn't
    // remove the error even after the field value is correctly inputted by
    // the user. This seems to be an compatibility issue with react-select
    // and react-hook-form. This happens only for multi select fields.
    clearErrors(name);
    field.onChange(value);
    restProps.onChange?.(value as any, actionMeta, originalOption);
  };

  return (
    <ControllerFormField
      label={label}
      name={name}
      rules={rules}
      required={isRequired}
      render={field => {
        return (
          <PrimitiveSelect
            {...restProps}
            required={isRequired}
            status={!!field.error ? FieldStatus.ERROR : undefined}
            label={label}
            {...field}
            onChange={(value, actionMeta, originalOption) =>
              handleOnChange(value, actionMeta, originalOption, field)
            }
          />
        );
      }}
    />
  );
};
