// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const ChevronUp = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronUp"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M6.533 5.607L2.387 9.754a1.333 1.333 0 00.153 2.012 1.365 1.365 0 001.764-.167L8 7.901l3.734 3.734a1.332 1.332 0 002.014-.155 1.364 1.364 0 00-.167-1.762L9.468 5.607a2.079 2.079 0 00-2.935 0z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default ChevronUp;
