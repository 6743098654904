import { TooltipVariants, Tooltip } from "../../../Tooltip";
import type { SliderMarkProps } from "../../Slider.types";
import { getMarkLabelAndStyle } from "../../Slider.utils";
import { useNodePositionInRange } from "../../hooks/useNodePositionInRange";
import styles from "./SliderMark.module.css";

export function SliderMark({ value, min, max, mark }: SliderMarkProps) {
  const { left: markLeft, nodeRef } = useNodePositionInRange({
    min,
    max,
    value: value
  });
  const { label, style } = getMarkLabelAndStyle(mark);

  return (
    <div
      className={styles.catalystSliderMarkWrapper}
      style={{ left: markLeft }}
      ref={nodeRef}
      data-testid="catalyst-slider-mark"
      aria-hidden="true" // In safari, reads these labels on click, that's not needed (https://github.com/Vetted/krypton-ui/pull/7432#issuecomment-1880667828)
    >
      <Tooltip
        variant={TooltipVariants.LIGHT}
        content={<div style={style}>{label}</div>}
      >
        <div className={styles.catalystSliderMark} />
      </Tooltip>
    </div>
  );
}
