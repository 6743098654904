import React from "react";
import { DropdownMenuTrigger } from "../../../..";

type NavbarDropdownMenuTriggerProps = {
  children: React.ReactElement;
};

type NavbarDropdownMenuTriggerInternalProps = {
  expanded: boolean;
  isActive: boolean;
};

export const NavbarDropdownMenuTrigger = (
  props: NavbarDropdownMenuTriggerProps
) => {
  const { children, ...internalProps } = props;

  const { expanded: isExpanded, isActive } =
    internalProps as NavbarDropdownMenuTriggerInternalProps;

  const transformedChildren = React.cloneElement(children, {
    expanded: isExpanded,
    isActive
  });

  return <DropdownMenuTrigger>{transformedChildren}</DropdownMenuTrigger>;
};
