// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Reduce = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Reduce"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_170_1464)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.014 1.067v13.866c0 .59-.48 1.067-1.073 1.067-.48 0-1.073-.477-1.073-1.067V1.067c0-.59.48-1.067 1.073-1.067.48 0 1.072.477 1.072 1.067zM5.72 12.557a1.133 1.133 0 000-1.607L3.911 9.147h7.318a1.137 1.137 0 100-2.272H3.891L5.72 5.05a1.133 1.133 0 000-1.607 1.142 1.142 0 00-1.61 0L.076 7.464a.757.757 0 000 1.073l4.032 4.02a1.141 1.141 0 001.611 0z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_170_1464">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Reduce;
