/**
 * Checks if the body of the document is overflowing both vertically and horizontally.
 *
 * The function compares the document body's scroll height with the viewport's height (window.innerHeight or document.documentElement.clientHeight)
 * and also checks if the viewport's width (window.innerWidth) is greater than the document body's offset width (document.body.offsetWidth).
 * If both conditions are true, it indicates that the body is overflowing both vertically and horizontally.
 *
 * @function
 * @returns {boolean} Returns 'true' if the body of the document is overflowing both vertically and horizontally;
 * otherwise, returns 'false'.
 */
export function isBodyOverflowing() {
  return (
    document.body.scrollHeight >
      (window.innerHeight || document.documentElement.clientHeight) &&
    window.innerWidth > document.body.offsetWidth
  );
}
