import type { ParsedQuery } from "query-string";
import queryString from "query-string";
import { Godaam } from "./Godaam";

const getProcessId = () => {
  const pathName = window.location.pathname;
  if (pathName) {
    const splittedRoute = pathName.split("/");
    /**
     * If url is of the format
     * - /workflows/instances/12 then last array item will be 12
     * - /workflows/instances/12/ then last array item will be "" and second last will be 12
     *
     * So below check will by default set processId to last array item
     * and if in case last array item is not truthy ("") so set the second last item as processId
     */
    let processId = splittedRoute[splittedRoute.length - 1];

    if (!processId) processId = splittedRoute[splittedRoute.length - 2];

    return processId;
  } else {
    return null;
  }
};

export const setParentChildDetail = (childId: string | number) => {
  const searchParams = queryString.parse(window.location.search);

  const processId = getProcessId();

  if (processId && childId) {
    updateInGoodam(childId, processId, searchParams);
  } else {
    console.warn(
      `WorkflowDetail: Unexpected arguments for parentChildHierarchy functions, only recieved for ${childId}`
    );
  }
};

const updateInGoodam = (
  childId: string | number,
  workflowId: string,
  searchParams: ParsedQuery<string>
) => {
  const parentChildHierarchyContext = {
    [childId]: {
      workflowId: workflowId,
      stepId: searchParams.step,
      groupId: searchParams.group
    }
  };

  Godaam.parentChildHierarchyContext = JSON.stringify(
    parentChildHierarchyContext
  );
};
