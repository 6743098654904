import type { Colors } from "../../types";
import type { NotificationType } from "./types";

export const mapTypeToColor: Record<NotificationType, Colors> = {
  success: "green",
  info: "teal",
  warning: "orange",
  error: "red"
};

export const mapTypeToStatus: Record<NotificationType, string> = {
  success: "Success",
  info: "Running",
  warning: "Queued",
  error: "Failed"
};
