let cached: number;

/**
 * Gets the size of the scrollbar in pixels for the current environment.
 *
 * The function creates a hidden div with both vertical and horizontal overflow.
 * It measures the difference in width between the div when the scrollbar is present and when it's hidden.
 * The difference represents the size of the scrollbar.
 *
 * @function
 * @param {boolean} [fresh] - Determines whether to recalculate the scrollbar size even if it's cached.
 * @returns {number} The size of the scrollbar in pixels. Returns 0 if the document object is not available.
 */
export function getScrollBarSize(fresh?: boolean) {
  if (typeof document === "undefined") {
    return 0;
  }

  if (fresh || cached === undefined) {
    const inner = document.createElement("div");
    inner.style.width = "100%";
    inner.style.height = "200px";

    const outer = document.createElement("div");
    const outerStyle = outer.style;

    outerStyle.position = "absolute";
    outerStyle.top = "0";
    outerStyle.left = "0";
    outerStyle.pointerEvents = "none";
    outerStyle.visibility = "hidden";
    outerStyle.width = "200px";
    outerStyle.height = "150px";
    outerStyle.overflow = "hidden";

    outer.appendChild(inner);

    document.body.appendChild(outer);

    const widthContained = inner.offsetWidth;
    outer.style.overflow = "scroll";
    let widthScroll = inner.offsetWidth;

    if (widthContained === widthScroll) {
      widthScroll = outer.clientWidth;
    }

    document.body.removeChild(outer);

    cached = widthContained - widthScroll;
  }
  return cached;
}

/**
 * Ensures the provided string is parsed into a size value in pixels.
 *
 * The function attempts to parse the provided string to extract a numeric value followed by "px".
 * If the parsing is successful, it returns the extracted value as a number representing the size in pixels.
 * If the parsing fails or the input string is not in the expected format, it falls back to the scrollbar size
 * obtained through the 'getScrollBarSize' function.
 *
 * @function
 * @param {string} str - The string containing the size value, typically with "px" as the unit.
 * @returns {number} The size value in pixels, or the scrollbar size if the parsing fails or the input string is not in the expected format.
 */
function ensureSize(str: string) {
  const match = str.match(/^(.*)px$/);
  const value = Number(match?.[1]);
  return Number.isNaN(value) ? getScrollBarSize() : value;
}

/**
 * Gets the size of the scrollbar in pixels for a specific target element.
 *
 * The function checks if the 'document' object is available and whether the provided 'target' element is valid.
 * If the 'document' object is not available or the 'target' element is invalid, it returns { width: 0, height: 0 }.
 * Otherwise, it gets the computed styles of the target element's scrollbar and returns the width and height
 * as objects with keys 'width' and 'height', where the values are the sizes in pixels.
 *
 * @function
 * @param {HTMLElement} target - The target element for which to get the scrollbar size.
 * @returns {{ width: number, height: number }} An object with 'width' and 'height' properties representing the sizes of the scrollbar in pixels.
 * Returns { width: 0, height: 0 } if the 'document' object is not available or the 'target' element is invalid.
 */
export function getTargetScrollBarSize(target: HTMLElement) {
  if (
    typeof document === "undefined" ||
    !target ||
    !(target instanceof Element)
  ) {
    return { width: 0, height: 0 };
  }

  const { width, height } = getComputedStyle(target, "::-webkit-scrollbar");
  return {
    width: ensureSize(width),
    height: ensureSize(height)
  };
}
