export const Info = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM6.66667 7.66667C6.66667 7.11438 7.11438 6.66667 7.66667 6.66667H8.33333C8.88562 6.66667 9.33333 7.11438 9.33333 7.66667V12.3333C9.33333 12.8856 8.88562 13.3333 8.33333 13.3333H7.66667C7.11438 13.3333 6.66667 12.8856 6.66667 12.3333V7.66667ZM8 5.33333C8.73638 5.33333 9.33333 4.73638 9.33333 4C9.33333 3.26362 8.73638 2.66667 8 2.66667C7.26362 2.66667 6.66667 3.26362 6.66667 4C6.66667 4.73638 7.26362 5.33333 8 5.33333Z"
        fill="currentColor"
      />
    </svg>
  );
};
