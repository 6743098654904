// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const DoubleChevronRight = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="DoubleChevronRight"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.261 3.526a.896.896 0 010-1.265.896.896 0 011.265 0l5.106 5.107a.896.896 0 010 1.264L3.526 13.74a.896.896 0 01-1.265 0 .896.896 0 010-1.265l4.477-4.476-4.477-4.472z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.154 3.526a.896.896 0 010-1.265.896.896 0 011.265 0l5.107 5.107a.896.896 0 010 1.264L8.419 13.74a.896.896 0 01-1.265 0 .896.896 0 010-1.265l4.478-4.476-4.478-4.472z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default DoubleChevronRight;
