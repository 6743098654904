import { useState, useEffect } from "react";

import styles from "./Switch.module.css";

import { classNames } from "../../utils/common";

type SwitchProps = {
  label: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (
    checked: boolean,
    evt: React.MouseEvent<HTMLButtonElement>
  ) => void;
};

export const Switch = (props: SwitchProps) => {
  const {
    checked: isChecked,
    defaultChecked: isDefaultChecked = false,
    label,
    disabled: isDisabled = false,
    onChange
  } = props;

  const [isValueChecked, setIsValueChecked] = useState(isDefaultChecked);

  useEffect(() => {
    if (isChecked !== undefined) {
      setIsValueChecked(isChecked);
    }
  }, [isChecked]);

  const handleOnClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    onChange && onChange(!isValueChecked, evt);
    if (isChecked === undefined) {
      setIsValueChecked(prevState => {
        return !prevState;
      });
    }
  };

  return (
    <button
      role="switch"
      aria-checked={isValueChecked}
      aria-label={label}
      className={classNames({
        [styles.catalystSwitch]: true
      })}
      disabled={isDisabled}
      onClick={handleOnClick}
    >
      <span className={styles.catalystSwitchHandle} aria-hidden={true}></span>
    </button>
  );
};
