// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const MuultipleFiles = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="MuultipleFiles"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_13780_11598)">
            <g clipPath="url(#prefix__clip1_13780_11598)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.938 7.111v7.556H4.061V3.11H8v4h3.938zM2.75 2.667c0-.491.392-.89.875-.89h4.888c.232 0 .454.094.618.261l3.863 3.924a.896.896 0 01.256.628v8.521c0 .491-.392.889-.875.889h-8.75a.882.882 0 01-.875-.889V2.667z"
                fill="currentColor"
              />
            </g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.696 8.403c.27-.258.637-.403 1.02-.403.381 0 .748.145 1.018.403.27.259.422.609.422.974 0 .366-.151.716-.422.974l-1.733 1.656a.897.897 0 01-.62.245.897.897 0 01-.618-.245.819.819 0 01-.257-.591c0-.222.092-.435.257-.592l1.733-1.656a.32.32 0 01.438 0 .287.287 0 010 .419l-1.733 1.656a.239.239 0 00-.075.173c0 .064.027.126.075.172a.262.262 0 00.362 0l1.733-1.656a.767.767 0 00.24-.555.767.767 0 00-.24-.555.84.84 0 00-.58-.23.84.84 0 00-.582.23L6.401 10.48c-.26.248-.406.585-.406.937 0 .351.146.688.406.937.26.248.613.388.98.388.369 0 .721-.14.982-.388l1.733-1.656a.32.32 0 01.438 0 .287.287 0 010 .419l-1.733 1.656c-.376.36-.887.561-1.42.561a2.055 2.055 0 01-1.418-.561 1.875 1.875 0 01-.588-1.356c0-.509.211-.997.588-1.357l1.733-1.656z"
              fill="currentColor"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.688 4.997v7.892h-1.249v1.333h1.686a.882.882 0 00.875-.889v-8.52a.896.896 0 00-.256-.629L10.88.26a.868.868 0 00-.618-.26H5.375A.882.882 0 004.5.889v1.517h1.313V1.333h4.268l3.607 3.664z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_13780_11598">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
            <clipPath id="prefix__clip1_13780_11598">
              <path
                fill=""
                transform="translate(1 1.778)"
                d="M0 0h14v14.222H0z"
              />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default MuultipleFiles;
