// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Close = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Close"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13.675 4.24L9.912 8l3.76 3.763a1.1 1.1 0 01.002 1.562l-.35.35c-.205.21-.486.325-.781.325h-.002c-.295 0-.573-.116-.78-.324L8 9.912l-3.763 3.76a1.1 1.1 0 01-1.56.002l-.35-.346a1.1 1.1 0 01-.002-1.565L6.089 8 2.328 4.237a1.102 1.102 0 01-.002-1.563l.35-.349a1.106 1.106 0 011.564 0L8 6.087l3.763-3.76a1.101 1.101 0 011.562-.002l.349.35c.212.205.326.486.326.78.002.296-.114.575-.322.783h-.003z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Close;
