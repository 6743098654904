// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Tasks = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Tasks"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_1_51)">
            <path
              d="M13.6 0A2.4 2.4 0 0116 2.4v11.2a2.4 2.4 0 01-2.4 2.4H2.4A2.4 2.4 0 010 13.6V2.4A2.4 2.4 0 012.4 0h11.2zm0 1.6H2.4a.8.8 0 00-.8.8v11.2a.8.8 0 00.8.8h11.2a.8.8 0 00.8-.8V2.4a.8.8 0 00-.8-.8zm-1.053 6.67l.07.061a.8.8 0 01.062 1.062l-.062.07-3.395 3.394a.8.8 0 01-1.06.062l-.07-.062-1.698-1.697a.8.8 0 011.062-1.194l.07.062 1.13 1.13 2.83-2.827a.8.8 0 011.061-.061zM8 6.4a.8.8 0 01.093 1.594L8 8H4a.8.8 0 01-.093-1.594L4 6.4h4zm4-3.2a.8.8 0 01.093 1.594L12 4.8H4a.8.8 0 01-.093-1.594L4 3.2h8z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_1_51">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Tasks;
