import { countries, type CountryItem } from "./countries";

export const phoneNumberPrefix = "+";
export const formatCharacter = "#";
export const phoneNumberDefaultFormat = "+### ### ### ### ##";
export const defaultCountryCode = "us";

/**
 * Guesses the country based on the input phone number.
 *
 * @param {string} input - The input phone number.
 * @returns {null | CountryItem} - The guessed country or null if no match is found.
 */
export const guessCountry = (input: string): null | CountryItem => {
  const inputNumber = input.replace(/\D/g, "");

  const bestGuess = Object.values(countries).reduce(
    (selectedCountry, country) => {
      if (inputNumber.startsWith(country.dialCode)) {
        if (country.dialCode.length > selectedCountry.dialCode.length) {
          return country;
        }
        if (
          country.dialCode.length === selectedCountry.dialCode.length &&
          country.priority < selectedCountry.priority
        ) {
          return country;
        }
      }
      return selectedCountry;
    },
    { dialCode: "", priority: 10001 } as CountryItem
  );

  if (!bestGuess.name) return null;

  return bestGuess;
};

/**
 * Formats a phone number according to a specified format.
 *
 * @param {string} text - The input phone number to format.
 * @param {string} format - The format to apply to the phone number.
 * @returns {string} - The formatted phone number.
 */
export const formatNumber = (text: string, format: string): string => {
  if (!text || text.length === 0) {
    return phoneNumberPrefix;
  }

  // For all strings with length less than 3, just return it (e.g., "1", "2")
  // Also return the same text if the selected country has no fixed format
  if ((text && text.length < 2) || !format) {
    return phoneNumberPrefix + text;
  }

  let formattedText = "";
  let remainingText = text.split("");

  for (const character of format) {
    if (remainingText.length === 0) {
      break;
    }

    if (character !== formatCharacter) {
      formattedText += character;
    } else {
      const head = remainingText[0];
      remainingText = remainingText.slice(1);
      formattedText += head;
    }
  }

  // Always close brackets
  if (formattedText.includes("(") && !formattedText.includes(")"))
    formattedText += ")";
  return formattedText;
};
