import { forwardRef, useRef, useEffect } from "react";

import styles from "./SliderInput.module.css";
import { FieldStatus } from "../../../types";
import { classNames, mergeRefs } from "../../../../utils";

export enum NumberInputSizes {
  DEFAULT = "Default",
  SMALL = "Small"
}

export type NumberInputProps = {
  label: string;
  disabled?: boolean;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  width?: string;
  status?: FieldStatus;
  onChange: (value?: number, evt?: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (value?: number, evt?: React.FocusEvent<HTMLInputElement>) => void;

  "aria-describedby"?: string;
  id?: string;
  error?: string;
};

// Why not use Number Input ? Why need separate input component ?
// Due to different padding, border, focus state, error state.
// This is specifically tailored for slider.
export const SliderInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => {
    const {
      label,
      id,
      value,
      onChange,
      onBlur,
      "aria-describedby": ariaDescribedBy,
      disabled: isDisabled = false,
      status,
      min,
      max,
      step = 1,
      error
    } = props;
    // why needed ref ?
    // using normal state, value can not be cleared.
    // for example, with min: 1, if user tries to clear
    // the value, it does not lets it do that.
    const numberInputRef = useRef<HTMLInputElement>();

    const hasError = status === FieldStatus.ERROR;

    const ariaDescribedById = ariaDescribedBy
      ? ariaDescribedBy
      : !!error
        ? id + "-error"
        : undefined;

    useEffect(() => {
      if (numberInputRef.current) {
        // Converting the number to string converts large number into exponential notation.
        // So just setting the value as number and typecasting it to string to avoid TS error.
        // Number input does takes in number as value but it is displayed as string.
        const valueAsString =
          value === null || value === undefined
            ? ""
            : (value as unknown as string);
        const currentValue = valueAsString;
        numberInputRef.current.value = currentValue;
      }
    }, [value]);

    const handleOnChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = Number(evt.target.value);
      const value = evt.target.value === "" ? undefined : convertedValue;
      onChange && onChange(value, evt);
    };

    const handleOnBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
      const value = evt.target.value;
      if (value === "") {
        onBlur?.(undefined, evt);
        return;
      }
      onBlur && onBlur(Number(value), evt);
    };

    return (
      <div
        className={classNames({
          [`${styles["catalystSliderInputContainer"]}`]: true,
          [`${styles["catalystSliderInputContainerDisabled"]}`]: isDisabled
        })}
      >
        <input
          id={id}
          type="number"
          className={styles.catalystSliderInput}
          disabled={isDisabled}
          aria-invalid={hasError}
          aria-describedby={ariaDescribedById}
          aria-label={label}
          ref={mergeRefs([ref, numberInputRef])}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          max={max}
          min={min}
          step={step}
        />
      </div>
    );
  }
);
