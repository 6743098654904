import React, { useState, useEffect, useCallback } from "react";

import styles from "./HorizontalTab.module.css";

import { classNames } from "../../utils/common";

type HorizontalTabProps = {
  badgeCount?: number;
  value: string;
  children: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  "aria-label": string;
};

type HorizontalTabsDefaultProps = {
  "aria-label": string;
  children:
    | React.ReactElement<HorizontalTabProps>
    | React.ReactElement<HorizontalTabProps>[];
};

type HorizontalTabChangeProps<T extends string> =
  | { activeTab?: never; onChange?: never }
  | { activeTab: T; onChange: (activeTab: T) => void };

type HorizontalTabsProps<T extends string> = HorizontalTabsDefaultProps &
  HorizontalTabChangeProps<T>;

const HorizontalTabs = <T extends string>(props: HorizontalTabsProps<T>) => {
  const { "aria-label": ariaTabsLabel, onChange, activeTab } = props;

  const [selectedTab, setSelectedTab] = useState<T | undefined>(activeTab);

  useEffect(() => {
    if (activeTab) {
      setSelectedTab(activeTab);
    }
  }, [activeTab]);

  const handleOnChange = useCallback(
    (tab: T) => {
      if (activeTab === undefined || activeTab === null) {
        setSelectedTab(tab);
      }
      onChange?.(tab);
    },
    [activeTab, onChange]
  );

  return (
    <div
      role="group"
      aria-label={ariaTabsLabel}
      className={styles.catalystHorizontalTabs}
    >
      {React.Children.map(
        props.children,
        (tab: React.ReactElement<HorizontalTabProps>) => {
          const tabValue = tab.props.value as T;
          const tabBadgeCount = tab.props.badgeCount;
          const isTabDisabled = !!tab.props.disabled;
          const tabIcon = tab.props.icon;
          const tabAriaLabel = tab.props["aria-label"];
          if (tab.type === HorizontalTab) {
            return (
              <button
                onClick={() => handleOnChange(tabValue)}
                className={classNames({
                  [styles.catalystHorizontalTab]: true,
                  [styles.catalystHorizontalTabSelected]:
                    tabValue === selectedTab
                })}
                disabled={isTabDisabled}
                aria-pressed={tabValue === selectedTab}
                aria-label={tabAriaLabel}
              >
                {tabIcon && (
                  <div className={styles.catalystHorizontalTabIcon}>
                    {tabIcon}
                  </div>
                )}
                {tab.props.children}
                {tabBadgeCount !== undefined && tabBadgeCount !== null && (
                  <div className={styles.catalystHorizontalTabBadge}>
                    {tabBadgeCount}
                  </div>
                )}
              </button>
            );
          } else {
            throw new Error("Use only HorizontalTab component as children");
          }
        }
      )}
    </div>
  );
};

const HorizontalTab = (props: HorizontalTabProps) => {
  return <>{props.children}</>;
};

export { HorizontalTabs, HorizontalTab };
