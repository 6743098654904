// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const FieldGroup = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="FieldGroup"
      icon={
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.60002 9.09968H1.30002L1.30002 3.8996H2.60002C2.9583 3.8996 3.25002 3.60786 3.25002 3.24961C3.25002 2.89133 2.95828 2.59961 2.60002 2.59961L0.649996 2.59961C0.291725 2.59961 0 2.89135 0 3.24961L0 9.74957C0 10.1078 0.291738 10.3996 0.649996 10.3996H2.60002C2.9583 10.3996 3.25002 10.1078 3.25002 9.74957C3.25002 9.39129 2.95828 9.09957 2.60002 9.09957V9.09968Z"
            fill="#00164E"
          />
          <path
            d="M12.35 2.59961L6.50009 2.59961C6.14182 2.59961 5.8501 2.89135 5.8501 3.24961C5.8501 3.60788 6.14184 3.8996 6.50009 3.8996L11.7002 3.8996V9.09967H6.50009C6.14182 9.09967 5.8501 9.39141 5.8501 9.74967C5.8501 10.1079 6.14184 10.3997 6.50009 10.3997H12.35C12.7083 10.3997 13 10.1079 13 9.74967V3.24971C13 2.89144 12.7083 2.59971 12.35 2.59971V2.59961Z"
            fill="#00164E"
          />
          <path
            d="M6.50013 0.649996C6.50013 0.291725 6.20839 0 5.85013 0L3.25009 0C2.89182 0 2.6001 0.291738 2.6001 0.649996C2.6001 1.00827 2.89184 1.29999 3.25009 1.29999L3.90009 1.29999L3.90009 11.7H3.25009C2.89182 11.7 2.6001 11.9917 2.6001 12.35C2.6001 12.7083 2.89184 13 3.25009 13H5.85013C6.2084 13 6.50013 12.7083 6.50013 12.35C6.50013 11.9917 6.20839 11.7 5.85013 11.7H5.20013L5.20013 1.29999L5.85013 1.29999C6.2084 1.29999 6.50013 1.00825 6.50013 0.649996Z"
            fill="#00164E"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default FieldGroup;
