import { ChevronUpLight } from "@certa/icons";
import { DropdownMenuTrigger } from "../../..";
import { Flag } from "../Flag/Flag";
import styles from "./TriggerButton.module.css";
import { defaultCountryCode } from "../../PhoneNumber.utils";

type TriggerButtonProps = {
  flagIso2?: string;
  disabled?: boolean;
  "aria-label"?: string;
};

export const TriggerButton = ({
  flagIso2,
  disabled,
  "aria-label": ariaLabel
}: TriggerButtonProps) => {
  return (
    <DropdownMenuTrigger disabled={disabled}>
      <button
        className={styles.catalystPhoneTriggerButton}
        disabled={disabled}
        aria-label={ariaLabel}
      >
        <Flag
          iso2={flagIso2 || defaultCountryCode}
          className={styles.catalystPhoneFlag}
        />
        {!disabled && <ChevronUpLight size={8} />}
      </button>
    </DropdownMenuTrigger>
  );
};
