import React, { forwardRef } from "react";

import { classNames } from "../../utils";
import { getTransformedPaddingWithCSSVariables, type Padding } from "../utils";

type GridItemProps = {
  /**
   * The children of the grid item
   */
  children: React.ReactNode;
  /**
   * The height of the grid item. Takes in only rem and % units.
   * @example "220rem"
   * @example "50%"
   **/
  height?:
    | `${string}rem`
    | `${string}%`
    | "fit-content"
    | "max-content"
    | "min-content";
  /**
   * The width of the grid item. Takes in only rem and % units.
   * @example "220rem"
   * @example "50%"
   **/
  width?:
    | `${string}rem`
    | `${string}%`
    | "fit-content"
    | "max-content"
    | "min-content";
  /**
   * The number of columns the grid item should span
   */
  colSpan?: number;
  /**
   * The number of rows the grid item should span
   */
  rowSpan?: number;
  /**
   * The starting column of the grid item
   */
  startCol?: number;
  /**
   * The ending column of the grid item
   */
  endCol?: number;
  /**
   * The starting row of the grid item
   */
  startRow?: number;
  /**
   * The ending row of the grid item
   */
  endRow?: number;
  /**
   * The tag of the grid item
   * @default "div"
   */
  as?: "div";
  /**
   * The padding of the grid item
   */
  padding?: Padding;
  /**
   * The background color of the grid item
   */
  backgroundColor?: string;
  /**
   * A custom class name to apply to the grid item.
   * Use this with caution, might override the default properties of the grid item.
   */
  dangerouslySetClassName?: string;
};

export const GridItem = forwardRef<HTMLElement, GridItemProps>((props, ref) => {
  const {
    children,
    colSpan,
    rowSpan,
    startCol,
    endCol,
    startRow,
    endRow,
    as = "div",
    padding,
    backgroundColor,
    dangerouslySetClassName = "",
    height = "100%",
    width = "100%"
  } = props;

  return (
    <>
      {React.createElement(as, {
        children,
        ref,
        style: {
          width,
          height,
          padding: getTransformedPaddingWithCSSVariables(padding),
          backgroundColor,
          gridColumn: getGridRowOrGridColumnValue(colSpan, startCol, endCol),
          gridRow: getGridRowOrGridColumnValue(rowSpan, startRow, endRow)
        },
        className: classNames({
          [dangerouslySetClassName]: !!dangerouslySetClassName
        })
      })}
    </>
  );
});

/**
 * function to get the grid row or column value
 * @param span - the number of columns or rows the grid item should span
 * @param start - the starting column or row of the grid item
 * @param end - the ending column or row of the grid item
 * @returns the grid row or column value
 * @example
 * getGridRowOrColumnValue(2) => "span 2"
 * getGridRowOrColumnValue(2, 1, 3) => "1 / 3"
 * getGridRowOrColumnValue(2, 1) => "1 / span 2"
 * getGridRowOrColumnValue(2, undefined, 3) => "span 2 / 3"
 * getGridRowOrColumnValue(undefined, 1) => "1 / auto"
 */
function getGridRowOrGridColumnValue(
  span?: number,
  start?: number,
  end?: number
) {
  let gridRowOrColumnValue = "auto";

  if (span) {
    gridRowOrColumnValue = `span ${span}`;
  }

  if (start && end) {
    gridRowOrColumnValue = `${start} / ${end}`;
  }

  if (start && !end && span) {
    gridRowOrColumnValue = `${start} / span ${span}`;
  }

  if (!start && end && span) {
    gridRowOrColumnValue = `span ${span} / ${end}`;
  }

  if (!span && start && !end) {
    gridRowOrColumnValue = `${start} / auto`;
  }

  if (!span && !start && end) {
    gridRowOrColumnValue = `auto / ${end}`;
  }

  return gridRowOrColumnValue;
}
