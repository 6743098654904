// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Support = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;

  return (
    <IconWrapper
      componentName="Support"
      icon={
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.2426 10.2434C12.5849 7.89975 12.5849 4.1018 10.2426 1.75819C7.89878 -0.583948 4.10054 -0.583948 1.75674 1.75819C-0.585582 4.1018 -0.585582 7.89975 1.75674 10.2434C4.10054 12.5855 7.89878 12.5855 10.2426 10.2434ZM11.0911 4.27922C11.4671 5.39457 11.4671 6.60703 11.0911 7.72086L9.71981 7.04123C9.9097 6.36159 9.9097 5.63942 9.71981 4.95976L11.0911 4.27922ZM0.908259 4.27922C0.532304 5.39457 0.532304 6.60703 0.908259 7.72086L2.27956 7.04123C2.08967 6.36159 2.08967 5.63942 2.27956 4.95976L0.908259 4.27922ZM4.2794 11.0919C5.39388 11.4678 6.60593 11.4678 7.72132 11.0919L7.04067 9.72066C6.36098 9.91054 5.63876 9.91054 4.95904 9.72066L4.2794 11.0919ZM4.2794 0.909811C5.39388 0.533885 6.60593 0.533885 7.72132 0.909811L7.04067 2.28101C6.36098 2.09113 5.63876 2.09113 4.95904 2.28101L4.2794 0.909811ZM8.28813 8.28923C9.55181 7.02565 9.55181 4.97614 8.28813 3.71257C7.02446 2.44899 4.97479 2.44899 3.71112 3.71257C2.44744 4.97614 2.44744 7.02565 3.71112 8.28923C4.97479 9.5528 7.02446 9.5528 8.28813 8.28923Z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Support;
