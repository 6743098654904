export const Warning = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6234 10.956L10.435 2.34521C9.93222 1.51254 9.00513 1 8.00119 1C6.99725 1 6.07013 1.51254 5.56739 2.34521L0.378934 10.956C-0.125395 11.7895 -0.126178 12.8175 0.375797 13.6525C0.878559 14.4867 1.80722 15.0008 2.81281 15H13.1897C14.1937 15 15.1216 14.4867 15.6235 13.6533C16.1255 12.8199 16.1255 11.7933 15.6235 10.9599L15.6234 10.956ZM7.96123 12.7416C7.5824 12.7431 7.24043 12.5259 7.09453 12.1915C6.94786 11.8571 7.0263 11.4716 7.29297 11.2138C7.55886 10.9561 7.96123 10.8779 8.31104 11.0154C8.66165 11.1529 8.89145 11.4783 8.89302 11.8413C8.89459 12.0788 8.79655 12.308 8.62163 12.4771C8.44751 12.6455 8.20906 12.7409 7.96121 12.7416L7.96123 12.7416ZM8.89302 8.84811C8.89302 9.3411 8.47576 9.74015 7.96123 9.74015C7.4467 9.74015 7.02944 9.3411 7.02944 8.84811V6.20048C7.02944 5.70749 7.4467 5.30769 7.96123 5.30769C8.47576 5.30769 8.89302 5.70749 8.89302 6.20048V8.84811Z"
        fill="currentColor"
      />
    </svg>
  );
};
