// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Bot = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Bot"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1a1 1 0 00-1 1v1H4.5A1.5 1.5 0 003 4.5v7A1.5 1.5 0 004.5 13h7a1.5 1.5 0 001.5-1.5v-7A1.5 1.5 0 0011.5 3H9V2a1 1 0 00-1-1zm3 6a1 1 0 11-2 0 1 1 0 012 0zM6 8a1 1 0 100-2 1 1 0 000 2zm0 2.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zM14 6a1 1 0 112 0v4a1 1 0 11-2 0V6zM1 5a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Bot;
