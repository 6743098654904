import { forwardRef } from "react";
import { classNames } from "../../../../utils";
import styles from "./PhoneNumberTextInput.module.css";
import { FieldStatus } from "../../../types";

type PhoneNumberTextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  status?: FieldStatus;
};

export const PhoneNumberTextInput = forwardRef<
  HTMLInputElement,
  PhoneNumberTextInputProps
>((props, ref) => {
  const {
    onChange,
    value,
    disabled: isDisabled,
    id,
    status,
    ...restProps
  } = props;

  return (
    <input
      {...restProps}
      disabled={isDisabled}
      className={classNames({
        [styles.catalystPhoneInput]: true,
        [styles.catalystPhoneInputDisabled]: isDisabled,
        [styles.catalystPhoneInputError]: status === FieldStatus.ERROR
      })}
      id={id}
      ref={ref}
      value={value}
      // This onChange is from NumberFormat and it requires the evt to be passed
      // evt must contain the target and currentTarget.
      onChange={evt => onChange?.(evt)}
    />
  );
});
