import React, { forwardRef } from "react";

import { classNames } from "../../utils";
import { getTransformedPaddingWithCSSVariables } from "../utils";

import type { StackProps } from "./types";

import styles from "./Stack.module.css";

export const Stack = forwardRef<HTMLElement, StackProps>((props, ref) => {
  const {
    as = "div",
    direction,
    gap = 0,
    role,
    align,
    justify,
    children,
    dangerouslySetClassName = "",
    padding,
    width,
    height,
    backgroundColor,
    overflow,
    onClick,
    onKeyDown,
    tabIndex,
    id
  } = props;

  return (
    <>
      {React.createElement(as, {
        role,
        ref,
        children,
        onClick,
        onKeyDown,
        tabIndex,
        id,
        style: {
          gap: `var(--space-${gap})`,
          justifyContent: justify,
          alignItems: align,
          padding: getTransformedPaddingWithCSSVariables(padding),
          width,
          height,
          backgroundColor,
          overflow
        },
        className: classNames({
          [dangerouslySetClassName]: !!dangerouslySetClassName,
          [styles.catalystStackContainer]: true,
          [styles.catalystStackContainerHorizontal]: direction === "horizontal"
        })
      })}
    </>
  );
});
