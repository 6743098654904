import React from "react";
import type { FC } from "react";
import type { IconProps } from "../types";

export const Gravatar: FC<IconProps> = props => {
  const { size, ...restProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || "40"}
      height={size || "40"}
      fill="none"
      viewBox="0 0 40 40"
      aria-label="Gravatar"
      {...restProps}
    >
      <circle cx="20" cy="20" r="20" fill="#EAEAEA"></circle>
      <g clipPath="url(#clip60)">
        <path
          fill="#8F8F91"
          d="M15 25.73V28h2v-2h2v2h2v-2h2v2h2v-2.27c.303-.175.555-.427.73-.73H28v-2h-2v-2h2v-2h-2v-2h2v-2h-2.27a2.016 2.016 0 00-.73-.73V12h-2v2h-2v-2h-2v2h-2v-2h-2v2.27a2.015 2.015 0 00-.73.73H12v2h2v2h-2v2h2v2h-2v2h2.27c.175.303.427.555.73.73zM16 21v-5h8v8h-8v-3z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip60">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="translate(12 12)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};
