// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Spinner3 = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Spinner3"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g>
            <path
              d="M12 6A6 6 0 110 6a6 6 0 0112 0zM2.087 6a3.913 3.913 0 107.826 0 3.913 3.913 0 00-7.826 0z"
              fill="#EEEFEF"
            />
            <path
              d="M6 0a6 6 0 11-6 6h2.087A3.913 3.913 0 106 2.087V0z"
              fill="#1F5EFF"
            />
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 6 6"
              to="360 6 6"
              dur="0.8s"
              repeatCount="indefinite"
            />
          </g>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Spinner3;
