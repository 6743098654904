import { COLORS, LAYOUT_ID } from "./constants";
import type { RAGIndicator } from "./types";
import { RAGStatusColor, RAGStatusText } from "./types";

/**
 * @deprecated
 */
export const getCSSToken = (value: string) => {
  return value.replace(/(s-?\d+)/g, "var(--$1)");
};

export const getCSSVarToken = (value: string) => {
  if (/^(s\d+\s?)+$/g.test(value)) {
    return value.replace(/(s-?\d+)/g, "var(--$1)");
  } else {
    const error = new Error(
      "Please provide spacing variable in format (s[value of spacing])" // dont'accept values other than variables
    );
    throw error;
  }
};

/**
 * In production build emotion css style tag is empty which
 * doesn't allows css to be applied in print mode as it works on
 * insertRule
 *
 * In dev mode the style tag contains all the classes with
 * the stylings
 *
 * This function is used to get the css classes from all the emotion style
 * tags present in the dom.
 * It fetches the classes fom cssRules
 */
export const getEmotionCSSClasses = () =>
  [...document.querySelectorAll("[data-emotion]")]
    // @ts-expect-error: suppressed type error since this code is not actively maintained
    .flatMap(({ sheet }) => [...sheet.cssRules].map(rules => rules.cssText))
    .join(" ");

/**
 * Get color css variable from design system.
 * Returns the string as is if color name not found in design system.
 */
export const getColor = (color?: string) => {
  // If color is not given return undefined, to avoid any breakages
  if (!color) return color;

  // If passed a design system color then use color as a variable
  if (COLORS.hasOwnProperty(color)) return `var(--${color})`;

  // Else return the hexcode/rgb color passed
  return color;
};

export const getLayoutContainer = (triggerNode?: HTMLElement) =>
  triggerNode?.parentElement ||
  document.getElementById(LAYOUT_ID) ||
  document.body;

export const getRAGIndicator = (
  status: RAGIndicator
): {
  icon: string;
  color: RAGStatusColor;
} => {
  return {
    icon: RAGStatusText[status],
    color: RAGStatusColor[status]
  };
};

export const generateRandomColor = (index: number) => {
  const colors = [
    "var(--colors-green-200)",
    "var(--colors-teal-200)",
    "var( --colors-red-200)",
    "var(--colors-brand-200)",
    "var(--colors-orange-200)",
    "var(--colors-blueberry-200)",
    "var(--colors-purple-200)"
  ];
  return colors[index % (colors.length - 1)];
};
