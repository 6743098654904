// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Link = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Link"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.717 1.25a4.266 4.266 0 016.034 6.033l-2.64 2.64a4.267 4.267 0 01-6.775-.994l.703-.704a1.22 1.22 0 01.797-.356c.121.285.297.547.523.771a2.436 2.436 0 001.735.719c.655 0 1.271-.255 1.735-.719L13.469 6c.462-.463.717-1.079.717-1.734 0-.656-.255-1.271-.718-1.734a2.438 2.438 0 00-1.735-.72c-.655 0-1.272.256-1.735.72L8.649 3.88a5.517 5.517 0 00-2.313-.25l2.38-2.38zM7.359 12.109c.568.192 1.159.29 1.763.29h.002c.18 0 .359-.009.536-.025L7.284 14.75A4.266 4.266 0 111.25 8.717l2.64-2.641a4.267 4.267 0 016.775.995l-.702.703c-.214.215-.496.34-.798.357a2.458 2.458 0 00-3.993-.773l-2.64 2.64a2.438 2.438 0 00-.719 1.735c0 .655.255 1.27.72 1.734.463.464 1.079.719 1.734.719s1.27-.255 1.734-.719l1.358-1.358z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Link;
