import React, { useState, useEffect, useMemo, forwardRef } from "react";
import type { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { nanoid } from "nanoid";
import {
  ChevronLeft,
  ChevronRight,
  Date as DateIcon,
  DoubleChevronLeft,
  DoubleChevronRight
} from "@certa/icons";
import type { StrictModifiers } from "@popperjs/core";

import { TextInput } from "../../Inputs";
import { ButtonSizes, ButtonTypes, Button } from "../../Button";
import { TypographyVariants, Typography } from "../../Typography";

import styles from "./DatePicker.module.css";
import { classNames } from "../../../utils";
import type { FieldStatus } from "../../types";
import { PopperPlacements } from "../../types";
import { formatDate, isOutOfBounds, validateAndParseDate } from "./Date.utils";
import "./DatePicker.css";

/*
 In future need to use only Date object in value and onchange,
 internally it can use anything, but the code consuming it need not have
 moment installed.
*/

export type PopperModifiers = ReadonlyArray<StrictModifiers>;
export type DatePickerPopperStrategy = "absolute" | "fixed";

export type DatePickerProps = {
  "aria-label": string;
  placeholder?: string;
  format?: string;
  disabled?: boolean;
  value?: moment.Moment | null;
  onChange?: (date: moment.Moment | null, dateString: string) => void;
  minDate?: moment.Moment | null;
  maxDate?: moment.Moment | null;
  inputWidth?: string;
  strategy?: DatePickerPopperStrategy;
  placement?: PopperPlacements;
  popperModifiers?: PopperModifiers;
  formatWithUserLocale?: boolean; // If true, format the date according to the user's locale. If false, format the date according to the format prop.
  /** Determines the behavior when the input value is invalid during onBlur in the DatePicker component.
   *
   * @type {boolean}
   * @default true
   * @description
   * If set to true, the DatePicker will clear the selected date and input value if the entered text is invalid.
   * If set to false, the DatePicker will restore the previous input value without changing the selected date when the entered text is invalid.
   */
  shouldClearValueOnInvalidText?: boolean;
  status?: FieldStatus;
  hasCustomInput?: boolean;
  showTodayButton?: boolean;
  openInPortal?: boolean;
};

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (props, forwardedRef) => {
    const {
      "aria-label": ariaLabel,
      format = "YYYY-MM-DD",
      disabled: isDisabled,
      value,
      onChange,
      minDate,
      maxDate,
      placeholder,
      inputWidth = "10.625rem", // 170px
      strategy = "absolute",
      placement = PopperPlacements.BOTTOM,
      popperModifiers,
      formatWithUserLocale: shouldFormatWithUserLocale = false,
      shouldClearValueOnInvalidText = true,
      hasCustomInput = false,
      status,
      showTodayButton: shouldShowTodayButton = false,
      openInPortal: shouldOpenInPortal = false
    } = props;

    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
      value || null
    );
    const [inputValue, setInputValue] = useState<string>(() =>
      value ? formatDate(value, format, shouldFormatWithUserLocale) : ""
    );
    const textDescriptionId = useMemo(() => {
      return nanoid();
    }, []);
    const isTodayButtonDisabled = isOutOfBounds(moment(), { minDate, maxDate });

    useEffect(() => {
      setSelectedDate(value || null);
      setInputValue(
        value ? formatDate(value, format, shouldFormatWithUserLocale) : ""
      );
    }, [value, format, shouldFormatWithUserLocale]);

    const handleClear = () => {
      setSelectedDate(null);
      setInputValue("");
      if (value) {
        onChange?.(null, "");
      }
    };

    const onSave = () => {
      if (inputValue === "") {
        handleClear();
        return;
      }

      const savedInputText = value
        ? formatDate(value, format, shouldFormatWithUserLocale)
        : "";
      if (savedInputText === inputValue) return;

      const { isValid, parsedMoment: inputDate } = validateAndParseDate(
        inputValue,
        format,
        shouldFormatWithUserLocale
      );

      const isBeforeMinDate = minDate && inputDate?.isBefore(minDate);
      const isAfterMaxDate = maxDate && inputDate?.isAfter(maxDate);

      if (isValid && inputDate && !isBeforeMinDate && !isAfterMaxDate) {
        setSelectedDate(inputDate);
        if (!inputDate.isSame(value)) {
          const dateStringToSaveToBackend = formatDate(
            inputDate,
            format,
            false
          );
          onChange?.(inputDate, dateStringToSaveToBackend);
        }
      } else {
        // TODO: better to add error handling

        // Clear value and handle onChange based on the prop
        if (shouldClearValueOnInvalidText) {
          handleClear();
        } else {
          // Restore the previous text input value
          setInputValue(value ? formatDate(value, format, false) : "");
        }
      }
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onSave();
      }
    };

    const handleChangeDate = (selectedDate: Date) => {
      const date = moment(selectedDate);
      const dateString = formatDate(date, format, shouldFormatWithUserLocale);
      setSelectedDate(date);
      setInputValue(dateString);
      onChange?.(date, dateString);
    };

    return (
      <div className={styles.catalystDatePickerContainer}>
        {!hasCustomInput ? (
          <TextInput
            label={ariaLabel}
            value={inputValue}
            onChange={setInputValue}
            onClear={handleClear}
            disabled={isDisabled}
            placeholder={placeholder}
            aria-describedby={textDescriptionId}
            onBlur={onSave}
            onKeyDown={onKeyDown}
            width={inputWidth}
            ref={forwardedRef}
            status={status}
          />
        ) : null}
        <span className="catalyst-screen-reader-only" id={textDescriptionId}>
          (<span>date format: </span>
          <span> {format})</span>
          {minDate && (
            <>
              <span>Minimum Date:</span>
              <span> {minDate?.format(format)}</span>
            </>
          )}
          {maxDate && (
            <>
              <span>Maximum Date:</span>
              <span> {maxDate?.format(format)}</span>
            </>
          )}
        </span>
        <ReactDatePicker
          wrapperClassName={classNames(
            "catalyst-datepicker-wrapper",
            styles.catalystDatePickerCalendarWrapper
          )}
          calendarClassName={classNames(
            "catalyst-datepicker-calendar",
            styles.catalystDatePickerCalendar
          )}
          selected={selectedDate?.toDate()}
          onChange={handleChangeDate}
          disabled={isDisabled}
          customInput={
            <Button
              size={ButtonSizes.SMALL}
              type={ButtonTypes.ICON}
              leftIcon={<DateIcon />}
              aria-label={`Choose ${ariaLabel}`}
            />
          }
          preventOpenOnFocus
          minDate={minDate?.toDate()}
          maxDate={maxDate?.toDate()}
          popperPlacement={placement}
          popperProps={{ strategy }}
          popperClassName={styles.catalystDatePickerPopper}
          popperModifiers={popperModifiers}
          showPopperArrow={false}
          closeOnScroll={false}
          renderCustomHeader={DatePickerHeader}
          fixedHeight
          portalId={
            shouldOpenInPortal ? "catalyst-datepicker-wrapper" : undefined
          }
          todayButton={
            shouldShowTodayButton ? (
              <Button fullWidth disabled={isTodayButtonDisabled}>
                Today
              </Button>
            ) : undefined
          }
        />
      </div>
    );
  }
);

const DatePickerHeader = (props: ReactDatePickerCustomHeaderProps) => {
  const {
    date,
    // changeYear,
    // changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled: isPrevMonthButtonDisabled,
    nextMonthButtonDisabled: isNextMonthButtonDisabled,
    decreaseYear,
    increaseYear,
    prevYearButtonDisabled: isPrevYearButtonDisabled,
    nextYearButtonDisabled: isNextYearButtonDisabled
  } = props;

  return (
    <div className={styles.catalystDatePickerHeader}>
      <div className={styles.catalystDatePickerHeaderIconWrap}>
        <Button
          aria-label="Previous Year"
          onClick={decreaseYear}
          disabled={isPrevYearButtonDisabled}
          leftIcon={<DoubleChevronLeft />}
          type={ButtonTypes.ICON}
        />

        <Button
          aria-label="Previous Month"
          onClick={decreaseMonth}
          disabled={isPrevMonthButtonDisabled}
          leftIcon={<ChevronLeft />}
          type={ButtonTypes.ICON}
        />
      </div>

      <Typography variant={TypographyVariants.BODY_BOLD}>
        {moment(date).format("MMMM")}&nbsp;
        {moment(date).format("YYYY")}
      </Typography>

      <div className={styles.catalystDatePickerHeaderIconWrap}>
        <Button
          aria-label="Next Month"
          onClick={increaseMonth}
          disabled={isNextMonthButtonDisabled}
          leftIcon={<ChevronRight />}
          type={ButtonTypes.ICON}
        />
        <Button
          aria-label="Next Year"
          onClick={increaseYear}
          disabled={isNextYearButtonDisabled}
          leftIcon={<DoubleChevronRight />}
          type={ButtonTypes.ICON}
        />
      </div>
    </div>
  );
};
