// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Attachment = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Attachment"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M13.1 2.9a3.03 3.03 0 00-2.179-.9 3.03 3.03 0 00-2.179.9L2.9 8.758a3.02 3.02 0 00-.9 2.163c0 .82.316 1.595.9 2.18.584.583 1.358.899 2.179.899.805 0 1.579-.316 2.18-.9l3.979-3.98a1.953 1.953 0 00-.063-2.667 1.906 1.906 0 00-2.716 0L5.174 9.737a.79.79 0 00-.22.553c0 .205.078.41.22.552.3.3.805.3 1.09 0L9.515 7.59a.391.391 0 01.537 0 .391.391 0 010 .537l-3.9 3.884c-.584.585-1.563.585-2.163 0-.584-.584-.584-1.563 0-2.163L9.848 3.99c.584-.584 1.563-.584 2.163 0 .284.285.442.663.442 1.074 0 .41-.158.79-.442 1.09a.74.74 0 00-.237.552c0 .205.095.41.237.553.3.284.79.284 1.09 0a3.088 3.088 0 000-4.358z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Attachment;
