import type { FieldStatus } from "../types";

export type MarkObj = {
  style?: React.CSSProperties;
  label: React.ReactNode;
};

export type SliderMarkProps = {
  value: number;
  style?: any;
  min: number;
  max: number;
  mark: React.ReactNode | MarkObj;
};

export const SliderColorVariants = {
  default: "Default",
  red: "Red",
  green: "Green",
  amber: "Amber"
} as const;

export type SliderProps = {
  min: number;
  max: number;
  step?: number;
  value?: number | null;
  onChange?: (value: number) => void;
  "aria-label": string;
  disabled?: boolean;
  width?: string;
  /**steps to break the slider at with custom labels and styles */
  marks?: Record<string | number, React.ReactNode | MarkObj>;
  status?: FieldStatus;
  color?: keyof typeof SliderColorVariants;
  trackBackgroundGradient?: false | "greenToRed" | "redToGreen";
};
