// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Arrow = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Arrow"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.842 7.523l-3.38-4.252c-.28-.324-.727-.355-1.021-.105-.295.25-.344.754-.102 1.059l2.406 3.025H1.725c-.4 0-.725.336-.725.75s.324.75.724.75h11.021l-2.406 3.025c-.242.305-.187.802.102 1.06.298.264.781.199 1.022-.106l3.38-4.252c.219-.32.2-.644 0-.954z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Arrow;
