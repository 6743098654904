// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Caret = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Caret"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M11.307 6H4.683c-.605 0-.926.807-.463 1.267L7.567 10.8c.285.268.712.268.96 0l3.276-3.532c.429-.46.109-1.267-.496-1.267z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Caret;
