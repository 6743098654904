// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Bell2 = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Bell2"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g clipPath="url(#prefix__clip0_14748_12145)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.528 1.728A.95.95 0 019.15 2.4c0 .251.145.48.372.588a4.95 4.95 0 012.828 4.228V9.6c0 .026.002.052.005.078a3.85 3.85 0 00.618 1.672H3.427a3.85 3.85 0 00.618-1.672.65.65 0 00.005-.078V7.216a4.95 4.95 0 012.816-4.223.65.65 0 00.384-.604.95.95 0 01.278-.66zM5.15 12.65H1.8a.65.65 0 01-.32-1.216A2.55 2.55 0 002.75 9.56V7.2v-.03a6.25 6.25 0 013.24-5.188 2.25 2.25 0 014.421 0 6.25 6.25 0 013.24 5.218v2.359a2.55 2.55 0 001.27 1.875.65.65 0 01-.32 1.216h-3.35v.15a3.05 3.05 0 01-6.1 0v-.15zm4.8 0v.15a1.75 1.75 0 11-3.5 0v-.15h3.5z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="prefix__clip0_14748_12145">
              <path fill="" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      }
      {...restProps}
    />
  );
};
export default Bell2;
