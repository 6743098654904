// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const Envelope = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="Envelope"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M14.667 2H1.333C.597 2 0 2.672 0 3.5v9c0 .398.14.78.39 1.06.25.282.59.44.943.44h13.334c.353 0 .692-.158.942-.44A1.6 1.6 0 0016 12.5v-9a1.6 1.6 0 00-.39-1.06 1.26 1.26 0 00-.943-.44zM8 8.54L2.4 3.5h11.2L8 8.54zm0 1.92l1.52-1.364 4 3.405H2.447L6.48 9.094 8 10.46zm6.667-6v7.103l-4.073-3.435 4.073-3.668zm-9.26 3.667l-4.074 3.436V4.46l4.074 3.667z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default Envelope;
