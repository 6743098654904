// GENERATED COMPONENT. DO NOT EDIT MANUALLY;

import * as React from "react";
import type { IconProps } from "../../types/icon";
import { IconWrapper } from "../IconWrapper";

const ChevronLeft = (allProps: IconProps) => {
  const { svgProps: props, ...restProps } = allProps;
  return (
    <IconWrapper
      componentName="ChevronLeft"
      icon={
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M4.584 9.467l4.147 4.146a1.333 1.333 0 002.012-.153 1.365 1.365 0 00-.167-1.764L6.878 8l3.734-3.734a1.332 1.332 0 00-.155-2.014 1.364 1.364 0 00-1.763.167l-4.11 4.114a2.079 2.079 0 000 2.935z"
            fill="currentColor"
          />
        </svg>
      }
      {...restProps}
    />
  );
};
export default ChevronLeft;
