import type { PropsWithChildren, ForwardedRef } from "react";
import React, { useEffect, forwardRef, useRef } from "react";

import { classNames, mergeRefs } from "../../../utils/common";
import type { FieldStatus } from "../../types";

import styles from "./TextArea.module.css";

export type TextAreaProps = {
  name?: string;
  id?: string;
  label: string;
  "aria-describedby"?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  placeholder?: string;
  rows?: number;
  cols?: number;
  width?: string;
  height?: string;
  error?: string;
  status?: FieldStatus;
  onChange?: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /**
   * The resize property controls if and how textarea can be resized by the user.
   * @default "vertical"
   */
  resize?: "vertical" | "horizontal" | "none" | "both";
  /**
   * If true, the textarea height will be adjusted automatically based on the content.
   * @default true
   */
  autoAdjustTextareaHeight?: boolean;
  maxHeight?: string;
} & React.HTMLAttributes<HTMLTextAreaElement>;

// For default behavior, we need to show max 6.5 lines of text.
const DEFAULT_MAX_HEIGHT = "8.875rem";

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    props: PropsWithChildren<TextAreaProps>,
    forwardedRef: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const {
      name,
      id,
      label,
      "aria-describedby": ariaDescribedBy,
      value,
      disabled: isDisabled = false,
      required: isRequired = false,
      placeholder,
      rows = 1,
      cols,
      width,
      height,
      status,
      error,
      resize = "vertical",
      autoAdjustTextareaHeight: shouldAutoAdjustTextareaHeight = true,
      maxHeight,
      ...restProps
    } = props;

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
      if (
        textAreaRef.current &&
        shouldAutoAdjustTextareaHeight &&
        (resize === "vertical" || resize === "both")
      ) {
        textAreaRef.current.style.height = "auto"; // setting it first auto, helps to calculate scrollheight in next line
        textAreaRef.current.style.height = `calc(${textAreaRef.current.scrollHeight}px + 2px)`; // 2px for top and bottom borders
      }
    }, [value, shouldAutoAdjustTextareaHeight, resize]);

    const ariaDescribedById = ariaDescribedBy
      ? ariaDescribedBy
      : !!error
        ? id + "-error"
        : undefined;

    const defaultMaxHeight =
      shouldAutoAdjustTextareaHeight &&
      (resize === "vertical" || resize === "both")
        ? DEFAULT_MAX_HEIGHT
        : "";

    return (
      <textarea
        {...restProps}
        name={name}
        id={id}
        aria-label={label}
        aria-describedby={ariaDescribedById}
        className={classNames({
          [styles.catalystTextArea]: true,
          [styles.catalystResizableTextArea]: !!resize,
          [`${styles["catalystTextArea" + status]}`]: !!status && !isDisabled,
          [`${styles["catalystTextAreaDisabled"]}`]: isDisabled
        })}
        style={{
          resize: getResizeValue(shouldAutoAdjustTextareaHeight, resize),
          width,
          height,
          maxHeight: maxHeight || defaultMaxHeight
        }}
        disabled={isDisabled}
        placeholder={placeholder}
        required={isRequired}
        aria-invalid={!!error}
        aria-required={isRequired}
        ref={mergeRefs([textAreaRef, forwardedRef])}
        rows={rows}
        cols={cols}
        value={value}
      />
    );
  }
);

function getResizeValue(
  autoAdjustTextareaHeight: boolean,
  resize: TextAreaProps["resize"]
) {
  if (!autoAdjustTextareaHeight) return resize;
  if (resize === "vertical") return "none";
  if (resize === "both") return "horizontal";
  return resize;
}
